import { useQwanta } from "qwanyx";
import { useState, useEffect, useCallback } from "react";

// Generic hook for managing persisted state with local fallback
export const usePersistentState = (qwantumId, initialValue = "") => {
  const [persistedValue, setQwanta] = useQwanta(qwantumId);
  const [localValue, setLocalValue] = useState(initialValue);

  // Sync local state with persisted state on mount and when persisted value changes
  useEffect(() => {
    if (persistedValue !== undefined) {
      setLocalValue(persistedValue);
    }
  }, [persistedValue]);

  // Save function that handles both local and persisted state
  const setValue = useCallback((newValue) => {
    const valueToSet = typeof newValue === 'function' ? newValue(localValue) : newValue;
    setLocalValue(valueToSet);
    
    if (qwantumId) {
      setQwanta(qwantumId, valueToSet);
    }
  }, [qwantumId, setQwanta, localValue]);

  return [localValue, setValue];
};

export const useTextPersistence = (qwantumId, onSave) => {
    const [text, setQwanta] = useQwanta(qwantumId);
  
    const handleBlur = useCallback((e) => {
      if (!qwantumId) return;
      
      const newValue = e.target.value;
      if (newValue !== text) {
        setQwanta(qwantumId, newValue);
        if (onSave) {
          onSave(newValue);
        }
      }
    }, [qwantumId, text, setQwanta, onSave]);
  
    return {
      text: text || "",
      handleBlur
    };
  };
  
// New hook specifically for boolean/checkbox state
export const useBooleanPersistence = (qwantumId, onSave) => {
  const [value, setQwanta] = useQwanta(qwantumId);
  
  // Convert stored string value to boolean
  const boolValue = value === "true" || value === true;
  
  const handleChange = useCallback((newValue) => {
    if (!qwantumId) return;
    
    // Convert to string for consistency with other persistence hooks
    const stringValue = String(newValue);
    
    if (stringValue !== String(value)) {
      setQwanta(qwantumId, stringValue);
      if (onSave) {
        onSave(newValue);
      }
    }
  }, [qwantumId, value, setQwanta, onSave]);

  return {
    value: boolValue,
    handleChange
  };
};

export const useSwitchState = (qwantumId) => {
  const [activeIndexQwanta, setQwanta] = useQwanta(qwantumId);
  const [activeIndexLocal, setActiveIndexLocal] = useState(0);
  
  const isQwantaEnabled = !!qwantumId;
  const activeIndex = isQwantaEnabled ? activeIndexQwanta : activeIndexLocal;
  
  const setActiveIndex = (nextIndex) => {
    if (isQwantaEnabled) {
      setQwanta(qwantumId, nextIndex);
    } else {
      setActiveIndexLocal(nextIndex);
    }
  };

  return {
    activeIndex,
    setActiveIndex,
    isQwantaEnabled
  };
};