import React, { useState } from "react";
import {
    EditQwantaConfig,
    EditQwantaConfigValues,
    ImportTab,
    SetupHeader,
    WebSettings,
    SetupIcons,
    ListSettingsTab,
    JSONQwantaEditor
} from "qwanyx";
export const Setup = ({ editorConfig = null }) => {
    const [activeTab, setActiveTab] = useState("editor");

    // Handler for switching to Import tab
    const handleImportClick = () => {
        setActiveTab("import");
    };
    // Handler for switching to Edit tab
    const handleEditClick = () => {
        setActiveTab("edit");
    };
    // Handler to return to Setup (Editor view)
    const handleBackToSetup = () => {
        setActiveTab("editor");
    };
    const handleEditValuesClick= () => {
        setActiveTab("values");
    };
    const handleJsonClick= () => {
        setActiveTab("json");
    };
    const handleWebClick= () => {
        setActiveTab("web");
    };
    const handleSettingsClick= () => {
        setActiveTab("listSettings");
    };

    return (
        <>
            {/* Header/Toolbox */}
            <SetupHeader onBack={handleBackToSetup} />
            {activeTab === "editor" && (
                <SetupIcons
                    onImportClick={handleImportClick}
                    onEditClick={handleEditClick} // Pass edit click handler
                    onEditValuesClick={handleEditValuesClick}
                    onEditJsonClick={handleJsonClick}
                    onWebClick={handleWebClick}
                    onListSettingsClick={handleSettingsClick}
                />
            )}
            {activeTab === "import" && (
                <ImportTab editorConfig={editorConfig} onBack={handleBackToSetup} />
            )}
            {activeTab === "edit" && (
                <EditQwantaConfig editorConfig={editorConfig} />
            )}
            {activeTab === "values" && (
                <EditQwantaConfigValues editorConfig={editorConfig} />
            )}
            {activeTab === "web" && (
                <WebSettings />
            )}
            {activeTab === "listSettings" && (
                <ListSettingsTab />
            )}
            {activeTab === "json" && (
                <JSONQwantaEditor qwantumId="metaqwanta" />
            )}
            
        </>
    );
};

export default Setup;