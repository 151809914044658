import React, { useState } from "react";
import {
  SwitchIcon,
  ToolBox,
  FieldBase,
  useListContext,
  ProjectsIcons,
} from "qwanyx";

export const SimpleSearch = ({ toggle }) => {
  // Local state for input control
  const [searchQuery, setSearchQuery] = useState("");
  
  // Get search configuration and update function from context
  const { updateQuery, searchArray } = useListContext();
  
  // Style constants
  const border = "1px solid rgba(0, 0, 0, 0.2)";

  // Get search fields, fallback to default fields if not configured
  const searchFields = searchArray?.length > 0 
    ? searchArray 
    : ["name", "title", "firstname"];

  const triggerSearch = () => {
    if (searchQuery.trim()) {
      const cleanedQuery = searchQuery
        .trim()
        .replace(/[\x00-\x1F\x7F-\x9F\u200B]/g, "");

      // Create case-insensitive prefix match filters for each search field
      const orFilters = searchFields.map((key) => ({
        [key]: {
          $regex: `^${cleanedQuery}`,
          $options: "i",
        },
      }));

      updateQuery({ $or: orFilters });
    } else {
      // Reset to default query when search is empty
      updateQuery({});
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      triggerSearch();
    } else if (event.key === "Backspace" && searchQuery.trim() === "") {
      triggerSearch();
    }
  };

  const handleBlur = () => {
    triggerSearch();
  };

  return (
    <>
      <ToolBox height="64px" borderTop={border}>
        <SwitchIcon
          iconNames={["task-backlog", "Burger"]}
          size="17"
          id="trigger-search-icon"
        />
        
        <FieldBase
          placeholder="Enter your search..."
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          fontSize="16px"
          width="100%"
          borderRadius="9999px"
        />
        
        <SwitchIcon
          iconNames={["lens"]}
          size="17"
          onClick={triggerSearch}
          id="trigger-search-icon"
        />
        
        <SwitchIcon
          iconNames={["caret-right"]}
          size="17"
          onClick={toggle}
          id="toggle-search-view"
        />
      </ToolBox>

      <ProjectsIcons />
    </>
  );
};

export default SimpleSearch;