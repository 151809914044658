import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import QwanyxApp from './QwanyxApp.js';
import reportWebVitals from './reportWebVitals.js';
import 'qwanyx/css/typography.css';
import 'qwanyx/css/dynamicGrid.css';
import 'qwanyx/css/animation.css';
import 'qwanyx/css/containers.css';
import 'qwanyx/css/menusystem.css';
import 'qwanyx/css/editors.css';
import 'qwanyx/css/dragdrop.css';
import 'qwanyx/css/shadows.css';
import 'qwanyx/css/ide.css';
//import "gridstack/dist/gridstack.min.css";
import { AuthProvider } from 'qwanyx'; // Adjust import paths if necessary
import { BrowserRouter } from 'react-router-dom';
import QwanixProvider from './qwanyx/providers/QwanyxProvider';
import DragContext from './qwanyx/providers/DragContext';
import 'bulma/css/bulma.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <QwanixProvider>
          <DragContext>
            <QwanyxApp />
          </DragContext>
        </QwanixProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
