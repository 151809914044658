import React from 'react';
// Make sure prop-types is installed in your project
// If not, run: npm install --save prop-types
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const CurrentDate = ({ size = '4', color = 'dark', format = 'DD-MM-YY', prefix = 'Jemamme / Meuse, Le', justify = 'left' }) => {
  const formatDate = (date, formatString) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const fullYear = date.getFullYear();
    
    switch (formatString) {
      case 'DD-MM-YY':
        return `${day}-${month}-${year}`;
      case 'DD-MM-YYYY':
        return `${day}-${month}-${fullYear}`;
      case 'MM-DD-YY':
        return `${month}-${day}-${year}`;
      case 'MM-DD-YYYY':
        return `${month}-${day}-${fullYear}`;
      default:
        return `${day}-${month}-${year}`;
    }
  };

  const today = new Date();
  const formattedDate = formatDate(today, format);

  return (
    <div className="current-date">
      <h1 className={`title is-${size} has-text-${color} has-text-${justify}`}>
        {prefix} {formattedDate}
      </h1>
    </div>
  );
};

// Define prop types after the component
CurrentDate.propTypes = {
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.string,
  format: PropTypes.oneOf(['DD-MM-YY', 'DD-MM-YYYY', 'MM-DD-YY', 'MM-DD-YYYY']),
  prefix: PropTypes.string,
  justify: PropTypes.oneOf(['left', 'centered', 'right', 'justified'])
};

export default CurrentDate;

// Usage examples:
// <CurrentDate />
// <CurrentDate size="3" color="primary" format="DD-MM-YYYY" />
// <CurrentDate prefix="Paris, Le" format="DD-MM-YYYY" />
// <CurrentDate justify="right" />