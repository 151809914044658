import React, { useState, useRef, useEffect } from "react";
import { FixedSizeList } from "react-window";
import { SearchEditor, useListContext, FlexLayout, arrayToBreadcrumbs } from "qwanyx";

export const VirtualizedList = ({ onToggleView, setProviderId, heightOffset = 128 }) => {
  // Get everything we need from context
  const {
    items,
    hasMore,
    isLoading,
    fetchBatch,
    titleArray,
    briefArray,
    subtitleArray,
  } = useListContext();

  // State to store list height dynamically
  const [listHeight, setListHeight] = useState(0);
  const containerRef = useRef();

  // Handle item selection
  const handleToggleView = (item) => {
    setProviderId(item._id);
    onToggleView();
  };

  // Dynamic height calculation
  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setListHeight(containerRef.current.offsetHeight);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  // Text building functions
  const buildPrimaryText = (item) => {
    return arrayToBreadcrumbs(item, titleArray, " ", 45)
  };

  const buildSecondaryText = (item) => {
    return arrayToBreadcrumbs(item, briefArray, " | ", 90)
  };

  const buildBriefText = (item) => {
    return arrayToBreadcrumbs(item, subtitleArray, " | ", 90)
  };

  const handleGoToStack = (item) => {
    alert("Item: " + item._id);
  };
  /*
    const getRandomPicsumUrl = (width = 500, height = 500) => {
      const randomId = Math.floor(Math.random() * 1000) + 1;
      return `https://picsum.photos/id/${randomId}/${width}/${height}`;
    };
  */
  const Row = ({ index, style }) => {
    const item = items[index];

    if (!item) {
      return (
        <div style={style}>
          {isLoading ? "Loading..." : "No data available."}
        </div>
      );
    }

    return (
      <div
        style={{
          ...style,
          cursor: "pointer",
          borderBottom: "1px solid #eee",
        }}
        onClick={() => handleToggleView(item)}
      >
        <FlexLayout
          direction="row"
          width="100%"
          height="100px"
          aSize="100px"
          showA={false}
          bSize="150px"
          padding='5px'
          //style={{ padding: "6px" }}
          /*
          a={
            <Passepartout
              src={getRandomPicsumUrl()}
              width="100px"
              height="100px"
              translateX='10px'
              translateY='20px'
              objectFit='cover'
              scale={1.8}
            //rotate={10}
            />
          }
          */
          m={
            <>
              <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                {buildPrimaryText(item)}
              </div>
              <div style={{ fontSize: "14px", color: "#666" }}>
                {buildSecondaryText(item)}
              </div>
              <button

                onClick={(event) => {
                  event.stopPropagation(); // Prevents event from bubbling up
                  handleGoToStack(item);
                }}
                style={{ marginTop: "5px", padding: "5px", cursor: "pointer" }}
              >
                Go To Stack
              </button>
            </>
          }
          b={
            <div style={{ fontSize: "12px", color: "#666" }}>
              {buildBriefText(item)}
            </div>
          }
        />
      </div>
    );
  };

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
    <SearchEditor />
      <div style={{ flexGrow: 1 }}>
        {listHeight > 0 && (
          <FixedSizeList
            height={listHeight - heightOffset}
            width="100%"
            itemCount={items.length}
            itemSize={100}
          /*
          onItemsRendered={({ visibleStopIndex }) => {
            if (visibleStopIndex + 10 > items.length && hasMore) {
              //fetchBatch();
            }
          }}
          */
          >
            {Row}
          </FixedSizeList>
        )}
      </div>
    </div>
  );
};