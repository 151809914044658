import React, { createContext, useContext, useState } from 'react';

// Context to manage drag state
export const DragContext = createContext(null);

// Provider component
export const DragProvider = ({ children }) => {
  const [dragState, setDragState] = useState({
    isDragging: false,
    payload: null,
    dragImage: null
  });

  return (
    <DragContext.Provider value={[dragState, setDragState]}>
      {children}
      {/* Drag Preview */}
      {dragState.isDragging && dragState.dragImage && (
        <div 
          className="drag-preview"
          style={{ 
            left: dragState.x, 
            top: dragState.y, 
          }}
        >
          {dragState.dragImage}
        </div>
      )}
    </DragContext.Provider>
  );
};

// Custom hook for drag operations
export const useDrag = () => {
  const [dragState, setDragState] = useContext(DragContext);
  
  if (!dragState) {
    throw new Error('useDrag must be used within a DragProvider');
  }

  const startDrag = (payload, dragImage) => {
    const handleMove = (e) => {
      setDragState(prev => ({
        ...prev,
        x: e.clientX,
        y: e.clientY
      }));
    };

    const handleUp = () => {
      setDragState({
        isDragging: false,
        payload: null,
        dragImage: null
      });
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleUp);
    };

    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleUp);

    setDragState({
      isDragging: true,
      payload,
      dragImage,
      x: 0,
      y: 0
    });
  };

  return {
    startDrag,
    isDragging: dragState.isDragging
  };
};

// Custom hook for drop operations
export const useDrop = (onDrop) => {
  const [dragState] = useContext(DragContext);
  const [isOver, setIsOver] = useState(false);

  const dropProps = {
    onMouseEnter: () => setIsOver(true),
    onMouseLeave: () => setIsOver(false),
    onMouseUp: () => {
      if (dragState.isDragging && dragState.payload) {
        onDrop(dragState.payload);
      }
      setIsOver(false);
    },
    className: `dropzone ${isOver ? 'dropzone-active' : ''}`
  };

  return { dropProps, isOver };
};

// Example Draggable Component
export const Draggable = ({ children, payload, dragImage }) => {
  const { startDrag, isDragging } = useDrag();

  return (
    <div
      onMouseDown={() => startDrag(payload, dragImage)}
      className={`draggable ${isDragging ? 'dragging' : ''}`}
    >
      {children}
    </div>
  );
};

// Example DropZone Component
export const DropZone = ({ onDrop, children }) => {
  const { dropProps } = useDrop(onDrop);

  return (
    <div {...dropProps}>
      {children}
    </div>
  );
};

export default DragProvider;