import React from 'react';
import 'bulma/css/bulma.min.css';
import { TreeList, Card, useQwanyx, CardHeader, IdeHeader, TreeProvider, DroppableWrapper, TreeHeader } from 'qwanyx';

export const Ide = ({ children }) => {
  const colmumnHeight = "calc(100vh - 18px)";
  const cardHeight = "calc(100vh - 50px)";
  const { selectedCard, updateSelectedCard } = useQwanyx(); // Get current selections

  const handelItemsDropped = (droppedData) => {
    if (droppedData.items.length > 0) {
      const item = droppedData.items[0];
      if (item) {
        updateSelectedCard({
          _id: item.index,
          ed: item.ed,
          di: item.di,
          pa: item.pa,
        });
      }
    }
  };

  return (
    <TreeProvider>
      {/* Header */}
      <IdeHeader />
      {/* Main Content */}

      <div className="layout-main-content" style={{ backgroundColor: "transparent" }}>
        <div>
          <TreeHeader />
          <div
            className="has-background-white"
            style={{
              height: colmumnHeight,
              width: '400px',
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <TreeList />
          </div>
        </div>
        {/* Main Content Area */}
        <div className="container" style={{ height: colmumnHeight, overflow: "auto" }}>
          <div style={{ height: "32px" }}>
            <CardHeader />
          </div>
          <div style={{ height: cardHeight, overflow: "auto" }}>
            <DroppableWrapper
              style={{ height: '100%' }}
              onItemDropped={handelItemsDropped}
            >
              <div className="content-area">
                {/* Just using _id as the key is sufficient */}
                {selectedCard._id && (
                  <Card
                    key={selectedCard._id}
                    _id={selectedCard._id}
                    di={selectedCard.di}
                    ed={selectedCard.ed}
                    pa={selectedCard.pa}
                  />
                )}
              </div>
            </DroppableWrapper>
          </div>
        </div>

        <div className="layout-config-list has-background-white" style={{ height: colmumnHeight, overflow: "auto" }} />
      </div>
    </TreeProvider>
  );
};

export default Ide;