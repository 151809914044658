import React from "react";
import { EditableText } from "qwanyx";

export const FrameTopBar = ({ startDrag }) => {
    return (
        <>
            {/* Top bar for dragging */}
            <div className="top-bar-left" onMouseDown={startDrag} />
            <div className="top-bar">
                <EditableText className="top-title" align="center" qwantumId="title" />
            </div>
            <div className="top-bar-right" onMouseDown={startDrag} />
        </>
    );
};