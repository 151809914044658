import React, { useEffect, useState } from "react";
import { useQwanyx, QwanyxProvider } from "qwanyx";
import {
  EditableText,
  Frame,
  SwitchIcon,
  QwantaProvider,
  useQwanta,
  recallChildren,
  HotDisplay,
  Drawer,
  MetaEditor,
  QwantaStack,

} from "qwanyx";


export const QwantaEditor = ({
  qwantumId = null, // Current editor's _id
  x = 0,
  y = 0,
  width = "100%",
  height = "100%",
  draggable = false,
  resizeable = false,
  children
}) => {
  const [status, setStatus] = useQwanta("status"); // Retrieve 'status' from Qwanta
  const [currentId] = useQwanta("_id"); // Get the current `_id` from context
  const [di] = useQwanta("di"); // Get the `di` (parent's _id) from context
  const [_id] = useQwanta("_id"); // Get the `di` (parent's _id) from context
  const [childrenEditors, setChildrenEditors] = useState([]); // Track rendered children
  const [childrenFetched, setChildrenFetched] = useState(false); // Prevent repeated fetching
  const statusColors = [
    "#FFFDFA", // note
    "#FFE7CC", // backlog
    "#E8F5E9", // doing
    "#CCE2F4", // done
  ];

  const { showOnlyRoot } = useQwanyx();  
  

  const addChildEditor = (qwantaType) => {
    const childProps = {
      x: 100,
      y: 100,
      width: "200px",
      height: "150px",
      position: "absolute",
    };

    setChildrenEditors((prev) => [
      ...prev,
      <QwantaProvider key={prev.length} embedEditor={false} qwantaType={qwantaType}>
        {qwantaType === "frame" ? (
          <QwantaEditor {...childProps} />
        ) : qwantaType === "stack" ? (
          <QwantaStack {...childProps} />
        ) : qwantaType === "meta" ? ( // Add support for MetaEditor
          <MetaEditor {...childProps} />
        ) : null}
      </QwantaProvider>,
    ]);
  };

  // Fetch children only once when the editor mounts
  useEffect(() => {
    const fetchChildren = async () => {
      if (childrenFetched) return;
      if (!currentId) return;
  
      try {
        const children = await recallChildren(currentId);

        // ✅ Use showOnlyRoot to filter children
        const selectedChildren = showOnlyRoot 
            ? children.filter(child => child.title === "Root") 
            : children;
  
        // 🌟 Map over the filtered results
        const renderedChildren = selectedChildren.map((child, index) => {
          const { qwantaType, frame } = child;
          return (
            <QwantaProvider key={child._id} _id={child._id} di={currentId} qwantum={child.qwantum} embedEditor={false} qwantaType={qwantaType}>
              {qwantaType === "frame" ? (
                <QwantaEditor qwantumId={child._id} {...frame} />
              ) : qwantaType === "stack" ? (
                <QwantaStack qwantumId={child._id} {...frame} />
              ) : qwantaType === "meta" ? (
                <MetaEditor
                  qwantumId={child._id}
                  {...frame}
                  x={window.innerWidth < 700 ? index * window.innerWidth : index * 500}
                  y={0}
                  width={showOnlyRoot 
                        ? "100%" // ✅ Force 100% if showOnlyRoot is true
                        : (window.innerWidth < 700 
                            ? `${window.innerWidth}px`  // ✅ Keep existing logic
                            : "500px")
                    }
                  height="100%"
                /> 
              ) : null}
            </QwantaProvider>
          );
        });
  
        setChildrenEditors(renderedChildren);
        setChildrenFetched(true);
      } catch (error) {
        console.error("Error fetching children:", error);
      }
    };
  
    fetchChildren();
  }, [currentId, childrenFetched]); // Dependencies remain unchanged



  const topLeftStyle = {
    position: 'absolute',
    top: '15px',
    left: '15px',
    width: '20px',
    height: '20px'
  };

  const topRightStyle = {
    position: 'absolute',
    top: '15px',
    right: '5px',
    width: '20px',
    height: '20px'
  };
  const bottomRightStyle = {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    width: '20px',
    height: '20px'
  };

  return (
    <div>
      <Frame
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          borderRadius: 3.5,
          padding: 0,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "rgba(0,0,0,0.3)",
          backgroundColor: statusColors[status] || "#FFFFFF",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          fontSize: "0.8em",
          position: "absolute",
          overflow: "hidden",
        }}
        qwantumId="frame"
        draggable={false}
        resizeable={false}
      >
        <HotDisplay containerStyle={topRightStyle}>
          {<SwitchIcon
            iconNames={["add"]}
            size={12}
            onClick={() => addChildEditor('frame')}
          />}
          {<SwitchIcon
            iconNames={["stack"]}
            size={12}
            onClick={() => addChildEditor('frame')}
          />}
        </HotDisplay>

        <HotDisplay containerStyle={topLeftStyle}>
          <Drawer>
            {/*<SwitchIcon
            iconNames={["title"]}
            size={18}
            onClick={() => addChildEditor('frame')}

          //qwantumId="status" // SwitchIcon updates the status in Qwanta
          />
          <SwitchIcon
            iconNames={["text"]}
            size={18}
            onClick={() => addChildEditor('stack')}
          //qwantumId="status" // SwitchIcon updates the status in Qwanta
          />*/}

            <SwitchIcon
              iconNames={["labelled-input"]}
              size={18}
              onClick={() => addChildEditor('meta')}
            //qwantumId="status" // SwitchIcon updates the status in Qwanta
            />

          </Drawer>

        </HotDisplay>

        {/*<HotDisplay containerStyle={bottomRightStyle}>
        <SwitchIcon
          iconNames={["trash"]}
          size={12}
          onClick={async () => {
            if (window.confirm("Are you sure you want to delete this qwanta?")) {
              try {
                await recursiveDelete(_id);
                console.log(`Qwanta with ID ${_id} deleted successfully.`);
                window.location.reload(); // Refresh the page
              } catch (error) {
                console.error(`Failed to delete qwanta with ID ${_id}:`, error);
              }
            }
          }}
        />
      </HotDisplay>*/}



        {/* Render Child Editors */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "calc(100% - 0px)",
            height: "calc(100% - 0px)",
            overflow: "auto",
          }}
        >
          {childrenEditors}

        </div>

      </Frame>
    </div>
  );
};
