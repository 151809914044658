import React, { useState, useEffect } from "react";
import { useQwanta } from "qwanyx"; // Import your custom hook

import { QwantumEditor, ComponentsIcons, removeQwantum } from "qwanyx";

// EDIT TAB COMPONENT
export const EditQwantaConfigValues = ({ editorConfig = [], onBack }) => {
  // Ensure `editorConfig` is always treated as an array
  const fields = Array.isArray(editorConfig) ? editorConfig : [];

  // Initialize draggable state
  const [editorItems, setEditorItems] = useState(fields);

  // Use `useQwanta` for syncing with database
  // `metaqwanta` should be replaced with the ID/key relevant to your context
  const [savedFieldValues, setQwanta] = useQwanta("metaqwanta");

  const handleNewQwantum = (newMetaqwanta) => {
    // Persist the new metaqwanta values
    setQwanta("metaqwanta", newMetaqwanta);

    // Optionally, sync the UI if it depends on the new Qwanta data
    setEditorItems(newMetaqwanta || []);
    console.log("New Qwantum data saved and UI updated.", newMetaqwanta);
  };

  const handleRemoveQwantum = (qwantumId) => {
    const newEditorItems = removeQwantum(editorItems, qwantumId);
    setEditorItems(newEditorItems);
  };



  // Save `editorItems` to the database whenever it changes
  useEffect(() => {
    const newConfig = editorItems.map((item) => ({
      type: item.type,
      defaultProps: item.defaultProps,
    }));

    // Persist updated configuration
    setQwanta("metaqwanta", newConfig);

    // Debugging (optional): Log changes being saved
    //console.log("Updated config saved to database:", newConfig);
  }, [editorItems]); // Re-run whenever `editorItems` changes
  

  const handleValuesChange = (index, updatedValues) => {
    // Log for debugging (optional)
    console.log("Handle update for index:", index, "with values:", updatedValues);

    // Create a new array with the updated item
    const updatedItems = editorItems.map((item, i) => {
      if (i === index) {
        // Update only the defaultProps for the matching item
        return {
          ...item,
          defaultProps: {
            ...item.defaultProps,
            ...updatedValues,
          },
        };
      }
      return item; // Return the item unchanged if index does not match
    });

    // Update state with the new array
    setEditorItems(updatedItems);
  };


  // Render the component
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5",
        color: "#555",
        padding: "10px",
      }}
    >
      <ComponentsIcons onEditClick={handleNewQwantum} />
      {editorItems.map((item, index) => {
        const { type, defaultProps } = item;
        const { qwantumId, labelText } = defaultProps || {};

        if (!type || !qwantumId) return null; // Skip invalid items

        return (
          <div key={index} style={{ width: "100%" }}>
            <QwantumEditor
              layout="row"
              labelText={labelText || "Edit me!"} // Default placeholder
              defaultprops={defaultProps}
              item={item} // Pass the entire item here
              onParentUpdate={(updatedValues) =>
                handleValuesChange(index, updatedValues) // Use index for updates
              }
              handleRemoveQwantum={handleRemoveQwantum}
            />
          </div>
        );
      })}


    </div>
  );
};

export default EditQwantaConfigValues;