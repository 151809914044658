import React, { useState, useRef, useCallback, useEffect } from 'react';
import Editor from '@monaco-editor/react';
import { FlexLayout, useQwanta } from 'qwanyx';
/*

TODO There is a bug when we resize the stack.
An error is reported when we scale down the window vertically.
From bottom or from left.
Even after trying to recalculate the size of the editor nothing was working.
As I don't want to block the delvelopement for this small problem 
And it's not blocking the editor I prefer to move on and come back to this problem later.
With a fixed height though no problem...
*/


export const JSONEditor = ({ qwantumId = null, labelText = 'Label', height="600px"}) => {
  const [savedText, setQwanta] = useQwanta(qwantumId);
  const [jsonContent, setJsonContent] = useState(Array.isArray(savedText) ? '[]' : '{}');
  const editorRef = useRef(null);
  const resizeTimeoutRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (savedText) {
      const formattedJson = JSON.stringify(savedText, null, 2);
      setJsonContent(formattedJson);
    }
  }, [savedText]);

  // Handle editor layout updates
  const updateEditorLayout = useCallback(() => {
    if (editorRef.current) {
      try {
        editorRef.current.layout();
      } catch (error) {
        // Silently handle layout errors
        console.debug('Layout adjustment in progress');
      }
    }
  }, []);

  // Debounced resize handler
  const handleResize = useCallback(() => {
    if (resizeTimeoutRef.current) {
      clearTimeout(resizeTimeoutRef.current);
    }

    resizeTimeoutRef.current = setTimeout(() => {
      updateEditorLayout();
    }, 100); // Adjust this delay as needed
  }, [updateEditorLayout]);

  // Set up resize observer
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      handleResize();
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [handleResize]);

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
    // Initial layout update
    setTimeout(updateEditorLayout, 100);
  };

  const saveText = useCallback(() => {
    if (!qwantumId) return;
    try {
      const parsedContent = JSON.parse(jsonContent);
      setQwanta(qwantumId, parsedContent);
    } catch (e) {
      console.error('Invalid JSON', e);
    }
  }, [qwantumId, jsonContent, setQwanta]);

  const handleEditorChange = (value) => {
    setJsonContent(value || (Array.isArray(savedText) ? '[]' : '{}'));
  };

  const formatJSON = () => {
    if (editorRef.current) {
      const model = editorRef.current.getModel();
      const value = model.getValue();
      try {
        const formattedJson = JSON.stringify(JSON.parse(value), null, 2);
        editorRef.current.setValue(formattedJson);
        setJsonContent(formattedJson);
      } catch (error) {
        console.error('Invalid JSON', error);
      }
    }
  };

  return (
    <div 
      ref={containerRef}
      style={{
        width: "100%",
        height,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <FlexLayout
        direction="row"
        width="100%"
        height="30px"
        aSize="100px"
        bSize="150px"
        aAlign='flex-start'
        aJustify='center'
        mAlign='center'
        mJustify='center'
        bAlign='flex-end'
        bJustify='center'
        a={
          <button onClick={formatJSON}>
            Format JSON
          </button>
        }
        m={
          <div class="componentTitle">
            {labelText}
          </div>
        }
        b={
          <button onClick={saveText}>
            Save
          </button>
        }
      />

      <div style={{ flex: 1 }}>
        <Editor
          height="100%"
          language="json"
          value={jsonContent}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
          theme="vs-dark"
          options={{
            minimap: { enabled: false },
            automaticLayout: true
          }}
        />
      </div>
    </div>
  );
};

export default JSONEditor;