import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { SvgInterpreter, useSwitchState } from "qwanyx";

export const SwitchIcon = ({
  iconNames = ["circle", "square"],
  size = 24,
  fill = "currentColor",
  stroke = "none",
  strokeWidth = 1,
  animation = "",
  onClick = () => { },
  className = "",
  rotation = 0,
  id = "switch-icon",
  qwantumId,
}) => {
  const { activeIndex, setActiveIndex } = useSwitchState(qwantumId);

  const divSize = size + 0.1;
  const handleToggle = () => {
    const nextIndex = (activeIndex + 1) % iconNames.length;
    setActiveIndex(nextIndex);
    onClick(iconNames[nextIndex], id);
  };

  const currentIcon = iconNames[activeIndex] || iconNames[0];

  return (
    <div
      key={`switch-icon-${id}`}
      id={id}
      className={`${animation} ${className}`}
      style={{
        "--rotation": `${rotation}deg`,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: `${size}px`,
        minWidth: `${size}px`,
        lineHeight: 0,
        overflow: "hidden",
        transform: `rotate(var(--rotation))`,
        transformOrigin: "50% 50%",
      }}
      onClick={handleToggle}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <SvgInterpreter
          style={{ pointerEvents: "none" }}
          iconName={currentIcon}
          size={size}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </Suspense>
    </div>
  );
};

SwitchIcon.propTypes = {
  iconNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  animation: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  rotation: PropTypes.number,
  id: PropTypes.string,
  qwantumId: PropTypes.string,
};

export default SwitchIcon;