import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useQwanta, Setup, VirtualizedList, Navigation, QwantaProvider, deleteInstance, ConfigRenderer, SearchEditor } from "qwanyx";

export const MetaQwanta = forwardRef(
  ({ qwantumId = "metaqwanta", navigationHeight = "48px", height = "100%" }, ref) => {

    const [sourceSetting,] = useQwanta("sourceSetting");
    const [editorConfig, setEditorConfig] = useState([]);
    const [providerId, setProviderId] = useState(null);
    const [showVirtualizedList, setShowVirtualizedList] = useState(true);
    const [showSetup, setShowSetup] = useState(false);
    const [savedConfig, setQwanta] = useQwanta(qwantumId);
    const sourceEd = sourceSetting?.sources?.[0];

    useEffect(() => {
      if (savedConfig && Array.isArray(savedConfig)) {
        setEditorConfig(savedConfig);
      } else {
        setEditorConfig([]);
      }
    }, [savedConfig]);

    const toggleVirtualizedList = () => {
      setShowVirtualizedList((prev) => !prev);
    };

    const toggleSetupView = () => {
      setShowSetup((prev) => !prev);
    };

    const addRecord = () => {
      setProviderId(0);
      setTimeout(() => setProviderId(null), 0);
      if (showVirtualizedList) {
        toggleVirtualizedList();
      }
    };

    const deleteRecord = async () => {
      try {
        await deleteInstance(providerId);
        setProviderId(0);
        setTimeout(() => setProviderId(null), 0);

        //if (!showVirtualizedList) {
        //toggleVirtualizedList();
        // }
        console.log(`Deleted record with ID: ${providerId}`);
      } catch (err) {
        console.error("Error deleting record:", err);
      }
    };

    // New function to handle navigation
    const navigateRecord = (newId) => {
      setProviderId(null);
      setTimeout(() => {
        setProviderId(newId);
      }, 0);
    };

    useImperativeHandle(ref, () => ({
      addRecord: () => addRecord(),
    }));

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height,

        }}
      >
        {
          showSetup ? (
            <Setup editorConfig={editorConfig} />
          ) : showVirtualizedList ? (
            <>
              <VirtualizedList
                onToggleView={toggleVirtualizedList}
                onSetupView={toggleSetupView}
                setProviderId={setProviderId}
              />
            </>
          ) : (
            <QwantaProvider embedEditor={false} qwantaType="instance" _id={providerId} ed={sourceEd}>
              <ConfigRenderer editorConfig={editorConfig} providerId={providerId} />
            </QwantaProvider>
          )
        }
        <div style={{ height: navigationHeight }}>
          <Navigation
            navigateRecord={navigateRecord}
            onAddRecord={addRecord}
            onToggleView={toggleVirtualizedList}
            onSetupView={toggleSetupView}
            onDeleteRecord={deleteRecord}
          />
        </div>
      </div>
    );
  }
);

export default MetaQwanta;