import React from "react";
import { DynamicGrid, IconButton, useQwanta, addQwantum } from "qwanyx";


const ICON_SIZE = 18; // Size of IconButton in pixels
const SPACING = 25; // Spacing between grid cells

export const ComponentsIcons = ({ onEditClick, qwantumId="metaqwanta"}) => {
    const [savedMetaqwanta, setQwanta] = useQwanta(qwantumId);
  
    const icons = [
      { id: 1, iconNames: ["title"], label: "Title", type: "LabelledField" },
      { id: 2, iconNames: ["text"], label: "Text", type: "LabelledArea" },
      { id: 3, iconNames: ["external-link"], label: "Link", type: "LabelledCom" },
      { id: 4, iconNames: ["image"], label: "Image", type: "Img" },
      { id: 5, iconNames: ["json"], label: "Json", type: "JSONEditor" },
      { id: 6, iconNames: ["image"], label: "Invoice", type: "Invoice" },
    ];
  
    const handleIconClick = (type) => {
      try {
        // Add a new Qwantum object to the metaqwanta list using its type
        const newMetaqwanta = addQwantum(savedMetaqwanta, type);
  
        // Persist the updated metaqwanta
        setQwanta("metaqwanta", newMetaqwanta);
  
        // Notify the parent via onEditClick
        if (onEditClick) {
          onEditClick(newMetaqwanta);
        }
      } catch (error) {
        console.error("Failed to add Qwantum:", error.message);
      }
    };
  
    return (
      <DynamicGrid
        iconSize={ICON_SIZE}
        spacing={SPACING}
        cellSize={30}
        padding={5}
      >
        {icons.map((icon) => (
          <IconButton
            fontSize="10px"
            key={icon.id}
            direction="column"
            iconNames={icon.iconNames}
            size={ICON_SIZE}
            onClick={() => handleIconClick(icon.type)} // Pass the type of Qwantum to add
          >
            {icon.label}
          </IconButton>
        ))}
      </DynamicGrid>
    );
  };