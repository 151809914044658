import React, { useState, useEffect } from "react";
import Icon from '@mdi/react';
import { mdiContentCopy, mdiTrashCanOutline, mdiCogOutline, mdiPlus, mdiMenu } from '@mdi/js';
import { useQwanyx, Dropdown, fetchQwantas, persistQwantum, useTreeContext } from 'qwanyx';


export const CardHeader = ({
    height = "32px",
    iconSize = "18px",
    gap = "8px",
    backgroundColor = "#f5f5f5",
}) => {

    const { newCard, deleteCard, domainId, selectedCard } = useQwanyx();
    const { updateItemProperty } = useTreeContext(); // Get the new method from TreeContext
    const [configItems, setConfigItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    // Fetch the config qwantas when the component mounts or domainId changes
    useEffect(() => {
        const loadConfigs = async () => {
            try {
                setIsLoading(true);
                
                // Create an array to hold all config items
                let allConfigItems = [];
                
                // Load domain-specific configs if domainId exists
                if (domainId) {
                    const domainQuery = { di: domainId, isconfig: "true" };
                    const projection = { title: 1, _id: 1 };
                    
                    const domainResults = await fetchQwantas(domainQuery, projection);
                    
                    // Map the domain-specific results
                    const domainConfigItems = domainResults.map(item => ({
                        content: item.title || "Untitled",
                        _id: item._id
                    }));
                    
                    allConfigItems = [...domainConfigItems];
                }
                
                // Load default configs (regardless of whether domainId exists)
                const defaultDomainId = "645b5e00c7b61df466430901";
                const defaultQuery = { di: defaultDomainId, isconfig: "true" };
                const projection = { title: 1, _id: 1 };
                
                const defaultResults = await fetchQwantas(defaultQuery, projection);
                
                // Map the default results
                const defaultConfigItems = defaultResults.map(item => ({
                    content: item.title || "Untitled (Default)",
                    _id: item._id
                }));
                
                // Combine domain-specific and default configs
                allConfigItems = [...allConfigItems, ...defaultConfigItems];
                
                // Update state with all config items
                setConfigItems(allConfigItems);
                
            } catch (error) {
                console.error("Error loading configs:", error);
            } finally {
                setIsLoading(false);
            }
        };
        
        loadConfigs();
    }, [domainId]);
    
    const handlePlusClick = () => {
        newCard();
    };
    
    const handleDeleteClick = () => {
        deleteCard();
    };
    
    const handleConfigSelect = async (item) => {
        // Check if we have a selectedCard with an _id
        if (selectedCard && selectedCard._id) {
            try {
                // Update the pa property in the database
                await persistQwantum(selectedCard._id, "pa", item._id);
                
                // IMPORTANT NEW CODE: Update the tree structure with the new pa value
                updateItemProperty(selectedCard._id, "pa", item._id);
                
                // Log success
                console.log(`Configuration applied: ${item.content} (ID: ${item._id})`);
                
                // Provide visual feedback to the user
                //alert(`Configuration "${item.content}" has been applied successfully.`);
                
            } catch (error) {
                console.error("Error applying configuration:", error);
                alert("Failed to apply configuration. Please try again.");
            }
        } else {
            console.warn("No card selected. Please select a card before applying a configuration.");
            alert("Please select a card first before applying a configuration.");
        }
    };

    return (
        <div style={{
            height,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 8px",
            backgroundColor
        }}>
            <div style={{ height, display: "flex", alignItems: "center", justifyContent: "left", gap }}>
                <div style={{ display: "flex", gap }}>
                    <Icon path={mdiMenu} size={iconSize} />
                    <Dropdown 
                        buttonText={isLoading ? "Loading..." : "Configurations"}
                        items={configItems}
                        onItemClick={handleConfigSelect}
                    />
                </div>
            </div>
            <div style={{ display: "flex", gap }}>
                {/* Buttons are kept commented as in the original */}
            </div>
        </div>
    );
};

export default CardHeader;