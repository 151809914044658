import app from "./realm-config";

export function qwanyxCollection() {
  return app.currentUser.mongoClient("mongodb-atlas").db("mainlistdb").collection("qwanyx");
}


export async function persist(delta, qwantumId, di, ed, backup = false) {
  try {
    const collection = qwanyxCollection();

    const updates = { $set: delta }; // Base updates

    if (backup) {
      // Add a backup entry if the backup flag is true
      const backupEntry = {
        [new Date().toISOString()]: delta, // Save only the changed properties with a timestamp
      };
      updates.$push = { backup: { $each: [backupEntry], $slice: -100 } }; // Append to backup
    }

    // Update the document
    await collection.updateOne(
      { _id: qwantumId },
      {
        ...updates,
        $setOnInsert: { di, ed }, // Set di and ed if it's a new document
      },
      { upsert: true }
    );
  } catch (error) {
    console.error("Error persisting Qwantum:", error);
    throw error;
  }
}

export async function recallChildren(parentId) {
  try {
    const collection = qwanyxCollection();

    // Ensure parentId is a string
    const queryParentId = String(parentId);

    console.log("Querying children with parentId:", queryParentId);

    // Directly use .find() which returns an array in MongoDB Realm
    const children = await collection.find({
      di: queryParentId,
      $or: [
        { qwantaType: 'frame' },
        { qwantaType: 'meta' }
      ]
    });

    console.log("Children retrieved:", children);

    return children; // Return the array of children
  } catch (error) {
    console.error("Error recalling children:", error);
    throw error;
  }
}

//the deleteQwanta Delete the qwanta and reassing the children to the parent...
export async function deleteQwanta(qwantumId) {
  try {
    const collection = qwanyxCollection();

    // Fetch the document to be deleted
    const qwanta = await collection.findOne({ _id: qwantumId });

    if (!qwanta) {
      throw new Error(`Qwanta with ID ${qwantumId} does not exist.`);
    }

    const parentDi = qwanta.di; // Get the parent ID of the qwanta

    // Update children to set their `di` to the parent's `di`
    await collection.updateMany(
      { di: String(qwantumId) }, // Find children of the qwanta
      { $set: { di: parentDi } } // Reassign their parent ID
    );

    // Delete the qwanta
    await collection.deleteOne({ _id: qwantumId });

    console.log(`Qwanta ${qwantumId} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting Qwanta:", error);
    throw error;
  }
}


export async function recursiveDelete(qwantumId) {
  try {
    const collection = qwanyxCollection();

    // Function to get all descendants
    async function getDescendants(parentId) {
      const stack = [parentId]; // Stack for traversal
      const toDelete = []; // Store all IDs to delete

      while (stack.length) {
        const currentId = stack.pop();
        toDelete.push(currentId); // Mark current ID for deletion

        // Fetch children of the current node
        const children = await collection.find({ di: String(currentId) });
        children.forEach((child) => stack.push(child._id)); // Add children to the stack
      }

      return toDelete; // Return all IDs to delete
    }

    // Fetch all descendants
    const allToDelete = await getDescendants(qwantumId);

    // Batch delete all documents
    await collection.deleteMany({ _id: { $in: allToDelete } });

    console.log(`Qwanta ${qwantumId} and its descendants deleted successfully.`);
  } catch (error) {
    console.error("Error during optimized recursive deletion:", error);
    throw error;
  }
}




export async function sync(qwantumId) {
  try {
    const collection = qwanyxCollection();

    // Fetch the full document
    const document = await collection.findOne({ _id: qwantumId });
    return document; // Return the full document to Qwanta.js for merging
  } catch (error) {
    //console.error("Error syncing Qwantum:", error);
    throw error;
  }
}


//DOCUMENTS NAVIGATION

export async function getBatch(parentDi, startId = null, limit = 50) {
  try {
    const collection = qwanyxCollection();

    // Construct the query
    const query = startId
      ? { di: parentDi, _id: { $gt: startId } } // Fetch records after the `startId`
      : { di: parentDi }; // Fetch from the beginning if no `startId`

    const batch = await collection
      .find(query)
      .sort({ _id: 1 }) // Sort ascending by `_id`
      .limit(limit) // Limit to `limit` records
      .toArray(); // Convert cursor to an array

    return batch; // Return the fetched batch
  } catch (error) {
    console.error("Error fetching batch of Qwantas:", error);
    throw error;
  }
}

export async function getPreviousBatch(parentDi, startId, limit = 50) {
  try {
    const collection = qwanyxCollection();

    const batch = await collection
      .find({
        di: parentDi,
        _id: { $lt: startId }, // Fetch records before the `startId`
      })
      .sort({ _id: -1 }) // Sort descending by `_id`
      .limit(limit) // Limit to `limit` records
      .toArray();

    // Reverse the batch to maintain ascending order
    return batch.reverse();
  } catch (error) {
    console.error("Error fetching previous batch of Qwantas:", error);
    throw error;
  }
}

export async function getTotalCount(parentDi) {
  try {
    const collection = qwanyxCollection();
    const count = await collection.countDocuments({ di: parentDi });
    return count; // Return the total count of documents
  } catch (error) {
    console.error("Error fetching total count of Qwantas:", error);
    throw error;
  }
}
