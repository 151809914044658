import React, { useEffect, useRef } from "react";
import {
  Textarea,
  EditableText,
  LabelledField,
  LabelledArea,
  LabelledCom,
  JSONEditor,
  Img,
  Invoice,
  CheckBox,
  ImageSection,
} from "qwanyx";

export const ConfigRenderer = ({ editorConfig, providerId }) => {
  const containerRef = useRef(null);

  // Reset scroll position when providerId changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [providerId]); // This will run whenever providerId changes

  const componentsMap = {
    Textarea,
    EditableText,
    LabelledField,
    LabelledArea,
    LabelledCom,
    JSONEditor,
    Img,
    Invoice,
    CheckBox,
    ImageSection,
  };

  return (
    <div
      ref={containerRef}
      style={{
        flexGrow: 1,
        overflow: "auto",
        position: "relative",
        opacity: providerId === null ? 0 : 1, // Hide content during refresh
        transition: "opacity 0.15s ease", // Smooth transition for opacity
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          padding: 15,
        }}
      >
        {editorConfig.map((component, index) => {
          const Component = componentsMap[component.type];

          if (!Component) {
            console.warn(`Unknown component type: ${component.type}`);
            return null;
          }

          return (
            <Component
              key={`${providerId}-${index}`}
              {...component.defaultProps}
              qwantumId={
                component.defaultProps.qwantumId || `component-${index}`
              }
            />
          );
        })}
        {editorConfig.length === 0 && (
          <p>No components configured. Add components to start editing.</p>
        )}
      </div>
    </div>
  );
};

export default ConfigRenderer;