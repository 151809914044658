import React, { useState, useEffect, useCallback, useRef } from "react";
import { LabelledField, LabelledArea, JSONQwantaEditor } from "qwanyx";

export const ListSettingsTab = ({ onBack }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5",
        //backgroundColor: "red",
        color: "#555",
        padding: "10px",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          width: "100%",
          overflow: "auto",
          position: "relative",
          transition: "opacity 0.15s ease", // Smooth transition for opacity
        }}
      >

        {/*<LabelledArea
          labelText='Data Sources'
          qwantumId="sourcestack"
          placeholder="Paste the UUID's of source stacks..."
          layout="Column"
          labelAlign='left'
        />
        <LabelledArea
          labelText='Filters'
          qwantumId="listFilter"
          placeholder="Pre-select the data"
          layout="Column"
          labelAlign='left'
        />*/}

        <LabelledField
          labelText='Search Properties'
          qwantumId="listSearch"
          placeholder="Properties to search"
          layout="Column"
          labelAlign='left'
        />
        <LabelledField
          labelText='Stacks to include'
          qwantumId="searchStacks"
          placeholder="uuids seperated by commas..."
          layout="Column"
          labelAlign='left'
        />
        <LabelledField
          labelText='Sorting Properties'
          qwantumId="listSort"
          placeholder="Properties sorting order"
          layout="Column"
          labelAlign='left'
        />
        <LabelledField
          labelText='Title Properties'
          qwantumId="listTitle"
          placeholder="Properties to display"
          layout="Column"
          labelAlign='left'
        />
        <LabelledField
          labelText='SubTitle Properties'
          qwantumId="listSubTitle"
          placeholder="Properties to display"
          layout="Column"
          labelAlign='left'
        />
        <LabelledField
          labelText='Brief Properties'
          qwantumId="listBrief"
          placeholder="Properties to display"
          layout="Column"
          labelAlign='left'
        />
        <LabelledField
          labelText='Shared with'
          qwantumId="sharedwith"
          placeholder="UUIDs, separated by commas..."
          layout="Column"
          labelAlign='left'
        />
        <JSONQwantaEditor
          qwantumId="sourceSetting"
          height="450px"
          labelText="Source configuration"
        />
      </div>
    </div>
  );
};

export default ListSettingsTab;