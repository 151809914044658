import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTextPersistence } from "qwanyx";

const normalizeLineBreaks = (text) => {
  return text ? text.replace(/\r\n|\r/g, "\n") : "";
};

export const Textarea = forwardRef(
  ({ 
    fontSize = "12px", 
    placeholder = "Enter text here...", 
    onSave, 
    qwantumId, 
    value, 
    rows = 4,
    resize = "none" 
  }, ref) => {

    const { text, handleBlur: persistenceBlur } = useTextPersistence(qwantumId, onSave);
    const [prevValue, setPrevValue] = useState(value || text || "");

    const handleBlur = (e) => {
      const newValue = normalizeLineBreaks(e.target.value);

      if (newValue !== prevValue) {
        persistenceBlur(e);
        if (onSave) {
          onSave(newValue);
        }
        setPrevValue(newValue);
      }
    };

    useImperativeHandle(ref, () => ({
      getValue: () => text,
    }));

    return (
      <div className="field">
        <div className="control">
          <textarea
            className="textarea" // Bulma's textarea class
            style={{ fontSize, resize }}
            defaultValue={normalizeLineBreaks(value || text)}
            placeholder={placeholder}
            rows={rows}
            onBlur={handleBlur}
          />
        </div>
      </div>
    );
  }
);

export default Textarea;