// ListContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useQwanta, getInstancesBatch } from "qwanyx";

export const ListContext = createContext();

export const ListProvider = ({ children, initialQuery = "" }) => {
    // State management
    const [editorId] = useQwanta("_id");
    const [frameId] = useQwanta("di");
    const [sourceSetting] = useQwanta("sourceSetting");
    const [refreshKey, setRefreshKey] = useState(0);
    const [query, setQuery] = useState(initialQuery);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [instanceIds, setInstanceIds] = useState([]);

    // Move all useQwanta calls here
    const [listTitle] = useQwanta("listTitle");
    const [listBrief] = useQwanta("listBrief");
    const [listSearch] = useQwanta("listSearch");
    const [listSort] = useQwanta("listSort");
    const [listSubTitle] = useQwanta("listSubTitle");

    // Helper function to process lists
    const processList = (list) =>
        list?.trim()?.length > 0
            ? list.split(",").map((field) => field.trim())
            : null;

    // Process all configuration arrays
    const titleArray = processList(listTitle);
    const briefArray = processList(listBrief);
    const searchArray = processList(listSearch);
    const sortArray = processList(listSort);
    const subtitleArray = processList(listSubTitle);

    // Combine all arrays into a unique projection list
    const projectionList = Array.from(
        new Set([
            ...(titleArray || []),
            ...(briefArray || []),
            ...(searchArray || []),
            ...(sortArray || []),
            ...(subtitleArray || []),
        ])
    );

    // Fetch data function
    const fetchBatch = useCallback(async () => {
        if (isLoading || !hasMore) return;
        setIsLoading(true);

        try {
            const limit = 6000;
            const skip = offset;
            const projection = projectionList.reduce(
                (acc, field) => ({ ...acc, [field]: 1 }),
                { _id: 1 }
            );

            const newItems = await getInstancesBatch(
                query,
                skip,
                limit,
                projection,
                sortArray
            );

            setItems(prevItems => [...prevItems, ...newItems]);
            setOffset(prevOffset => prevOffset + newItems.length);
            setInstanceIds(prevIds => [
                ...prevIds,
                ...newItems.map(item => item._id)
            ]);

            if (newItems.length < limit) setHasMore(false);
        } catch (err) {
            console.error("Error fetching data in batch:", err);
        } finally {
            setIsLoading(false);
        }
    }, [query, offset, hasMore, projectionList, sortArray, isLoading]);

    const navigateTo = useCallback((direction) => {
        if (direction === 0) {
            setCurrentIndex(0); // Navigate to first record
            return;
        }
        
        if (direction === undefined) {
            setCurrentIndex(instanceIds.length - 1); // Navigate to last record
            return;
        }
    
        const newIndex = Math.max(0, Math.min(instanceIds.length - 1, currentIndex + direction));
        setCurrentIndex(newIndex);
        /*
        // If we're near the end and there's more data, fetch it
        if (newIndex + 5 >= items.length && hasMore) {
            fetchBatch();
        }
        */
    }, [currentIndex, instanceIds.length, items.length, hasMore, fetchBatch]);

    const navigateToFirst = useCallback(() => {
        setCurrentIndex(0);
    }, []);

    const navigateToLast = useCallback(async () => {
        // If we have more items to load, fetch them all
        while (hasMore) {
            await fetchBatch();
        }
        setCurrentIndex(instanceIds.length - 1);
    }, [hasMore, fetchBatch, instanceIds.length]);

    // Reset and refresh data when query changes
    useEffect(() => {
        setItems([]);
        setOffset(0);
        setHasMore(true);
        setInstanceIds([]);
        setCurrentIndex(0); // Reset currentIndex when query changes
        fetchBatch();
    }, [query]);

    // Helper function to build the base query
    const buildBaseQuery = useCallback((frameId, editorId, sourceSetting) => {
        // Start with the frame ID which is always required
        const baseQuery = { di: frameId };

        // Handle editor sources
        if (sourceSetting?.sources?.length > 0) {
            baseQuery.ed = { $in: sourceSetting.sources };
        } else {
            baseQuery.ed = editorId;
        }

        return baseQuery;
    }, []);

    // Helper function to process MongoDB filter
    const processMongoDBFilter = useCallback((filter) => {
        if (!filter) return {};

        // No processing needed - use MongoDB syntax directly
        return filter;
    }, []);

    // Initialize query with editorId, frameId, and sourceSetting
    useEffect(() => {
        if (editorId && frameId) {
            // Build the base query with frame and editor logic
            const baseQuery = buildBaseQuery(frameId, editorId, sourceSetting);

            // Process and merge MongoDB-style filters
            if (sourceSetting?.filter) {
                const mongoDBFilter = processMongoDBFilter(sourceSetting.filter);
                Object.assign(baseQuery, mongoDBFilter);
            }

            setQuery(baseQuery);
        }
    }, [editorId, frameId, sourceSetting, buildBaseQuery, processMongoDBFilter]);

    const updateQuery = useCallback((newQuery) => {
        // Build the base query
        const baseQuery = buildBaseQuery(frameId, editorId, sourceSetting);

        // Merge the new query with the base query
        const mergedQuery = {
            ...baseQuery,
            ...newQuery
        };

        // Process and merge any MongoDB-style filters from sourceSetting
        if (sourceSetting?.filter) {
            const mongoDBFilter = processMongoDBFilter(sourceSetting.filter);
            Object.assign(mergedQuery, mongoDBFilter);
        }

        setQuery(mergedQuery);
        setOffset(0);
        setItems([]);
        setHasMore(true);
        setCurrentIndex(0);
        setRefreshKey(prev => prev + 1);
    }, [frameId, editorId, sourceSetting, buildBaseQuery, processMongoDBFilter]);

    const contextValue = {
        query,
        items,
        hasMore,
        isLoading,
        fetchBatch,
        updateQuery,
        currentIndex,
        setCurrentIndex,
        totalRecords: instanceIds.length,
        navigateTo,
        navigateToFirst,
        navigateToLast,
        titleArray,
        briefArray,
        searchArray,
        sortArray,
        subtitleArray,
    };

    return (
        <ListContext.Provider value={contextValue}>
            {children}
        </ListContext.Provider>
    );
};

export const useListContext = () => useContext(ListContext);