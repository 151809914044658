import React from "react";
import {componentRegistry, Card} from "qwanyx";

// Recursive component renderer that uses the dedicated HTML component instead of direct HTML tag rendering
// This component takes a component definition and recursively renders it and its children
export const QwantaComponent = ({ component, index, parentId }) => {
  if (!component || !component.type) {
    return (
      <div key={`invalid-${parentId}-${index}`} style={{ color: "red" }}>
        ⚠️ Invalid component definition
      </div>
    );
  }

  // Generate a unique id for this component
  const qwantumId = component.defaultProps.qwantumId || `${parentId}-${index}`;

  try {
    // Check if this component has children
    const children = component.defaultProps.children;

    // Special case for Card components to avoid circular dependency issues
    if (component.type === "Card") {
      const CardComponent = Card;
      
      if (!children) {
        return (
          <CardComponent
            key={qwantumId}
            {...component.defaultProps}
            qwantumId={qwantumId}
          />
        );
      }

      if (!Array.isArray(children)) {
        return (
          <CardComponent
            key={qwantumId}
            {...component.defaultProps}
            qwantumId={qwantumId}
          >
            <QwantaComponent
              component={children}
              index={0}
              parentId={qwantumId}
            />
          </CardComponent>
        );
      }

      return (
        <CardComponent
          key={qwantumId}
          {...component.defaultProps}
          qwantumId={qwantumId}
        >
          {children.map((child, childIndex) => (
            <QwantaComponent
              key={`${qwantumId}-child-${childIndex}`}
              component={child}
              index={childIndex}
              parentId={qwantumId}
            />
          ))}
        </CardComponent>
      );
    }

    // Handle other components from the registry
    const Component = componentRegistry[component.type];

    // If not found in componentsMap, show error
    if (!Component) {
      console.warn(`❌ Unknown component type: ${component.type}`);
      return (
        <div key={`unknown-${parentId}-${index}`} style={{ color: "red" }}>
          ⚠️ Error: Unknown component type "{component.type}"
        </div>
      );
    }

    // If no children, render the component normally
    if (!children) {
      return (
        <Component
          key={qwantumId}
          {...component.defaultProps}
          qwantumId={qwantumId}
        />
      );
    }

    // If the children is a single component (not an array)
    if (!Array.isArray(children)) {
      return (
        <Component
          key={qwantumId}
          {...component.defaultProps}
          qwantumId={qwantumId}
        >
          <QwantaComponent
            component={children}
            index={0}
            parentId={qwantumId}
          />
        </Component>
      );
    }

    // If children is an array of components
    return (
      <Component
        key={qwantumId}
        {...component.defaultProps}
        qwantumId={qwantumId}
      >
        {children.map((child, childIndex) => (
          <QwantaComponent
            key={`${qwantumId}-child-${childIndex}`}
            component={child}
            index={childIndex}
            parentId={qwantumId}
          />
        ))}
      </Component>
    );
  } catch (error) {
    console.error(`❌ Error rendering component (${component.type}):`, error);
    return (
      <div key={`error-${parentId}-${index}`} style={{ color: "red" }}>
        ⚠️ Error loading component "{component.type}"
      </div>
    );
  }
};

export default QwantaComponent;