import React from "react";
import { IconButton } from "qwanyx";



export const Icons = ({ icons = [], gap = "15px", onIconClick,  iconzize = 24 }) => {
  const gridStyle = {
    display: "flex",
    flexWrap: "wrap",
    iconzize,
    gap, // Dynamic gap between icons, passed as a prop
  };

  return (
    <div style={gridStyle}>
      {icons.map((icon) => (
        <IconButton
          key={icon.id}
          direction="column"
          iconNames={icon.iconNames}
          size={iconzize}
          onClick={() => onIconClick(icon)} // Pass the entire icon for contextual use
        >
          {icon.label}
        </IconButton>
      ))}
    </div>
  );
};