import { qwanyxCollection } from 'qwanyx'; // Assuming this gives us access to the collection.

export async function persistQwantum(_id, qwantumId, value, backup = false) {
  try {
    // Get the database collection
    const collection = qwanyxCollection();

    // Build the base update object: set the `qwantumId` property with the provided value.
    const updates = {
      $set: {
        [qwantumId]: value, // Dynamic property name: qwantumId acts like a "key".
      },
    };

    // Handle backup logic if the flag is true
    if (backup) {
      const deltaBackup = {
        [new Date().toISOString()]: { [qwantumId]: value }, // Delta only for the updated property.
      };

      // Add the delta backup to the `backup` array, limiting to 100 entries.
      updates.$push = {
        backup: {
          $each: [deltaBackup],
          $slice: -100, // Keep the most recent 100 items.
        },
      };
    }

    // Perform the upsert operation
    await collection.updateOne(
      { _id }, // Match the document by its `_id`.
      {
        ...updates,
        $setOnInsert: { createdAt: new Date() }, // Add a `createdAt` field if this is a new document.
      },
      { upsert: true } // Create the document if it doesn’t exist.
    );

  } catch (error) {
    console.error("Error persisting Qwantum:", error);
    throw error; // Rethrow for higher-level handling.
  }
}