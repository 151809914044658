import React, { useState, useEffect, useRef } from "react";

export const DynamicGrid = ({ children, padding = 25, cellSize = 52, spacing = 25, maxWidth = 500 }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateWidth();

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const columns = Math.max(
    Math.floor((containerWidth + spacing) / (cellSize + spacing)),
    1
  );

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        padding: `${padding}px`,
        boxSizing: "border-box",
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, ${cellSize}px)`,
        gap: `${spacing}px`,
        justifyContent: "center",
        alignContent: "start",
        maxWidth: `${maxWidth}px`,
         gap: "10px",
      }}
    >
      {children}
    </div>
  );
};