import React from 'react';

/**
 * Background component that renders a customizable background container
 * with support for colors, images, and common styling props
 * 
 * @param {Object} props
 * @param {string} [props.backgroundColor] - Background color (hex, rgb, etc)
 * @param {string} [props.backgroundImage] - URL to an image for background
 * @param {string} [props.backgroundSize] - CSS background-size property (cover, contain, etc)
 * @param {string} [props.backgroundPosition] - CSS background-position property
 * @param {string} [props.backgroundRepeat] - CSS background-repeat property
 * @param {number|string} [props.width] - Width of the background container
 * @param {number|string} [props.height] - Height of the background container
 * @param {string} [props.className] - Additional CSS classes
 * @param {Object} [props.style] - Additional inline styles
 * @param {React.ReactNode} [props.children] - Child components
 * @param {string} [props.borderRadius] - Border radius of the background container
 * @param {string} [props.padding] - Padding of the background container
 * @param {string} [props.margin] - Margin of the background container
 * @param {string} [props.opacity] - Opacity of the background
 * @param {boolean} [props.fullscreen] - Whether the background should take up the full screen
 * @param {boolean} [props.centered] - Whether the background should be centered in its parent
 * @returns {React.ReactElement}
 */
export const Background = ({
  backgroundColor = 'transparent',
  backgroundImage,
  backgroundSize = 'cover',
  backgroundPosition = 'center',
  backgroundRepeat = 'no-repeat',
  width = '100%',
  height = '100%',
  top = "0px",
  left = "0px",
  right = "0px",
  className = '',
  style = {},
  children,
  borderRadius = '0',
  padding = '0',
  margin = '0',
  opacity = '1',
  fullscreen = false,
  zIndex = "-1",
  centered = true,
  ...restProps
}) => {
  // Construct the backgroundImage CSS property if provided
  const bgImage = backgroundImage ? `url(${backgroundImage})` : 'none';

  // Build the base style
  const baseStyle = {
    backgroundColor,
    backgroundImage: bgImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat,
    width,
    height,
    borderRadius,
    padding,
    margin,
    opacity,
    position: 'absolute',
    zIndex,
    ...style,
  };

  // Apply centering styles if centered prop is true
  if (centered) {
    baseStyle.position = 'absolute';
    baseStyle.top = '50%';
    baseStyle.left = '50%';
    baseStyle.transform = 'translate(-50%, -50%)';
    // Remove potentially conflicting positioning props
    delete baseStyle.right;
  } else {
    // Use original positioning if not centered
    baseStyle.top = top;
    baseStyle.left = left;
    baseStyle.right = right;
  }

  return (
    <div 
      className={className}
      style={baseStyle}
      {...restProps}
    >
     {children}
    </div>
  );
};

export default Background;