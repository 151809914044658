import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTextPersistence } from "qwanyx";

export const Field = forwardRef(
  ({ fontSize = "12px", placeholder = "Enter text here...", onSave, width = "100%", qwantumId, value }, ref) => {
    const { text, handleBlur: persistenceBlur } = useTextPersistence(qwantumId, onSave);
    const [prevValue, setPrevValue] = useState(value || text || "");

    const handleBlur = (e) => {
      const newValue = e.target.value;

      // Only persist if value has changed
      if (newValue !== prevValue) {
        persistenceBlur(e);
        if (onSave) {
          onSave(newValue);
        }
        setPrevValue(newValue); // Update the previous value after successful persistence
      }
    };

    useImperativeHandle(ref, () => ({
      getValue: () => text,
    }));

    return (
          <input
            width={width}
            type="text"
            className="input"
            style={{ fontSize }}
            defaultValue={value || text}
            placeholder={placeholder}
            onBlur={handleBlur}
          />
    );
  }
);

export default Field;