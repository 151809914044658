import { qwanyxCollection } from 'qwanyx'

export async function deleteInstance(recordId) {
    try {
        const collection = qwanyxCollection();
        await collection.deleteOne({ _id: recordId });
        console.log(`Record with ID ${recordId} deleted successfully.`);
    } catch (error) {
        console.error(`Error deleting record with ID ${recordId}:`, error);
        throw error;
    }
}
