import React from "react";
import PropTypes from "prop-types";
import Icon from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';

export const FontIcon = ({ iconName, size = "24px" }) => {
  return (
    <Icon path={mdiContentCopy} size={1} />
  );
};

FontIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.string, // Default = "24px"
};

export default FontIcon;