import { qwanyxCollection } from 'qwanyx';

export async function getMetaQwanta(_id, di) {
    try {
        const collection = qwanyxCollection();

        // Retrieve the documents that match _id and di
        const result = await collection.find(
            { _id, di },
            { projection: { metaqwanta: 1 } }
        );

        // Extract the metaqwanta and flatten the structure
        const metaqwanta = result[0].metaqwanta;
        
      
        //console.log("Final flattenedMetaQwanta:", metaqwanta);
      
        return metaqwanta; 
    } catch (error) {
        console.error("Error fetching metaqwanta from Qwantas:", error);
        throw error;
    }
}