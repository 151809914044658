import React from "react";
import classNames from "classnames"; // Helps manage conditional classNames

export const Label = ({
  text = "Default Label",
  className = "",
  style = {},
  align = "right",
  fontSize = "16px",
  isBold = true // Default to bold text
}) => {
  const labelClass = classNames("label", className, {
    "has-text-left": align === "left",
    "has-text-centered": align === "center",
    "has-text-right": align === "right",
    "has-text-weight-bold": isBold, // Apply bold styling only if isBold is true
  });

  return (
    <label className={labelClass} style={{ fontSize, ...style }}>
      {text}
    </label>
  );
};

export default Label;