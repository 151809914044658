import React from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const SimpleValueDisplay = ({ 
  prefix = 'Label:', 
  value = 'Value',
  size = '4', 
  color = 'dark', 
  justify = 'left'
}) => {
  return (
    <div className="simple-value-display">
      <h1 className={`title is-${size} has-text-${color} has-text-${justify}`}>
        {prefix} {value}
      </h1>
    </div>
  );
};

// Define prop types for the component
SimpleValueDisplay.propTypes = {
  prefix: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.string,
  justify: PropTypes.oneOf(['left', 'centered', 'right', 'justified'])
};

export default SimpleValueDisplay;

// Usage examples:
// <SimpleValueDisplay />                                       <!-- Shows "Label: Value" -->
// <SimpleValueDisplay prefix="Name:" value="John Doe" />       <!-- Shows "Name: John Doe" -->
// <SimpleValueDisplay prefix="Total:" value="$1,234.56" size="3" color="primary" justify="right" />