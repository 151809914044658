import React from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const SimpleTable = ({
  data = [],
  size = '4',
  color = 'dark',
  alignments = 'left'
}) => {
  // Validate data structure
  if (!Array.isArray(data)) {
    console.error('SimpleTable: data prop must be an array of arrays');
    return <div>Invalid data format</div>;
  }

  // Parse alignment string into an array
  const alignmentArray = alignments.split(',').map(a => a.trim());
  
  // Find the maximum number of columns in any row
  const maxColumns = data.reduce((max, row) => {
    return Array.isArray(row) && row.length > max ? row.length : max;
  }, 0);

  return (
    <div className="simple-table">
      <div className="columns is-mobile is-gapless">
        {/* Create a column for each position in the data */}
        {[...Array(maxColumns)].map((_, colIndex) => {
          // Determine alignment for this column (default to 'left' if not specified)
          const alignment = colIndex < alignmentArray.length 
            ? alignmentArray[colIndex] 
            : 'left';
            
          return (
            <div 
              key={`col-${colIndex}`}
              // Last column expands, others hug content
              className={colIndex === maxColumns - 1 ? "column" : "column is-narrow"}
            >
              {data.map((row, rowIndex) => {
                // Get cell value (if exists)
                const cellValue = Array.isArray(row) && colIndex < row.length 
                  ? row[colIndex] 
                  : '';
                  
                return (
                  <div 
                    key={`cell-${rowIndex}-${colIndex}`}
                    className={`has-text-${alignment} has-text-${color} is-size-${size} px-2 py-1`}
                  >
                    {cellValue}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Define prop types for the component
SimpleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array),
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.string,
  alignments: PropTypes.string // Comma-separated list of alignments (e.g., "left,right,center")
};

export default SimpleTable;

// Usage examples:
// const userData = [
//   ["Name", "John Doe", "Active"],
//   ["Email", "john@example.com"],
//   ["Phone", "+1 555-123-4567", "Primary"],
//   ["Address", "123 Main St, Anytown, USA"]
// ];
// 
// <SimpleTable data={userData} />
// <SimpleTable data={userData} alignments="right,left,center" size="3" color="primary" />