import React from 'react';
import { useListContext, Icons } from 'qwanyx';

export const IconTabControl = ({
    iconSize = "17",
    fontSize = ".9rem",
    height = "30px",
}) => {

    const icons = [
        { id: 1, iconNames: ["edit"], label: "edit", type: "" },
        { id: 2, iconNames: ["reorder"], label: "reorder", type: "" },
        { id: 3, iconNames: ["hand-pointer"], label: "preview", type: "" },
    ];

    return (
        <div
            //className='toolbox'
            style={{
                display: "flex",
                justifyContent: "center", // Centers icons horizontally
                alignItems: "center", // Centers icons vertically
                width: "100%",
                gap: "15px", // Space between the icons; adjust as needed
            }}
        >
            <Icons icons={icons} />
        </div>
    );
};

export default IconTabControl;
