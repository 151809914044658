import * as Realm from "realm-web";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import app from "../qwanyx-core/realm-config";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [logged, setLogged] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const location = useLocation();

  // Default guest credentials
  /*
    const DEFAULT_GUEST_CREDENTIALS = {
      email: "phil@pixanima.com",
      password: "Pasmoidavid4vip",
    };
    */

  const DEFAULT_GUEST_CREDENTIALS = {
    email: "guest@qwanyx.com",
    password: "qwanyx",
  };

  /**
   * Handle URL-based login
   */
  const handleLoginRoute = async (route) => {
    if (route.startsWith('/login/')) {
      const [, userCredentials] = route.split('/login/');
      const [email, password] = userCredentials.split(':');
      try {
        const credentials = Realm.Credentials.emailPassword(email, password);
        const newUser = await app.logIn(credentials);
        setCurrentUser(newUser);
        setLogged(true)
        console.log(`Login successful. User UUID: ${newUser.id}`);
        return newUser;
      } catch (error) {
        console.error('Login failed:', error.message);
        throw error;
      }
    }
  };

  /**
   * Handle logout
   */
  const handleLogoutRoute = async (route) => {
    if (route === '/logout') {
      try {
        await app.currentUser?.logOut();
        // Instead of setting currentUser to null, log in as guest
        await autoLoginGuest();
        setLogged(false)
        console.log('Logout successful. Logged in as guest.');
      } catch (error) {
        console.error('Logout failed:', error.message);
        throw error;
      }
    }
  };


  /**
   * Automatically log in guest if no user exists
   */
  const autoLoginGuest = async () => {
    try {
      console.log("Attempting guest login...");
      const credentials = Realm.Credentials.emailPassword(
        DEFAULT_GUEST_CREDENTIALS.email,
        DEFAULT_GUEST_CREDENTIALS.password
      );
      const guestUser = await app.logIn(credentials);
      setCurrentUser(guestUser);
      console.log("Guest login successful:", guestUser);
    } catch (error) {
      console.error("Guest login failed:", error.message);
    }
  };

  /**
   * Handle route changes and trigger login/logout handlers
   */
  useEffect(() => {
    const route = location.pathname;
    console.log(`Current route: ${route}`);

    if (route.startsWith('/login/')) {
      handleLoginRoute(route);
    } else if (route === '/logout') {
      handleLogoutRoute(route);
    }
  }, [location]);

  /**
   * Initialization logic - determine currentUser on app load
   */
  useEffect(() => {
    const initialize = async () => {
      if (app.currentUser) {
        console.log("Using existing session:", app.currentUser);
        setCurrentUser(app.currentUser);
      } else {
        await autoLoginGuest();
      }
      setInitializing(false);
    };

    initialize();
  }, []);

  const value = {
    currentUser,
    logged,
    // Keep these utility functions for explicit calls if needed
    signUp: async (email, password) => {
      await app.emailPasswordAuth.registerUser(email, password);
    },
    resendConfirmationEmail: async (email) => {
      await app.emailPasswordAuth.resendConfirmationEmail({ email });
    },
    sendResetPasswordEmail: async (email) => {
      await app.emailPasswordAuth.sendResetPasswordEmail(email);
    },
    resetPassword: async (newPassword, token, tokenId) => {
      await app.emailPasswordAuth.resetPassword({
        password: newPassword,
        token,
        tokenId,
      });
    },
  };

  if (initializing) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};