import React from 'react';

/**
 * A generic component for rendering HTML elements
 * @param {string} tag - The HTML tag name to render
 * @param {string} text - Text content to render as children
 * @param {object} props - Props to pass to the HTML element
 * @param {any} children - Child elements
 */
export const HTML = ({ tag = 'div', text, children, ...props }) => {
  // Validate that the tag is an allowed HTML element
  const validHtmlTags = [
    'div', 'span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 
    'ul', 'ol', 'li', 'a', 'img', 'button', 'section', 'article', 
    'nav', 'header', 'footer', 'main', 'aside', 'form', 'input', 
    'label', 'select', 'option', 'textarea', 'hr', 'br', 'table',
    'thead', 'tbody', 'tr', 'th', 'td', 'code', 'pre', 'blockquote',
    'strong', 'em', 'small', 'figure', 'figcaption', 'time','i'
  ];

  // Check if the tag is valid
  if (!validHtmlTags.includes(tag.toLowerCase())) {
    console.warn(`Invalid HTML tag: ${tag}`);
    return (
      <div style={{ color: 'red', padding: '10px', border: '1px solid red' }}>
        Invalid HTML tag: {tag}
      </div>
    );
  }

  // Create the element with the specified tag
  const TagName = tag.toLowerCase();
  
  // Use the text prop as children if provided, otherwise use the children prop
  const content = text !== undefined ? text : children;
  
  return <TagName {...props}>{content}</TagName>;
};

export default HTML;