import React, { useState, useEffect, useCallback, useRef } from "react";
import { LabelledField, JSONEditor } from "qwanyx";

export const JSONQwantaEditor = ({ qwantumId, height="600px", labelText="Stack Configuration" }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        width: "100%",
        height,
        backgroundColor: "#f5f5f5",
        //backgroundColor: "red",
        color: "#555",
        padding: "10px",
      }}
    >
      <JSONEditor qwantumId={qwantumId} labelText={labelText} height={height}/>
    </div>
  );
};

export default JSONQwantaEditor;