import React, { useEffect, useState, useRef } from "react";
import { QwantaProvider, getMetaQwanta, useQwanyx, QwantaComponent } from "qwanyx";


// Self-contained Card that can be used directly
export const Card = ({ _id, di, ed, pa, useGlobalContext = false }) => {
  // Track initial props
  const initialPropsRef = useRef({ _id, di, ed, pa });

  // Track if we've initialized from selected card yet
  const [initializedFromContext, setInitializedFromContext] = useState(false);

  // Track actual card data directly as state
  const [cardData, setCardData] = useState(initialPropsRef.current);
  
  // Add loading state to control visibility
  const [isLoading, setIsLoading] = useState(true);

  // Get selectedCard from context when useGlobalContext is true
  const { selectedCard } = useQwanyx();

  // Debug context readiness
  useEffect(() => {
    if (useGlobalContext) {
      console.log("Context state:", {
        selectedCard: selectedCard,
        initializedFromContext
      });
    }
  }, [useGlobalContext, selectedCard, initializedFromContext]);

  // Update cardData whenever props or selectedCard changes
  useEffect(() => {
    // Set loading state to true whenever data changes
    setIsLoading(true);
    
    // Case 1: Using global context and we have a selected card
    if (useGlobalContext && selectedCard && selectedCard._id) {
      console.log("🔄 Updating from context:", selectedCard);
      setCardData({
        _id: selectedCard._id,
        di: selectedCard.di,
        ed: selectedCard.ed,
        pa: selectedCard.pa
      });

      // Mark that we've initialized from context
      if (!initializedFromContext) {
        setInitializedFromContext(true);
      }
    }
    // Case 2: Using direct props OR context isn't ready yet
    else if (!useGlobalContext || !initializedFromContext) {
      console.log("🔄 Updating from props:", { _id, di, ed, pa });
      setCardData({ _id, di, ed, pa });
    }
    // Case 3: Using global context but selectedCard is null and we've already initialized
    // In this case, we don't update cardData to prevent flickering back to props
  }, [
    // Props dependencies
    _id, di, ed, pa,
    // Context dependencies
    useGlobalContext,
    selectedCard?._id,
    selectedCard?.di,
    selectedCard?.ed,
    selectedCard?.pa,
    initializedFromContext
  ]);

  const containerRef = useRef(null);
  const [metaQwanta, setMetaQwanta] = useState([]);
  const [providerReady, setProviderReady] = useState(false);

  // ✅ SAFETY NET: Warn if `di` or `ed` are null, but KEEP the provider!
  const missingData = cardData.di === null || cardData.ed === null;

  // 1️⃣ Scroll Reset when `_id` changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [cardData._id]);

  // 2️⃣ Update `QwantaProvider` first before fetching components
  useEffect(() => {
    console.log("⚡ Card data changed, resetting provider:", cardData);
    // Reset metaQwanta to force a complete refresh
    setMetaQwanta([]);
    setIsLoading(true);
    
    setProviderReady(false);
    let timeout = setTimeout(() => setProviderReady(true), 50);
    return () => clearTimeout(timeout);
  }, [cardData._id, cardData.di, cardData.pa]);

  // 3️⃣ Fetch Components AFTER `QwantaProvider` is ready
  useEffect(() => {
    if (!providerReady || missingData) return; // Prevent fetch if missing data

    const fetchMetaQwanta = async () => {
      try {
        // Use _id when pa is empty
        const editorConfigId = cardData.pa || cardData._id;

        if (!editorConfigId) {
          console.error("❌ Both pa and _id are empty, cannot fetch metaQwanta.");
          setIsLoading(false);
          return;
        }

        console.log(`🔄 Fetching metaQwanta for: ${editorConfigId}, di: ${cardData.di}`);
        const persistedMetaqwanta = await getMetaQwanta(editorConfigId, cardData.di);

        if (!Array.isArray(persistedMetaqwanta)) {
          console.error("❌ Unexpected response format from getMetaQwanta:", persistedMetaqwanta);
          setIsLoading(false);
          return;
        }

        console.log(`✅ Fetched ${persistedMetaqwanta.length} components`);
        setMetaQwanta(persistedMetaqwanta);
        
        // Allow a brief moment for React to process the updates
        // This ensures components are rendered before showing
        setTimeout(() => setIsLoading(false), 100);
      } catch (error) {
        console.error("❌ Error fetching metaQwanta:", error);
        setIsLoading(false);
      }
    };

    fetchMetaQwanta();
  }, [providerReady, cardData._id, cardData.di, cardData.pa, missingData]);

  // Only show "No card selected" when we've tried to initialize from context but got nothing
  if (useGlobalContext && initializedFromContext && (!selectedCard || !selectedCard._id)) {
    return <div>No card selected</div>;
  }

  return (
    <QwantaProvider
      key={`${cardData._id}-${cardData.di}`}
      _id={cardData._id}
      di={cardData.di}
      ed={cardData.ed}
      pa={cardData.pa}
      embedEditor={false}
      qwantaType="card"
    >
      <div
        ref={containerRef}
        style={{
          flexGrow: 1,
          width: "100%",
          //height: "100%",
          //overflow: "auto",
          position: "relative",
          opacity: isLoading ? 0 : 1, // Hide while loading
          transition: "opacity 0.5s ease", // Fade in when ready
        }}
      >
        {/* Optional loading indicator */}
        {isLoading && (
          <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 10
          }}>
            <div>Loading...</div>
          </div>
        )}
      
        <div style={{ display: "flex", flexDirection: "column", backgroundColor: 'transparent' }}>
          {missingData ? (
            <div style={{ padding: 20, textAlign: "center", color: "red" }}>
              <h3>Missing required data</h3>
              <p>Please ensure both `di` and `pa` are provided.</p>
            </div>
          ) : providerReady && metaQwanta.length > 0 ? (
            // Only render when everything is fully loaded
            metaQwanta
              .filter((component) => component)
              .map((component, index) => (
                <QwantaComponent
                  key={`root-${index}`}
                  component={component}
                  index={index}
                  parentId="root"
                />
              ))
          ) : null}
        </div>
      </div>
    </QwantaProvider>
  );
};

export default Card;