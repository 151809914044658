import React from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const Address = ({ 
  street = '123 Main Street',
  town = 'Anytown',
  country = 'Country',
  size = '5',
  color = 'dark',
  justify = 'left'
}) => {
  return (
    <div className="address">
      <div className={`content has-text-${color} has-text-${justify} is-size-${size}`}>
        <div className="address-line">{street}</div>
        <div className="address-line">{town}</div>
        <div className="address-line">{country}</div>
      </div>
    </div>
  );
};

// Add some custom CSS to control the spacing
const styles = `
  .address-line {
    margin-bottom: 0.25rem;
  }
  .address-line:last-child {
    margin-bottom: 0;
  }
`;

Address.propTypes = {
  street: PropTypes.string,
  town: PropTypes.string,
  country: PropTypes.string,
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7']),
  color: PropTypes.string,
  justify: PropTypes.oneOf(['left', 'centered', 'right', 'justified'])
};

export default Address;

// Usage examples:
// <Address />
// <Address street="42 Rue du Commerce" town="Paris" country="France" />
// <Address street="10 Downing Street" town="London" country="United Kingdom" justify="right" />
// <Address street="1600 Pennsylvania Avenue" town="Washington DC" country="USA" size="3" />