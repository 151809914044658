import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { getInstancesBatch, useAuth, persistQwantum } from "qwanyx";

export const TreeContext = createContext();

export const TreeProvider = ({ children }) => {
  const [query, setQuery] = useState(null);
  const [items, setItems] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [offset, setOffset] = useState(0);
  const { currentUser } = useAuth();

  // Update query when currentUser is available
  useEffect(() => {
    if (currentUser?.id) {
      setQuery({
        qwantaType: "meta",
        di: currentUser.id,
      });
    }
  }, [currentUser]);

  // Fetch data from the database and build the tree structure
  const fetchBatch = useCallback(async () => {
    // Add hasMore check to prevent unnecessary fetches
    if (isLoading || !query || !currentUser?.id || !hasMore) return;

    setIsLoading(true);
    setLoadingError(null);

    try {
      const limit = 1000;
      const structuredItems = {};
      
      // Step 1: Fetch root-level items (using the initial query for roots)
      console.log("Fetching root items with query:", query);
      const rootItems = await getInstancesBatch(query, offset, limit, {
        _id: 1, title: 1, brief: 1, ed: 1, di: 1, name: 1, firstname: 1, pa: 1, isFolder: 1
      }, []);
      
      console.log(`Fetched ${rootItems.length} root items`);

      // Process root items
      rootItems.forEach(item => {
        structuredItems[item._id] = {
          index: item._id,
          _id: item._id,
          children: [],
          data: item.name
            ? [item.firstname, item.name].filter(Boolean).join(" ")
            : item.title ?? "No Name",
          brief: item.brief ?? "",
          ed: item.ed,
          di: item.di,
          pa: item.pa,
          isFolder: item.isFolder,
          isRoot: true
        };
      });

      // Step 2: Process children for all items
      const processQueue = [...rootItems];
      const processedIds = new Set(); // Track processed IDs to prevent cycles
      
      while (processQueue.length > 0) {
        const batch = processQueue.splice(0, 50); // Process in smaller batches
        await Promise.all(batch.map(async (parent) => {
          if (processedIds.has(parent._id)) return;
          processedIds.add(parent._id);
          
          // KEY OPTIMIZATION: Only check for children if the node is a root
          // or has isFolder explicitly set to true
          const shouldFetchChildren = parent.isRoot || parent.isFolder === true;
          if (!shouldFetchChildren) {
            return; // Skip fetching children
          }
          
          try {
            const childQuery = { 
              qwantaType: { $in: ["instance", "card"] }, 
              di: currentUser.id, 
              ed: parent._id
            };
            
            const childItems = await getInstancesBatch(childQuery, 0, limit, {
              _id: 1, title: 1, brief: 1, ed: 1, di: 1, name: 1, firstname: 1, pa: 1, isFolder: 1
            }, []);
            
            // Update folder status based on whether children exist
            const hasChildren = childItems.length > 0;
            
            // If parent is not already marked as a folder but has children, update it
            if (hasChildren && parent._id !== 'root' && structuredItems[parent._id] && 
                structuredItems[parent._id].isFolder !== true) {
              structuredItems[parent._id].isFolder = true;
              
              // Persist the folder status to database
              persistQwantum(parent._id, "isFolder", true);
            }
            
            // Process the children and add them to the queue for further processing
            childItems.forEach(child => {
              if (!structuredItems[child._id]) {
                structuredItems[child._id] = {
                  index: child._id,
                  _id: child._id,
                  children: [],
                  data: child.name
                    ? [child.firstname, child.name].filter(Boolean).join(" ")
                    : child.title ?? "No Name",
                  brief: child.brief ?? "",
                  ed: child.ed,
                  di: child.di,
                  pa: child.pa,
                  isFolder: child.isFolder,
                  isRoot: false
                };
              }
              
              // Add child to parent's children array
              if (structuredItems[parent._id]) {
                structuredItems[parent._id].children.push(child._id);
              }
              
              // Add to queue for processing if not already processed
              // Only if the child is explicitly marked as a folder
              if (!processedIds.has(child._id)) {
                if (child.isFolder === true) {
                  processQueue.push(child);
                }
              }
            });
          } catch (childErr) {
            console.error(`Error fetching children for ${parent._id}:`, childErr);
          }
        }));
      }

      // Update state with the complete tree structure
      setItems(prevItems => ({
        ...prevItems,
        ...structuredItems
      }));

      // Update pagination state
      setOffset(prevOffset => prevOffset + rootItems.length);
      
      // Set hasMore to false if we received fewer items than the limit
      if (rootItems.length < limit) {
        setHasMore(false);
      }
      
      console.log(`Tree building complete with ${Object.keys(structuredItems).length} total items`);
    } catch (err) {
      console.error("Error fetching tree data:", err);
      setLoadingError(err.message || "Failed to load tree data");
      // Set hasMore to false on error to prevent continuous retries
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [query, offset, isLoading, currentUser?.id, hasMore]); // Added hasMore as dependency

  // Fetch new data when the query changes
  useEffect(() => {
    if (query) {
      setItems({});
      setOffset(0);
      setHasMore(true);
      fetchBatch();
    }
  }, [query]); // Removed fetchBatch from dependencies to prevent unnecessary re-runs

  // Create a separate effect for loading more data
  const loadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      fetchBatch();
    }
  }, [fetchBatch, isLoading, hasMore]);

  // NEW METHOD: Update a specific item's property in the tree
  const updateItemProperty = useCallback((itemId, propertyName, propertyValue) => {
    if (!itemId || !propertyName) {
      console.warn("Missing required parameters for updateItemProperty");
      return false;
    }

    setItems(prevItems => {
      // Check if the item exists in our tree structure
      if (!prevItems[itemId]) {
        console.warn(`Item with ID ${itemId} not found in tree structure`);
        return prevItems;
      }

      // Create a new items object with the updated property
      const updatedItems = {
        ...prevItems,
        [itemId]: {
          ...prevItems[itemId],
          [propertyName]: propertyValue
        }
      };

      console.log(`Updated item ${itemId} - ${propertyName}: ${propertyValue}`);
      return updatedItems;
    });

    return true;
  }, []);

  const contextValue = {
    items,
    isLoading,
    hasMore,
    loadingError,
    loadMore, // Provide loadMore function instead of fetchBatch
    refreshTree: () => {
      setItems({});
      setOffset(0);
      setHasMore(true);
      fetchBatch();
    },
    // Add the new method to the context
    updateItemProperty
  };

  return (
    <TreeContext.Provider value={contextValue}>
      {children}
    </TreeContext.Provider>
  );
};

export const useTreeContext = () => useContext(TreeContext);