import React from "react";

export const Typo = ({
    text = "",
    className = "", // For existing typography classes
    tag = "p", // HTML tag to use (p, h1, h2, span, etc.)
    width = "auto",
    style = {}, // All additional styles in one object
    onClick = null, // Optional click handler
}) => {
    // Create component style combining width and custom styles
    const componentStyle = {
        width,
        ...style, // All other styles come from this prop
    };

    // Dynamically render the appropriate HTML tag
    const CustomTag = tag;

    return (
        <CustomTag 
            className={className}
            style={componentStyle}
            onClick={onClick}
        >
            {text}
        </CustomTag>
    );
};

export default Typo;