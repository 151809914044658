import React from "react";
import { ImageIcon, useQwanyx } from "qwanyx";
import "bulma/css/bulma.min.css";

export const ImageIconGrid = ({
  icons = [],
  maxWidth = "500px",
  imageWidth = "64px",
  columnsPerRow = 3,
  imageClassName = "",
  textStyle = {},
}) => {
  const { updateSelectedCard } = useQwanyx();

  const handleClick = (iconTarget) => {
    if (!iconTarget) return;
    
    // Force a new object reference with added timestamp
    const cardData = {
      _id: iconTarget._id,
      di: iconTarget.di,
      ed: iconTarget.ed,
      pa: iconTarget.pa,
      // Add a timestamp to force re-render even if same card is clicked
      _timestamp: Date.now()
    };
    
    console.log("Updating card with:", cardData);
    updateSelectedCard(cardData);
  };

  return (
    <div className="container" style={{ maxWidth }}>
      <div className="columns is-multiline is-mobile">
        {icons.map((icon, index) => (
          <div 
            key={index} 
            className={`column is-${12 / columnsPerRow}`}
          >
            <div 
              onClick={() => handleClick(icon.target)}
              style={{ cursor: "pointer" }}
            >
              <ImageIcon
                imageUrl={icon.url}
                text={icon.text}
                textStyle={textStyle}
                imageWidth={imageWidth}
                containerStyle={{ width: "100%" }}
                imageClassName = {imageClassName}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageIconGrid;