import React, { useState } from "react";
import { IconButton, QwantumValues } from "qwanyx";

// Example of ICON_MAPPING (create or import from your project)
export const QwantumEditor = ({
    key,
    labelText,
    iconNames,
    defaultprops,
    item,
    onParentUpdate, // Callback to inform the parent about state changes
    handleRemoveQwantum
}) => {
   const ICON_MAPPING = {
        LabelledField: ["title"],
        LabelledCom: ["external-link"],
        LabelledArea: ["text"],
        Img: ["image"],
        JSONEditor: ["json"],
        Invoice: ["image"],
    };

    // Map icons for the current item type
    const iconArray = ICON_MAPPING[item?.type] || [];

    // State for toggling visibility
    const [isVisible, setIsVisible] = useState(false);

    // State to hold field values updated by QwantumValues
    const [fieldValues, setFieldValues] = useState(defaultprops || {});

    /**
     * Toggles the visibility of the QwantumValues component.
     * Sends updates to the parent only when hiding the component.
     */
    const toggleVisibility = () => {
        if (isVisible && onParentUpdate) {
            // If we're hiding the component and a callback exists, notify parent
            onParentUpdate(fieldValues);
        }
        // Toggle visibility state
        setIsVisible(!isVisible);
    };

    /**
     * Callback to handle updates from QwantumValues
     * @param {Object} updatedValues - The latest field values from QwantumValues
     */
    const handleValuesChange = (updatedValues) => {
        // Update the field values in QwantumEditor state
        setFieldValues(updatedValues);

        // Log for debugging
        console.log("Updated values in QwantumEditor:", updatedValues);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <IconButton
                    padding="8px"
                    width="100%"
                    size={16}
                    fontSize="14px"
                    key={key}
                    iconNames={iconArray} // Pass array of icons
                    layout="row"
                >
                    {labelText} {/* Use labelText as the children */}
                </IconButton>
                <button
                    onClick={toggleVisibility}
                    style={{ padding: "8px", fontSize: "14px" }}
                >
                    {isVisible ? "Hide" : "Show"}
                </button>
            </div>
            {isVisible && (
                <>
                    <div>
                        <QwantumValues
                            defaultprops={defaultprops}
                            onValuesChange={handleValuesChange} // Pass the callback here
                        />
                    </div>
                    <button
                        onClick={() => handleRemoveQwantum(fieldValues.qwantumId)}
                        style={{ padding: "8px", fontSize: "14px" }}

                    >Delete</button>
                </>
            )}
        </div>
    );
};

export default QwantumEditor;