import React, { useEffect } from "react";
import { useQwanta, FrameTopBar } from "qwanyx";


export const Frame = ({
  backgroundColor = "#559FEF",
  x = 0,
  y = 0,
  width = "150px",
  height = "150px",
  children,
  style,
  draggable = true,
  resizeable = true,
  qwantumId,
}) => {
  const [position, setPosition] = React.useState({ x, y });
  const [size, setSize] = React.useState({ width, height });

  const [dragging, setDragging] = React.useState(false);
  const [offset, setOffset] = React.useState({ x: 0, y: 0 });

  const [resizing, setResizing] = React.useState(false);
  const [resizeDirection, setResizeDirection] = React.useState(null);
  const [resizeOffset, setResizeOffset] = React.useState({ x: 0, y: 0 });

  const [qwantumState, setQwanta, SwitchIcon] = useQwanta(qwantumId);

  // Initialize from Qwanta
  useEffect(() => {
    if (qwantumState) {
      const { x, y, width, height } = qwantumState;
      setPosition({ x, y });
      setSize({ width: width || "150px", height: height || "150px" });
    } else {
      setQwanta(qwantumId, { x, y, width, height });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------
  // DRAGGING (Top Bar)
  // --------------------
  const startDrag = (e) => {
    //if (!draggable) return;
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const onDrag = (e) => {
    if (!dragging) return;
    const newX = Math.max(0, e.clientX - offset.x);
    const newY = Math.max(0, e.clientY - offset.y);
    setPosition({ x: newX, y: newY });
  };

  const stopDrag = () => {
    setDragging(false);
    setQwanta(qwantumId, {
      ...qwantumState,
      x: position.x,
      y: position.y,
    });
  };

  // --------------------
  // RESIZING
  // --------------------
  const startResize = (e, direction) => {
    if (!resizeable) return;
    setResizing(true);
    setResizeDirection(direction);
    setResizeOffset({ x: e.clientX, y: e.clientY });
  };

  const onResize = (e) => {
    if (!resizing) return;
    const deltaX = e.clientX - resizeOffset.x;
    const deltaY = e.clientY - resizeOffset.y;

    let newWidth = parseFloat(size.width);
    let newHeight = parseFloat(size.height);
    let newX = position.x;
    let newY = position.y;

    switch (resizeDirection) {
      case "right":
        newWidth += deltaX;
        newWidth = Math.max(50, newWidth);
        break;
      case "left":
        newWidth -= deltaX;
        newX += deltaX;
        newWidth = Math.max(50, newWidth);
        break;
      case "top":
        newHeight -= deltaY; // Reduce height
        newY += deltaY;      // Adjust position
        newHeight = Math.max(50, newHeight); // Minimum height constraint
        break;
      case "bottom":
        newHeight += deltaY;
        newHeight = Math.max(50, newHeight);
        break;
      case "top-left":
        newWidth -= deltaX;
        newX += deltaX;
        newHeight -= deltaY;
        newY += deltaY;
        newWidth = Math.max(50, newWidth);
        newHeight = Math.max(50, newHeight);
        break;
      case "top-right":
        newWidth += deltaX;
        newHeight -= deltaY;
        newY += deltaY;
        newWidth = Math.max(50, newWidth);
        newHeight = Math.max(50, newHeight);
        break;
      case "bottom-left":
        newWidth -= deltaX;
        newX += deltaX;
        newHeight += deltaY;
        newWidth = Math.max(50, newWidth);
        newHeight = Math.max(50, newHeight);
        break;
      case "bottom-right":
        newWidth += deltaX;
        newHeight += deltaY;
        newWidth = Math.max(50, newWidth);
        newHeight = Math.max(50, newHeight);
        break;
      default:
        break;
    }

    setSize({ width: `${newWidth}px`, height: `${newHeight}px` });
    setPosition({ x: newX, y: newY });
    setResizeOffset({ x: e.clientX, y: e.clientY });
  };

  const stopResize = () => {
    setResizing(false);
    setResizeDirection(null);
    setQwanta(qwantumId, {
      ...qwantumState,
      x: position.x,
      y: position.y,
      width: size.width,
      height: size.height,
    });
  };

  // --------------------
  // GLOBAL MOUSE EVENTS
  // --------------------
  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", onDrag);
      document.addEventListener("mouseup", stopDrag);
    }
    if (resizing) {
      document.addEventListener("mousemove", onResize);
      document.addEventListener("mouseup", stopResize);
    }
    return () => {
      document.removeEventListener("mousemove", onDrag);
      document.removeEventListener("mouseup", stopDrag);
      document.removeEventListener("mousemove", onResize);
      document.removeEventListener("mouseup", stopResize);
    };
  }, [dragging, resizing, onDrag, stopDrag, onResize, stopResize]);

  return (
    <div
      className="frame"
      style={{
        backgroundColor,
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: size.width,
        height: size.height,
        justifyContent: 'center',
        ...style,
        ...(!draggable && !resizeable ? { border: "none", borderRadius: "0px" } : {}),
      }}
    >
      {draggable && resizeable && (
        <>
          {/* Top bar for dragging */}
          {draggable && resizeable && <FrameTopBar startDrag={startDrag} />}

          {/* Left/Right/Top/Bottom edges for single-axis resizing */}
          <div
            className="top-edge"
            onMouseDown={(e) => startResize(e, "top")}
          />
          <div
            className="left-edge"
            onMouseDown={(e) => startResize(e, "left")}
          />
          <div
            className="right-edge"
            onMouseDown={(e) => startResize(e, "right")}
          />
          <div
            className="bottom-edge"
            onMouseDown={(e) => startResize(e, "bottom")}
          />

          {/* Corner handles */}
          <div
            className="corner-handle top-left"
            onMouseDown={(e) => startResize(e, "top-left")}
          />
          <div
            className="corner-handle top-right"
            onMouseDown={(e) => startResize(e, "top-right")}
          />
          <div
            className="corner-handle bottom-left"
            onMouseDown={(e) => startResize(e, "bottom-left")}
          />
          <div
            className="corner-handle bottom-right"
            onMouseDown={(e) => startResize(e, "bottom-right")}
          />
        </>
      )}
      {children}
    </div>
  );
};
