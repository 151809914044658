import React from 'react';
import PropTypes from 'prop-types';

/**
 * Dock component that mimics an iPhone dock
 * Positions at the bottom of the screen and stacks items horizontally
 * 
 * Available props:
 * - position: 'bottom' (default), 'top', 'left', 'right' - Position of the dock
 * - spacing: Custom spacing between dock items (e.g., '10px', '1em')
 * - maxItems: Maximum number of items to show in dock (defaults to unlimited)
 * - backgroundColor: Custom background color for dock (defaults to translucent dark)
 * - style: Additional custom styles to apply to the dock
 */

export const Dock = ({ 
  position, 
  spacing, 
  maxItems, 
  backgroundColor,
  borderRadius = '20px', // Default border radius
  style, 
  children 
}) => {
  // Parse children and limit to maxItems if specified
  const dockItems = React.Children.toArray(children);
  const limitedItems = maxItems ? dockItems.slice(0, maxItems) : dockItems;

  // Base styles
  const baseStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    borderRadius: {borderRadius},
    backgroundColor: backgroundColor || 'rgba(0, 0, 0, 0.7)',
    backdropFilter: 'blur(10px)',
    position: 'fixed',
    zIndex: 100,
    transition: 'all 0.3s ease',
    margin: '0 auto',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  };

  // Position styles
  const positionStyles = {
    bottom: {
      bottom: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      flexDirection: 'row',
    },
    top: {
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      flexDirection: 'row',
    },
    left: {
      left: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
      flexDirection: 'column',
    },
    right: {
      right: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
      flexDirection: 'column',
    }
  };

  // Define item spacing
  const itemStyle = spacing ? {
    margin: position === 'left' || position === 'right' 
      ? `${spacing} 0` 
      : `0 ${spacing}`
  } : {
    margin: position === 'left' || position === 'right' ? '10px 0' : '0 10px'
  };

  // Combine all styles
  const dockStyle = {
    ...baseStyles,
    ...positionStyles[position || 'bottom'],
    ...style,
  };

  return (
    <div className="dock" style={dockStyle}>
      {limitedItems.map((item, index) => (
        <div key={index} className="dock-item" style={itemStyle}>
          {item}
        </div>
      ))}
    </div>
  );
};

Dock.propTypes = {
  position: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  spacing: PropTypes.string,
  maxItems: PropTypes.number,
  backgroundColor: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

Dock.defaultProps = {
  position: 'bottom',
  spacing: '',
  maxItems: null,
  backgroundColor: '',
  style: {},
};

export default Dock;