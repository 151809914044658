import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const DynamicMenuBar = ({ menuConfig, actions }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [submenu, setSubmenu] = useState(null); // Track the active submenu
  const [submenuPosition, setSubmenuPosition] = useState(null); // Position of the submenu
  const menuRef = useRef(null);
  const submenuRef = useRef(null);

  const handleMenuMouseEnter = (menuIndex, event) => {
    const rect = event.target.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      left: rect.left,
    });
    setActiveMenu(menuIndex);
    setSubmenu(null); // Reset submenu when switching menus
  };

  const handleSubmenuMouseEnter = (submenuItems, event) => {
    const rect = event.target.getBoundingClientRect();
    setSubmenu(submenuItems);
    setSubmenuPosition({
      top: rect.top,
      left: rect.right, // Position to the right of the parent menu item
    });
  };

  const handleMenuItemMouseEnter = (item, event) => {
    if (item.submenu) {
      handleSubmenuMouseEnter(item.submenu, event); // Open the new submenu
    } else {
      setSubmenu(null); // Close any open submenu if the item doesn't have one
    }
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      (!submenuRef.current || !submenuRef.current.contains(event.target))
    ) {
      setActiveMenu(null);
      setSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const renderSubmenu = () => {
    if (!submenu || !submenuPosition) return null;

    return ReactDOM.createPortal(
      <div
        ref={submenuRef}
        className="submenuContainer"
        style={{
          position: 'fixed',
          top: submenuPosition.top,
          left: submenuPosition.left,
          zIndex: 10000,
        }}
      >
        {submenu.map((item, index) => (
          <div
            key={index}
            className="menuItem"
            onClick={() => {
              actions[item.action]?.(); // Execute submenu action
              setActiveMenu(null); // Close menus
              setSubmenu(null);
            }}
          >
            {item.content || item.label} {/* Render dynamic content */}
          </div>
        ))}
      </div>,
      document.body
    );
  };

  const renderMenu = () => {
    if (activeMenu === null || !menuPosition) return null;

    return ReactDOM.createPortal(
      <div
        ref={menuRef}
        className="menuContainer"
        style={{
          position: 'fixed', // Ensure global positioning
          top: menuPosition.top,
          left: menuPosition.left,
          zIndex: 9999,
        }}
      >
        {menuConfig.menus[activeMenu].items.map((item, index) => (
          <div
            key={index}
            className="menuItem"
            onMouseEnter={(event) => handleMenuItemMouseEnter(item, event)}
            onClick={() => {
              if (!item.submenu) {
                actions[item.action]?.(); // Execute main menu action
                setActiveMenu(null); // Close menu
              }
            }}
          >
            {item.content || item.label} {/* Render dynamic content */}
            {item.submenu && <span className="submenuArrow">▶</span>}
          </div>
        ))}
      </div>,
      document.body
    );
  };

  return (
    <div className="menuBar">
      {menuConfig.menus.map((menu, index) => (
        <div key={index} className="menuWrapper">
          <button
            className="menuButton"
            onMouseEnter={(event) => handleMenuMouseEnter(index, event)}
            style={{
              height: '100%', // Ensure button spans the full height
              display: 'flex', // Use flexbox for alignment
              alignItems: 'center', // Vertically center content
              justifyContent: 'center', // Horizontally center content
            }}
          >
            <div
              style={{
                height: '100%', // Ensure content spans full height
                display: 'flex', // Flexbox ensures proper alignment
                alignItems: 'center', // Vertically center the icon
                justifyContent: 'center', // Horizontally center the icon
              }}
            >
              {menu.content || menu.title} {/* Render content or title */}
            </div>
          </button>
        </div>
      ))}
      {renderMenu()}
      {renderSubmenu()}
    </div>
  );
  
};
