import React, { useState, useCallback } from 'react';

export const DroppableWrapper = ({ children, onItemDropped, className, style }) => {
  const [isOver, setIsOver] = useState(false);
  
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    if (!isOver) setIsOver(true);
  }, [isOver]);
  
  const handleDragLeave = useCallback(() => {
    setIsOver(false);
  }, []);
  
  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsOver(false);
    
    try {
      // Parse the data from react-complex-tree
      const dragData = JSON.parse(e.dataTransfer.getData('text/plain'));
      
      // Call the provided callback with the dropped data
      if (onItemDropped) {
        onItemDropped(dragData);
      }
    } catch (err) {
      console.error('Error parsing dropped data:', err);
    }
  }, [onItemDropped]);
  
  // Combine provided styles with highlight when item is dragged over
  const combinedStyle = {
    ...(style || {}),
    ...(isOver ? { 
      outline: '2px dashed #4a90e2',
      backgroundColor: 'rgba(74, 144, 226, 0.1)'
    } : {})
  };
  
  return (
    <div
      className={className}
      style={combinedStyle}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
};

export default DroppableWrapper;