import React, { useRef } from "react";
import { Label, Field, SwitchIcon } from "qwanyx";

export const LabelledCom = ({
    iconSize = 18,
    fontSize = "16px",
    value = "", // Controlled value for EditableText
    qwantumId = null,
    labelText = "Default Label",
    placeholder = "Enter text here...",
    onSave, // Callback to handle value updates
    labelWidth = "100%", // Dynamic width for the label
    width = "100%",
    closeOnComplete = false,
    editable = true,
    align = "left", // Alignment for the EditableText
    layout = "column", // Layout option: 'row' or 'column'
    labelAlign, // Optional: Override default label alignment
    showColon = true, // Optional colon display
    style = {}, // Additional styles for the container
}) => {
    // Determine alignment based on layout
    const effectiveLabelAlign = labelAlign || (layout === "column" ? "left" : "right");
    const icons = ['external-link', 'email', 'phone', 'website', 'import']

    const fieldRef = useRef(null); // Ref for the Field

    const ongGoToLink = () => {
        const value = fieldRef.current?.getValue(); // Get the current value from Field
        if (!value) {
            console.error("Field value is empty. Cannot navigate.");
            return;
        }

        let url = "";
        switch (qwantumId) {
            case "email":
                url = `mailto:${value}`;
                break;
            case "phone":
                url = `tel:${value}`;
                break;
            case "website":
                url = value.startsWith("http") ? value : `https://${value}`;
                break;
            default:
                url = value.startsWith("http") ? value : `https://${value}`;
        }
        window.open(url, "_blank");
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: layout, // Set layout dynamically
                justifyContent: "flex-start", // Ensure all items align left
                alignItems: layout === "row" ? "center" : "flex-start", // Adjust vertical alignment
                gap: layout === "column" ? "4px" : "0", // Spacing for column layout
                width: { width },
                ...style,
            }}
        >
            {/* Label and Colon */}
            <div
                style={{
                    width: width,
                    display: "flex", // Keep label and colon together
                    alignItems: "center", // Align vertically
                    whiteSpace: "nowrap", // Prevent line breaks
                    minWidth: labelWidth, // Adjust minimum width
                    justifyContent: effectiveLabelAlign === "left" ? "flex-start" : "flex-end",
                    padding: 0, // Remove unnecessary padding
                    margin: 0, // Remove unnecessary margin
                }}
            >
                <Label
                    text={labelText}
                    align={effectiveLabelAlign}
                    style={{
                        fontSize: fontSize,
                        padding: 0,
                        margin: 0,
                    }}
                />
                {showColon && (
                    <span
                        style={{
                            fontSize: fontSize,
                            color: "black",
                            marginLeft: "4px", // Minimal space between label and colon
                            verticalAlign: "middle", // Align colon with text
                        }}
                    >
                        :
                    </span>
                )}
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "right", // Ensures vertical alignment
                    justifyContent: "space-between", // Pushes icon to the right
                    gap: "4px", // Space between components
                    //backgroundColor: "red"
                }}
            >
                {/* Input Field - Should grow and take up available space */}
                <Field
                    fontSize={fontSize}
                    value={value}
                    qwantumId={qwantumId}
                    placeholder={placeholder}
                    onSave={onSave}
                    closeOnComplete={closeOnComplete}
                    editable={editable}
                    align={align}
                    ref={fieldRef}
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,  // Allow shrinking if needed
                        flexBasis: "0%", // Allow it to grow freely
                        width: "100%",   // Ensures it expands
                       
                    }}
                />
                    <SwitchIcon
                        iconNames={[icons[icons.indexOf(qwantumId)] || icons[0]]}
                        size={iconSize}
                        onClick={ongGoToLink}
                        id="list-icon"
                    />
            </div>
        </div>
    );
};

export default LabelledCom;
