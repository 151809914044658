import React, { useMemo } from 'react';
import {
    InvoiceLayout,
    InvoiceNum,
    CurrentDate,
    Address,
    VatNumberDisplay,
    SimpleValueDisplay,
    BankAccountDisplay,
    SimpleTable,
    InvoiceTable,
    LabelledArea
} from 'qwanyx';


export const Invoice = ({
    invoiceNum,
    productData = [
        ["Marque:", "----"],
        ["Modèle:", "----"],
        ["Cylindrée:", "----"],
        ["Chassis:", "----"],
        ["Plaque:", "----"],
        ["Kilométrage:", "----"]
    ],
    invoiceData = [
        ["Référence", "Désignation", "Quantité", "P.U. HTVA", "%REM", "Remise HTVA", "Montant HTVA", "TVA"],
        ["N131N30", "FILTRE A HUILE", 1.00, 15.20, 0, 0, 15.20, 21.00],
        ["N131N30", "FILTRE A EAU", 1.00, 14.30, 0, 0, 14.30, 21.00],
    ]

}) => {

    // Calculate summary values using useMemo for performance
    const calculatedSummary = useMemo(() => {
        // Skip header row (index 0)
        const dataRows = invoiceData.slice(1);

        // Total HTVA - Sum of all P.U. HTVA (index 3)
        const totalHTVA = dataRows.reduce((sum, row) => sum + (Number(row[3]) || 0), 0);

        // Calculate Remise (sum of %REM * P.U. HTVA for each line)
        const totalRemise = dataRows.reduce((sum, row) => {
            const puHTVA = Number(row[3]) || 0;
            const remisePercentage = Number(row[4]) || 0;
            return sum + (puHTVA * remisePercentage / 100);
        }, 0);

        // Net HTVA = Total HTVA - Remise
        const netHTVA = totalHTVA - totalRemise;

        // Calculate Total TVA (sum of NetHTVA * TVA% for each line)
        const totalTVA = dataRows.reduce((sum, row) => {
            const montantHTVA = Number(row[6]) || 0;
            const tvaRate = Number(row[7]) || 0;
            return sum + (montantHTVA * tvaRate / 100);
        }, 0);

        // Total TVAC = Net HTVA + Total TVA
        const totalTVAC = netHTVA + totalTVA;

        // Assume Acompte (down payment) is provided elsewhere or is zero for this example
        const acompte = 0;

        // Net à Payer = Total TVAC - Acompte
        const netAPayer = totalTVAC - acompte;

        return [
            parseFloat(totalHTVA.toFixed(2)),
            parseFloat(totalRemise.toFixed(2)),
            parseFloat(netHTVA.toFixed(2)),
            parseFloat(totalTVA.toFixed(2)),
            parseFloat(totalTVAC.toFixed(2)),
            acompte,
            parseFloat(netAPayer.toFixed(2))
        ];
    }, [invoiceData]);

    const sumData = [
        ["Total HTVA", "Remise", "Net HTVA", "Total TVA", "Total TVAC", "Accomte", "Net à Payer"],
        [...calculatedSummary],
    ];

    const size = "5";

    // Create the header left content
    const headerLeftContent = (
        <div className="p-3">
            <SimpleValueDisplay size={size} prefix="" value="200 rue de Hollogne - 4101 Jemeppe /Meuse" />
            <SimpleValueDisplay size={size} prefix="" value="www.bel-car.be - belcarscrl@skynet.be" />
            <BankAccountDisplay size={size} bankName="FORTIS:" iban="BE86-2400-1792-0550" bic="GEBABEBB" />
            <VatNumberDisplay prefix="N° d'entreprise:" vatNumber='BE0442649602' />
            <SimpleTable
                data={productData}
                alignments="right,left"
                size={size}
            />
        </div>
    );
    // Create the header right content with InvoiceNum
    const headerRightContent = (
        <div className="p-3">
            <InvoiceNum size="5" invoiceNum={invoiceNum} />
            <SimpleValueDisplay size="5" prefix="Cde. Client:" value="VENTE1COMPTOIR" />
            <CurrentDate size="5" prefix="Jemeppe / Meuse Le:" format="DD-MM-YYYY" />
            <Address />
            <VatNumberDisplay prefix="N° d'entreprise:" vatNumber='BE0442649602' />
        </div>
    );



    // Create basic content section
    const contentSectionContent = (

        <InvoiceTable
            data={invoiceData}
            size="6"
            alignments="left,left,right,right,right,right,right,right"

        />


    );

    // Create basic footer
    const footerContent = (
        <>
            <div class="block" style={{ border: '1px solid black' }}>
                <InvoiceTable
                    data={sumData}
                    size="6"
                    alignments="right,right,right,right,right,right,right"
                />
            </div>
            <div class="block">
                <LabelledArea fontSize="18px" rows={3} labelText="Commentaire" qwantumId="invoiceRem" />
            </div>
            <div class="block">
            <p>Conditions générales de vente:</p>
                Les factures sont payables au comptant, toute réclamation sur nos factures doit être adressées par écrit dans les 8 jours. Le défaut de payement d'une facture à l'échéance entraîne de plein droit et sans mise en demeure préalable d'un intérêt de 2% par mois à compté de date de la facture en outre il sera dû 20% du montant de la facture avec un minimum de 50€ à titre de clause pénale conventionelle forfétaire et irréductible et ce sans préjudice du droit de réclamer des dommages et intérêts complémentaires. Les tribunaux de Liège sont seuls compétents en cas de litige.
            </div>
        </>
    );

    return (
        <InvoiceLayout
            headerLeftContent={headerLeftContent}
            headerRightContent={headerRightContent}
            contentSectionContent={contentSectionContent}
            footerContent={footerContent}
            headerBackgroundColor={'white'}
        />
    );
};

export default Invoice;