import React from "react";
import { DynamicGrid, IconButton } from "qwanyx";

const ICON_SIZE = 24; // Size of IconButton in pixels
const SPACING = 25; // Spacing between grid cells


export const SetupIcons = ({
    onImportClick,
    onEditClick,
    onEditValuesClick,
    onEditJsonClick,
    onWebClick,
    onListSettingsClick
}) => {

    const icons = [
        { id: 1, iconNames: ["import"], label: "import", onClick: onImportClick },
        { id: 2, iconNames: ["edit"], label: "reorder", onClick: onEditClick },
        { id: 3, iconNames: ["edit"], label: "config", onClick: onEditValuesClick },
        { id: 4, iconNames: ["json"], label: "json", onClick: onEditJsonClick },
        { id: 5, iconNames: ["page"], label: "web", onClick: onWebClick },
        { id: 6, iconNames: ["list"], label: "list", onClick: onListSettingsClick }
    ];

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                width: "100%",
                height: "100%",
                backgroundColor: "#f5f5f5",
                color: "#555",
                padding: "10px",
            }}
        >
            <DynamicGrid iconSize={ICON_SIZE} spacing={SPACING} maxWidth={500}>
                {icons.map((icon) => (
                    <IconButton
                        key={icon.id}
                        direction="column"
                        iconNames={icon.iconNames}
                        size={ICON_SIZE}
                        onClick={icon.onClick} // Handle click dynamically
                    >
                        {icon.label}
                    </IconButton>
                ))}
            </DynamicGrid>
        </div>
    );
};