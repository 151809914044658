import React, { useState } from "react";
import {SimpleSearch, QueryEditor } from "qwanyx";

export const SearchEditor = () => {
    const [isQueryEditorVisible, setIsQueryEditorVisible] = useState(false);

    const toggleComponents = () => {
        setIsQueryEditorVisible(!isQueryEditorVisible);
    };
    return (
        <div className = "search-editor">
            {/* Conditionally render components */}
            {isQueryEditorVisible ? (
                <QueryEditor toggle={toggleComponents} />
            ) : (
                <SimpleSearch toggle={toggleComponents} />
            )}
        </div>
    );
};

export default SearchEditor;