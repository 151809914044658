import React from "react";

export const Drawer = ({height= '35px',width='50px', children }) => {
  const style = {
    position: "absolute",
    padding: "8px",
    top: "4px",
    left: "0px",
    width: width,
    height: height,
    backgroundColor: "white", // Example background color
    border: "1px solid #ccc", // Example border
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Example shadow
    overflow: "auto", // Ensure content inside the drawer can scroll if needed
    display: "flex", // Use Flexbox to arrange children
    flexDirection: "column", // Arrange children vertically
    gap: "5px", // Add spacing between children
  };

  return <div style={style}>{children}</div>;
};

export default Drawer;
