import React from 'react';

export const Typography = ({ className, fontSize, color, children, backgroundColor, height }) => {
    const style = {
        fontSize,
        height,
        //backgroundColor: 'green',
        color
    };
    return <div className={`${className}`} style={style}>{children}</div>;
};

export default Typography;