import React from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';
import { validateTaxNumber } from 'qwanyx';

export const VatNumberDisplay = ({ 
  vatNumber = '', 
  size = '5', 
  color = 'dark', 
  prefix = 'VAT', 
  justify = 'left',
  showValidation = false
}) => {
  // Validate the VAT number
  const validationResult = validateTaxNumber(vatNumber);
  
  // Determine the display color based on validation result (if showValidation is true)
  let displayColor = color;
  if (showValidation) {
    displayColor = validationResult.isValid ? 'dark' : 'danger';
  }
  
  // Display "NA" if no VAT number is provided
  const displayVatNumber = validationResult.result === "NA" ? "NA" : vatNumber;
  
  // Extract country code from the first two characters if available
  const countryCode = vatNumber && vatNumber.length >= 2 ? vatNumber.substring(0, 2).toUpperCase() : '';
  
  return (
    <div className="vat-number-display">
      <h1 className={`title is-${size} has-text-${displayColor} has-text-${justify}`}>
        {prefix} {displayVatNumber}
      </h1>
      
      {showValidation && displayVatNumber !== "NA" && (
        <p className={`has-text-${displayColor} has-text-${justify}`}>
          {validationResult.isValid 
            ? `Valid ${countryCode} tax number` 
            : validationResult.error || 'Invalid tax number format'}
        </p>
      )}
    </div>
  );
};

// Define prop types for the component
VatNumberDisplay.propTypes = {
  vatNumber: PropTypes.string,
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.string,
  prefix: PropTypes.string,
  justify: PropTypes.oneOf(['left', 'centered', 'right', 'justified']),
  showValidation: PropTypes.bool
};

export default VatNumberDisplay;

// Usage examples:
// <VatNumberDisplay />                                 <!-- Shows "VAT NA" -->
// <VatNumberDisplay vatNumber="US12-3456789" />       <!-- Shows validated US tax number -->
// <VatNumberDisplay prefix="Tax ID:" vatNumber="GB123456789" size="2" justify="centered" />
// <VatNumberDisplay vatNumber="FR12345678901" showValidation={false} /> <!-- Doesn't show validation message -->