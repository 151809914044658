import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { Card, QwanyxProvider, DroppableWrapper } from 'qwanyx';

export const Guest = ({ initialCard = null }) => {
    // Local state to track the selected card
    const [selectedCard, setSelectedCard] = useState({
        _id: initialCard._id,
        di: initialCard.di,
        ed: initialCard.ed,
        pa: initialCard.pa
    });

    const handleItemDropped = (droppedData) => {
        if (droppedData.items && droppedData.items.length > 0) {
            const item = droppedData.items[0];
            if (item) {
                setSelectedCard({
                    _id: item.index,
                    ed: item.ed,
                    di: item.di,
                    pa: item.pa,
                });
            }
        }
    };

    return (
        <portal>
            <QwanyxProvider>
                <div className="guest-container" style={{ height: '100vh', overflow: 'auto' }}>

                    {/* Main content area */}
                    <div className="guest-content" style={{ minHeight: '100vh', overflow: 'auto' }}>
                        <DroppableWrapper
                            style={{ height: '' }}
                            onItemDropped={handleItemDropped}
                        >
                            {selectedCard._id ? (
                                <Card
                                    useGlobalContext={true}
                                    key={initialCard._id}
                                    _id={initialCard._id}
                                    di={initialCard.di}
                                    ed={initialCard.ed}
                                    pa={initialCard.pa}
                                />
                            ) : (
                                <div className="has-text-centered" style={{ marginTop: '40px' }}>
                                    <p>No card selected. Drop a card here to view it.</p>
                                </div>
                            )}
                        </DroppableWrapper>
                    </div>
                </div>
            </QwanyxProvider>
        </portal>
    );
};

export default Guest;