import React, { useRef, useState, useEffect } from "react";
import {
    Frame,
    MetaQwanta,
    useQwanta,
    ListProvider,
} from "qwanyx";

export const MetaEditor = ({
    x = 0,
    y = 0,
    width = "100%",
    height = "100%",
}) => {
    const metaQwantaRef = useRef();
    const [_id] = useQwanta("_id"); // Get the `di` (parent's _id) from context
    return (
        <ListProvider>
            <Frame
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    borderRadius: 3.5,
                    padding: 0,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "rgba(0,0,0,0.3)",
                    backgroundColor: "#FFFDFA", // Default color
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    fontSize: "0.8em",
                    position: "absolute",
                    overflow: "hidden",
                }}
                qwantumId="frame"
            >         
                <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 20px - 0.4em)', overflow: 'hidden' }}>

                    <div
                        style={{
                            flexGrow: 1,
                            overflow: 'auto',
                            position: 'relative',
                        }}
                    >
                        <MetaQwanta ref={metaQwantaRef}/>
                    </div>
                </div>

            </Frame>
        </ListProvider>
    );
};
export default MetaEditor;
