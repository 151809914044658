import AWS from 'aws-sdk';

// Set the AWS Region.
const REGION = "eu-central-1"; //e.g., "us-west-2"

// Initialize the Amazon Cognito credentials provider
AWS.config.region = REGION; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'IDENTITY_POOL_ID', // an Amazon Cognito Identity Pool ID
});

// Create an S3 instance
const s3 = new AWS.S3();

// A function to upload a file
export const uploadFile = (file, bucketName, keyPrefix) => {
    const uploadParams = {
        Bucket: bucketName,
        Key: `${keyPrefix}/${file.name}`,
        Body: file,
        ACL: 'public-read' // if you want the file to be publicly accessible
    };

    return s3.upload(uploadParams).promise();
};

// A function to download a file
export const getFile = (bucketName, key) => {
    const getParams = {
        Bucket: bucketName,
        Key: key
    };

    return s3.getObject(getParams).promise();
};

// A function to delete a file
export const deleteFile = (bucketName, key) => {
    const deleteParams = {
        Bucket: bucketName,
        Key: key
    };

    return s3.deleteObject(deleteParams).promise();
};

// A function to list files
export const listFiles = (bucketName, keyPrefix) => {
    const listParams = {
        Bucket: bucketName,
        Prefix: keyPrefix
    };

    return s3.listObjectsV2(listParams).promise();
};

// ... any other functions you need for your S3 interactions ...
