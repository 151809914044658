import React from "react";
import { ImageSection, Typo, QwanyxProvider } from "qwanyx";

export const ImageIcon = ({
    // Image props
    //className = "window-shadow",

    imageClassName = "",
    imageUrl = "https://picsum.photos/600",
    ratio = "1/1",
    imageWidth = "64px",
    imageHeight = "auto",
    justification = "center",
    padding = "0px",
    alt = "Image",
    imageStyle = {},

    // Text props
    text = "hello world",
    textClassName = "image-icon-text",
    textTag = "p",
    textWidth = "auto",
    textStyle = {},
    textOnClick = null,

    // Container props
    containerStyle = {},
    direction = "column", // "column" or "row"
    spacing = "8px", // Space between image and text
}) => {

    const defaultTextStyle = { textAlign: 'center' };
    const combinedStyle = {
        ...defaultTextStyle,
        ...textStyle
      };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: direction,
                alignItems: "center",
                width: "fit-content",
                gap: spacing,
                
                justifyContent: justification,
                ...containerStyle,
            }}
        >
            {/* Use the existing ImageSection component */}

            <ImageSection
                imageClassName={`responsive-icon ${imageClassName}`}
                imageUrl={imageUrl}
                ratio={ratio}
                width={imageWidth}
                height={imageHeight}
                justification={justification}
                padding={padding}
                alt={alt}
                style={imageStyle}
            />

            {/* Use the existing Typo component if text is provided */}
            {text && (
                <Typo
                    text={text}
                    className={textClassName}
                    tag={textTag}
                    width={textWidth}
                    style={combinedStyle}
                    onClick={textOnClick}
                />
            )}
        </div>
    );
};

export default ImageIcon;