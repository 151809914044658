import { qwanyxCollection } from 'qwanyx'

/**
 * Generic function to fetch qwantas based on a query and projection
 * 
 * @param {Object} query - The query object to filter qwantas
 * @param {Object} projection - The projection object to specify which qwantums to include
 * @returns {Promise<Array>} - Array of qwantas matching the query with specified projection
 */
export async function fetchQwantas(query, projection) {
    try {
        const collection = qwanyxCollection();

        // Fetch data with the provided query and projection
        const results = await collection
            .find(query, { projection: projection });
            
        return results;
    } catch (error) {
        console.error("Error fetching qwantas:", error);
        throw error;
    }
}