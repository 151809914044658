import React from 'react';
import PropTypes from 'prop-types';

/**
 * Container component to dynamically apply styles based on props
 * Available class names and their usage:
 * - center-aligned: Center-aligned text with light background and border.
 * - justified-text-block: Justified text block with light background and border.
 * - scrollable-box: Scrollable box with a maximum height of 200px and overflow handling.
 * - overlay-text-box: Text box with a dark overlay and secondary text color.
 * - rounded-full: Fully circular border radius.
 * - rounded-pill: Fully rounded for pill-shaped elements.
 * 
 * The component also supports padding as a prop.
 */

export const Container = ({ className, padding, children }) => {
  const paddingStyle = padding ? { padding } : {}; // Inline padding override
  return (
    <div className={`container ${className}`} style={paddingStyle}>
      {children}
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string, // Accepts one or more of the predefined class names
  padding: PropTypes.string,  // Custom padding (e.g., '10px', '1em')
  children: PropTypes.node,   // Content to display inside the container
};

Container.defaultProps = {
  className: '',
  padding: '', // Use default padding from CSS if not specified
};

export default Container;
