import React, { useRef, useState, useEffect } from "react";
import { Label, Field, SwitchIcon, S3FileUploader, useQwanta } from "qwanyx";

export const LabelledImport = ({
    iconSize = 18,
    fontSize = "16px",
    value = "",
    labelText = "Default Label",
    placeholder = "Enter text here...",
    onSave,
    labelWidth = "100%",
    width = "100%",
    layout = "column",
    labelAlign,
    showColon = true,
    style = {},
}) => {
    const effectiveLabelAlign = labelAlign || (layout === "column" ? "left" : "right");
    const fieldRef = useRef(null);
    const uploaderRef = useRef(null);
    const [_id] = useQwanta("_id");
    const [fieldValue, setFieldValue] = useState(value);

    // Add effect to update fieldValue when prop value changes
    useEffect(() => {
        setFieldValue(value);
    }, [value]);

    const handleUploadComplete = (fileNames) => {
        // Convert existing fieldValue string to array, handling empty strings
        const existingFiles = fieldValue ? fieldValue.split(',').map(f => f.trim()) : [];
        
        // Convert new fileNames to array if it isn't already
        const newFiles = Array.isArray(fileNames) ? fileNames : [fileNames];
        
        // Merge and convert back to comma-separated string
        const mergedFilesString = [...existingFiles, ...newFiles].join(', ');
    
        console.log("Merged files:", mergedFilesString);
        setFieldValue(mergedFilesString);
        onSave?.(mergedFilesString);
    };

    const handleSwitchIconClick = () => {
        uploaderRef.current?.click();
    };

    const handleFieldSave = (newValue) => {
        setFieldValue(newValue);
        onSave?.(newValue);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: layout,
                justifyContent: "flex-start",
                alignItems: layout === "row" ? "center" : "flex-start",
                gap: layout === "column" ? "4px" : "0",
                width: width,
                ...style,
            }}
        >
            <div
                style={{
                    width: width,
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    minWidth: labelWidth,
                    justifyContent: effectiveLabelAlign === "left" ? "flex-start" : "flex-end",
                    padding: 0,
                    margin: 0,
                }}
            >
                <Label
                    text={labelText}
                    align={effectiveLabelAlign}
                    style={{
                        fontSize: fontSize,
                        padding: 0,
                        margin: 0,
                    }}
                />
                {showColon && (
                    <span
                        style={{
                            fontSize: fontSize,
                            color: "black",
                            marginLeft: "4px",
                            verticalAlign: "middle",
                        }}
                    >
                        :
                    </span>
                )}
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                    gap: "4px",
                }}
            >
                <Field
                    fontSize={fontSize}
                    value={fieldValue}
                    placeholder={placeholder}
                    onSave={handleFieldSave}
                    style={{ flexGrow: 1 }}
                    ref={fieldRef}
                />
                <SwitchIcon 
                    iconNames={['import']}
                    size={iconSize}
                    onClick={handleSwitchIconClick}
                    id="import-icon"
                />
                <S3FileUploader
                    ref={uploaderRef}
                    folderPath={_id}
                    onUpload={handleUploadComplete}
                />
            </div>
        </div>
    );
};

export default LabelledImport;