import React from 'react';
import { SwitchIcon, Typography, useListContext } from 'qwanyx';

export const Navigation = ({
    location = "bottom",
    iconSize = "17",
    fontSize = ".9rem",
    height = "45px",
    onToggleView = () => { },
    onSetupView = () => { },
    onAddRecord = () => { },
    onDeleteRecord = () => { },
    navigateRecord = () => { },

}) => {
    const { currentIndex, totalRecords, navigateTo, navigateFirst, navigateLast, items, updateQuery } = useListContext();

    const handleNavigate = (direction) => {
        navigateTo(direction);
        const newIndex = Math.max(0, Math.min(items.length - 1, currentIndex + direction));
        const item = items[newIndex];
        if (item) {
            navigateRecord(item._id);
        }
    };

    const handleAddRecord = async () => {
        const newId = await onAddRecord();
        if (newId !== null) {
            // Navigate to the new record first
            navigateRecord(newId);
            // Maybe delay the query update
            setTimeout(() => {
                updateQuery({});
            }, 100);
        }
    };

    const handleDelete = async () => {
        // Only proceed if there are records to delete
        if (totalRecords > 0 && currentIndex >= 0) {
            const currentItem = items[currentIndex];
            if (currentItem) {
                // Delete the current record
                await onDeleteRecord();

                // Navigate to the previous record if it exists
                if (currentIndex > 0) {
                    const previousItem = items[currentIndex - 1];
                    if (previousItem) {
                        navigateRecord(previousItem._id);
                    }
                } else if (totalRecords > 1) {
                    // If we're at the first record and there are more records,
                    // navigate to the next one
                    const nextItem = items[1];
                    if (nextItem) {
                        navigateRecord(nextItem._id);
                    }
                }

                // Update the query to refresh the list
                updateQuery({});
            }
        }
    };

    const handleToggleView = () => {
        // Call the provided toggle view function
        onToggleView();

        // Refresh the list
        updateQuery({});
    };

    return (
        <div
            className='toolbox'
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: height,
                padding: "15px",
                borderTop: "1px solid rgba(0, 0, 0, 0.2)",
            }}
        >

            {/*<SwitchIcon
                iconNames={["caretmax"]}
                size={iconSize}
                onClick={() => handleNavigate(0)}
                disabled={currentIndex >= totalRecords - 1}
                id="first-record-icon"
            />*/}

            <SwitchIcon
                iconNames={["caret"]}
                size={iconSize}
                onClick={() => handleNavigate(-1)}
                disabled={currentIndex <= 0}
                id="previous-record-icon"
            />
            <SwitchIcon
                iconNames={["settings"]}
                size={iconSize}
                onClick={onSetupView}
                id="setup-icon"
            />
            <SwitchIcon
                iconNames={["list"]}
                size={iconSize}
                onClick={handleToggleView}
                id="list-icon"
            />
            <SwitchIcon
                iconNames={["caret"]}
                size={iconSize}
                onClick={() => handleNavigate(1)}
                disabled={currentIndex >= totalRecords - 1}
                id="next-record-icon"
                rotation={180}
            />
            {/*
            <SwitchIcon
                iconNames={["caretmax"]}
                size={iconSize}
                onClick={() => handleNavigate}
                disabled={currentIndex >= totalRecords - 1}
                id="last-record-icon"
                rotation={180}
            />
            */}
            <SwitchIcon
                iconNames={["add"]}
                size={iconSize}
                onClick={handleAddRecord}
                id="add-record-icon"
            />

            <SwitchIcon
                iconNames={["trash"]}
                size={iconSize}
                onClick={handleDelete}
                id="delete-record-icon"
            />
        </div>
    );
};

export default Navigation;