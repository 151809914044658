import React, { useState, useRef, useEffect } from 'react';

export const Dropdown = ({ 
  buttonText = 'Dropdown button',
  items = ["Item1","Item2","Item3"],
  onItemClick = () => {}
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const handleItemClick = (item, index) => {
    onItemClick(item, index);
    setIsActive(false);
  };

  return (
    <div className={`dropdown ${isActive ? 'is-active' : ''}`} ref={dropdownRef}>
      <div className="dropdown-trigger">
        <button 
          className="button is-small"  
          aria-haspopup="true" 
          aria-controls="dropdown-menu"
          onClick={toggleDropdown}
        >
          <span>{buttonText}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {items.map((item, index) => {
            // Check if item is an object with properties
            if (typeof item === 'object' && item !== null) {
              if (item.isDivider) {
                return <hr className="dropdown-divider" key={`divider-${index}`} />;
              }
              
              return (
                <a 
                  key={index}
                  href={item.href || '#'}
                  className={`dropdown-item ${item.isActive ? 'is-active' : ''}`}
                  onClick={(e) => {
                    if (!item.href) e.preventDefault();
                    handleItemClick(item, index);
                  }}
                >
                  {item.content}
                </a>
              );
            } else {
              // Handle string items
              return (
                <a 
                  key={index}
                  href="#"
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleItemClick(item, index);
                  }}
                >
                  {item}
                </a>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;