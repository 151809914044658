import React, { useState, useEffect } from "react";
import { useQwanta } from "qwanyx"; // Import your custom hook
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Group, IconButton, ComponentsIcons } from "qwanyx";

// EDIT TAB COMPONENT
export const EditQwantaConfig = ({ editorConfig = [], onBack }) => {
  // Ensure `editorConfig` is always treated as an array
  const fields = Array.isArray(editorConfig) ? editorConfig : [];

  // Initialize draggable state
  const [editorItems, setEditorItems] = useState(fields);

  // Use `useQwanta` for syncing with database
  // `metaqwanta` should be replaced with the ID/key relevant to your context
  const [savedFieldValues, setQwanta] = useQwanta("metaqwanta");

  // Save `editorItems` to the database whenever it changes
  useEffect(() => {
    const newConfig = editorItems.map((item) => ({
      type: item.type,
      defaultProps: item.defaultProps,
    }));

    // Persist updated configuration
    setQwanta("metaqwanta", newConfig);

    // Debugging (optional): Log changes being saved
    console.log("Updated config saved to database:", newConfig);
  }, [editorItems]); // Re-run whenever `editorItems` changes

  // Icon mapping for different component types
  const ICON_MAPPING = {
    LabelledField: ["title"],
    LabelledCom: ["external-link"],
    LabelledArea: ["text"],
    Img: ["image"],
    JSONEditor: ["json"],
    Invoice: ["image"],
  };

  // Sorting and DnD setup
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setEditorItems((items) => {
        const oldIndex = items.findIndex(
          (item) => item.defaultProps.qwantumId === active.id
        );
        const newIndex = items.findIndex(
          (item) => item.defaultProps.qwantumId === over.id
        );
        return arrayMove(items, oldIndex, newIndex); // Reorder the array
      });
    }
  };

  // Sortable item - these are now draggable
  const SortableItem = ({ id, children }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {children}
      </div>
    );
  };

  // Render the component
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5",
        color: "#555",
        padding: "10px",
      }}
    >
      
      {/* Header/Toolbox */}
      <Group>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={editorItems.map((item) => item.defaultProps.qwantumId)}
            strategy={verticalListSortingStrategy}
          >
            {editorItems.map(({ type, defaultProps }) => {
              const { qwantumId, labelText } = defaultProps || {};
              if (!qwantumId || !type) return null; // Skip invalid configurations

              const iconArray = ICON_MAPPING[type] || []; // Retrieve appropriate icon array

              return (
                <SortableItem key={qwantumId} id={qwantumId}>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <IconButton
                      padding="8px"
                      width="100%"
                      size={16}
                      fontSize="14px"
                      key={qwantumId}
                      iconNames={iconArray} // Pass array of icons
                      layout="row"
                    >
                      {labelText} {/* Use labelText as the children */}
                    </IconButton>
                  </div>
                </SortableItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </Group>
    </div>
  );
};

export default EditQwantaConfig;