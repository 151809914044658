import React from "react";
import {
    EditableText,
    Frame,
    SwitchIcon,
    HotDisplay,
    recursiveDelete,
    Drawer,
    Textarea,
    useQwanta
} from "qwanyx";

export const QwantaStack = ({
    x = 0,
    y = 0,
    width = "100%",
    height = "100%",
}) => {
    const [_id] = useQwanta("_id"); // Get the `di` (parent's _id) from context
    const statusColors = [
        "#FFFDFA", // note
        "#FFE7CC", // backlog
        "#E8F5E9", // doing
        "#CCE2F4", // done
    ];

    const topLeftStyle = {
        position: "absolute",
        top: "15px",
        left: "15px",
        width: "20px",
        height: "20px",
    };

    const topRightStyle = {
        position: "absolute",
        top: "15px",
        right: "5px",
        width: "20px",
        height: "20px",
    };

    const bottomRightStyle = {
        position: "absolute",
        bottom: "10px",
        right: "10px",
        width: "20px",
        height: "20px",
    };

    return (
        <Frame
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
                borderRadius: 3.5,
                padding: 0,
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "rgba(0,0,0,0.3)",
                backgroundColor: "#FFFDFA", // Default color for the stack
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                fontSize: "0.8em",
                position: "absolute",
                overflow: "hidden",
            }}
            qwantumId="frame"
        >
            <HotDisplay containerStyle={topRightStyle}>
                {/* Commenting out the addChildEditor functionality */}
                {/*
        <SwitchIcon
          iconNames={["add"]}
          size={12}
          onClick={addChildEditor} // Disabled for now
        />
        */}
            </HotDisplay>

            <HotDisplay containerStyle={topLeftStyle}>
                <Drawer>
                    <SwitchIcon
                        iconNames={["title"]}
                        size={18}
                    // onClick={addChildEditor} // Disabled for now
                    />
                    <SwitchIcon
                        iconNames={["text"]}
                        size={18}
                    // onClick={addChildEditor} // Disabled for now
                    />
                    <SwitchIcon
                        iconNames={["labelled-input"]}
                        size={18}
                    // onClick={addChildEditor} // Disabled for now
                    />
                </Drawer>
            </HotDisplay>

            <HotDisplay containerStyle={bottomRightStyle}>
                <SwitchIcon
                    iconNames={["trash"]}
                    size={12}
                    onClick={async () => {
                        if (window.confirm("Are you sure you want to delete this note?")) {
                            try {
                                await recursiveDelete(_id);
                                console.log(`Qwanta with ID ${_id} deleted successfully.`);
                                window.location.reload(); // Refresh the page
                            } catch (error) {
                                console.error(`Failed to delete qwanta with ID ${_id}:`, error);
                            }
                        }
                    }}
                />
            </HotDisplay>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                }}
            >
                <EditableText
                    align="center"
                    style={{ flex: 1, zIndex: 30 }}
                    qwantumId="title"
                />
                <Textarea
                    align="center"
                    style={{ flex: 1, zIndex: 30 }}
                    qwantumId="brief"
                />
            </div>
        </Frame>
    );
};

export default QwantaStack;
