export const arrayToBreadcrumbs = (item, itemsArray, separator = " ", maxLength) => {
  if (itemsArray) {
    // Map through the itemsArray, convert values to strings, and filter out empty strings
    const breadcrumbParts = itemsArray
      .map((prop) => {
        const value = item[prop];
        // Convert value to string if it exists, otherwise return empty string
        return value != null ? String(value).trim() : "";
      })
      .filter((part) => part !== ""); // Remove empty strings

    // If no valid parts are left, return the fallback
    if (breadcrumbParts.length === 0) {
      return (
        item.nickname ||
        item.firstname ||
        item.name ||
        item.title ||
        "New record..."
      ).trim();
    }

    // If there's only one valid item, return it directly without a separator
    if (breadcrumbParts.length === 1) {
      const result = breadcrumbParts[0];
      return result.length > maxLength ? `${result.slice(0, maxLength)}...` : result;
    }

    // Join all parts except the last one with the separator
    const result = breadcrumbParts.slice(0, -1).join(separator) +
      (breadcrumbParts.length > 1 ? separator : "") +
      breadcrumbParts[breadcrumbParts.length - 1];

    // Handle maxLength
    return result.length > maxLength ? `${result.slice(0, maxLength)}...` : result;
  }

  // Fallback if itemsArray is not provided
  return (
    item.nickname ||
    item.firstname ||
    item.name ||
    item.title ||
    "New record..."
  ).trim();
}