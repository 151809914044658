import { qwanyxCollection } from "qwanyx";

export async function setDocumentsBatch(records, maxRecords = '1000000') {
  const MAX_RECORDS = maxRecords; // Define the maximum allowed records

  try {
    // Check if records exceed the maximum limit
    if (records.length > MAX_RECORDS) {
      throw new Error(`The number of records exceeds the maximum limit of ${MAX_RECORDS}.`);
    }

    const collection = qwanyxCollection();

    // Use insertMany to save the array of documents as a batch
    const result = await collection.insertMany(records);

    console.log("Batch insert successful:", result);
    return result;
  } catch (error) {
    console.error("Error saving batch:", error);
    throw error;
  }
}