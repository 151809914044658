import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import {
  UncontrolledTreeEnvironment,
  Tree,
} from "react-complex-tree";
import "react-complex-tree/lib/style-modern.css";
import { persistQwantum, useQwanyx, useTreeContext, newId, persistQwanta } from "qwanyx";

export const TreeList = ({ treeId = "treeId", title = "title", id = null, di = null, items = {} }) => {
  const { items: contextItems } = useTreeContext();
  const { updateSelectedCard } = useQwanyx();

  // Use items from props if provided, otherwise use from context
  const treeItems = Object.keys(items).length > 0 ? items : contextItems;
  const [convertedItems, setConvertedItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const dataProviderRef = useRef(null);

  // Convert treeItems to the format expected by react-complex-tree
  useEffect(() => {
    if (Object.keys(treeItems).length === 0) return;

    // Create a new items object with the expected structure
    const converted = {
      root: {
        index: "root",
        isFolder: true,
        children: [], // Will hold root item IDs
        data: "Root"
      }
    };

    // First, process all items to convert them to the expected format
    Object.entries(treeItems).forEach(([id, item]) => {
      converted[id] = {
        index: id,
        isFolder: Array.isArray(item.children) && item.children.length > 0,
        children: [...(item.children || [])],
        data: item.data,
        // Keep other metadata if needed
        brief: item.brief,
        ed: item.ed,
        di: item.di,
        pa: item.pa,
      };

      // Add root items as children of the main root
      if (item.isRoot) {
        converted.root.children.push(id);
      }
    });

    setConvertedItems(converted);
  }, [treeItems]);

  // Function to add a new item to the tree
  const addItem = useCallback(async (name = "New Item") => {
    // Check if we have selected items to use as a template
    if (selectedItems.length === 0) {
      alert("Please select an item first to add a new item");
      return;
    }

    // Get the selected item to use as a template/parent
    const selectedItemId = selectedItems[0];
    const selectedItem = convertedItems[selectedItemId];
    
    if (!selectedItem) {
      alert("Selected item not found");
      return;
    }

    // Generate a new ID for the item
    const newItemId = newId();
    
    // Create the new item based on the selected item
    const newItem = {
      index: newItemId,
      isFolder: false,
      children: [],
      data: name,
      ed: selectedItemId, // Set the parent reference to the selected item
      di: selectedItem.di, // Maintain the same di value as the parent
      pa: selectedItem.pa, // Maintain the same pa value as the parent
      qwantaType: "card"
    };

    // Update the converted items with the new item
    const updatedItems = {
      ...convertedItems,
      [newItemId]: newItem
    };

    // Add the new item to the children of the selected item
    if (!updatedItems[selectedItemId].children) {
      updatedItems[selectedItemId].children = [];
    }
    updatedItems[selectedItemId].children.push(newItemId);
    updatedItems[selectedItemId].isFolder = true;

    // Update the state
    setConvertedItems(updatedItems);

    // Persist the new item to the database with required properties
    const itemToStore = {
      title: name,
      qwantaType: "card",
      ed: selectedItemId,
      di: selectedItem.di,
      pa: selectedItem.pa,
      isFolder: false
    };
    
    // Persist the new item with all properties at once
    await persistQwanta(newItemId, itemToStore);
    
    // Update the parent's isFolder status ONLY (not the children array)
    if (selectedItemId !== 'root') {
      await persistQwantum(selectedItemId, "isFolder", true);
    }
    
    // Notify listeners of the changes if dataProvider exists
    if (dataProviderRef.current && dataProviderRef.current.changeListeners) {
      dataProviderRef.current.data = updatedItems;
      dataProviderRef.current.changeListeners.forEach(listener => 
        listener([selectedItemId, newItemId])
      );
    }
    
    return newItemId;
  }, [selectedItems, convertedItems]);

  // Custom Data Provider
  const dataProvider = useMemo(() => {
    class CustomDataProvider {
      constructor(data) {
        this.data = { ...data };
        this.changeListeners = [];
      }

      async getTreeItem(itemId) {
        return this.data[itemId];
      }

      async onChangeItemChildren(itemId, newChildren) {
        // Update children & ensure it's a folder
        this.data[itemId].children = newChildren;
        this.data[itemId].isFolder = newChildren.length > 0;
      
        // Persist the isFolder status to the database (if it's not the root)
        if (itemId !== 'root') {
          persistQwantum(itemId, "isFolder", newChildren.length > 0);
        }
      
        // For each child in newChildren, update its parent reference
        newChildren.forEach(childId => {
          if (this.data[childId] && itemId !== 'root') {
            // Set parent reference
            this.data[childId].ed = itemId;
            
            // Persist the parent change to database
            persistQwantum(childId, "ed", itemId);
          }
        });
      
        // Notify tree of changes
        this.changeListeners.forEach(listener => listener([itemId, ...newChildren]));
      }

      onDidChangeTreeData(listener) {
        this.changeListeners.push(listener);
        return {
          dispose: () => {
            this.changeListeners = this.changeListeners.filter(l => l !== listener);
          },
        };
      }

      async onRenameItem(item, newName) {
        // Update the item in the data array
        this.data[item.index].data = newName;

        // Persist the change with the new name
        persistQwantum(item.index, "title", newName);

        // Alert with the updated item
        //alert(JSON.stringify({ ...item, data: newName }, null, 2));
      }

      // Add method to inject a new item
      async addNewItem(name) {
        try {
          return await addItem(name);
        } catch (error) {
          console.error("Error adding new item:", error);
          throw error;
        }
      }
    }

    return new CustomDataProvider(convertedItems);
  }, [convertedItems, addItem]);

  // Setup global drag capabilities
  useEffect(() => {
    if (!dataProviderRef.current) return;

    const handleGlobalDragStart = (e) => {
      const itemElement = e.target.closest('[data-rct-item-id]');
      if (!itemElement) return;

      const draggedItemId = itemElement.getAttribute('data-rct-item-id');

      // Ignore dragging the root
      if (draggedItemId === 'root') return;

      // Use selected items if the dragged item is among them, otherwise just use the dragged item
      let itemsToInclude = selectedItems.includes(draggedItemId)
        ? selectedItems
        : [draggedItemId];

      // Create array with full data
      const itemsWithData = itemsToInclude.map(id => {
        const item = dataProviderRef.current.data[id];
        if (!item) return { id };

        return {
          id,
          data: item.data,
          index: item.index,
          ed: item.ed,
          di: item.di,
          pa: item.pa,
          ...item
        };
      });

      const dragData = {
        items: itemsWithData,
        source: treeId
      };

      // Only set drag data - do not stopPropagation or preventDefault
      // This allows the tree's internal drag mechanism to still work
      e.dataTransfer.setData('text/plain', JSON.stringify(dragData));
    };

    document.addEventListener('dragstart', handleGlobalDragStart);
    return () => {
      document.removeEventListener('dragstart', handleGlobalDragStart);
    };
  }, [treeId, selectedItems]);

  // Handle item selection
  const handleItemSelect = useCallback((itemIds) => {
    setSelectedItems(itemIds);
  }, [updateSelectedCard]);

  // Don't render until we have data
  if (Object.keys(convertedItems).length === 0) {
    return <div style={{ padding: '10px' }}>Loading tree data...</div>;
  }

  // Store reference to dataProvider for global drag
  dataProviderRef.current = dataProvider;

  return (
    <div style={{ width: '100%', height: 'calc(100%)' }}>
      <button 
        onClick={() => {
          addItem("New Item").catch(err => console.error("Error adding item:", err));
        }}
        style={{ 
          margin: '8px 0', 
          padding: '6px 12px', 
          backgroundColor: '#4CAF50', 
          color: 'white', 
          border: 'none', 
          borderRadius: '4px', 
          cursor: 'pointer' 
        }}
      >
        Add Item
      </button>
      <UncontrolledTreeEnvironment
        dataProvider={dataProvider}
        getItemTitle={(item) => item.data}
        viewState={{}}
        canDragAndDrop={true}
        canReorderItems={true}
        canDropOnFolder={true}
        canDropOnNonFolder={true}
        onSelectItems={handleItemSelect}
      >
        <Tree treeId={treeId} rootItem="root" treeLabel={title} />
      </UncontrolledTreeEnvironment>
    </div>
  );
};