import React, { useState, useEffect } from "react";
import { SimpleLabelledField } from "qwanyx";

export const QwantumValues = ({ defaultprops, onValuesChange }) => {
  // Ensure `defaultprops` is an object. If not, default to an empty object.
  const fields = typeof defaultprops === "object" && defaultprops !== null ? defaultprops : {};

  // Internal state to keep track of field values
  const [fieldValues, setFieldValues] = useState(() => ({ ...fields }));

  /**
   * Handles per-field edits, updating the internal state
   */
  const handleFieldChange = (key, value) => {
    setFieldValues((prev) => {
      const updatedValues = {
        ...prev,
        [key]: value, // Update the value for the specific key
      };

      // Notify parent of the updated values
      if (onValuesChange && typeof onValuesChange === "function") {
        onValuesChange(updatedValues);
      }

      return updatedValues;
    });
  };

  // Notify parent of initial default values on component mount
  useEffect(() => {
    if (onValuesChange && typeof onValuesChange === "function") {
      onValuesChange(fieldValues);
    }
  }, [fieldValues, onValuesChange]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5",
        color: "#555",
        padding: "10px",
      }}
    >
      {/* Render fields dynamically */}
      {Object.entries(fields).map(([key, value]) => (
        <SimpleLabelledField
          labelWidth="180px"
          key={key} // Ensures unique React keys
          labelText={key} // Label for the input field
          fieldValue={fieldValues[key]} // Controlled input bound to state
          onFieldChange={(e) => handleFieldChange(key, e.target.value)} // Update state on typing
        />
      ))}
    </div>
  );
};

