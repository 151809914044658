import React from 'react';

/**
 * ToolBox Component
 * A container with a predefined style that allows for children components to be added dynamically.
 */
export const ToolBox = ({
  height = "30px",
  children,
  gap = "10px",
  alignItems = "center",
  borderTop,
  borderBottom,
}) => {
  return (
    <div
      className='toolbox'
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems,
        width: "100%",
        height: height,
        padding: "15px",
        gap,
        borderTop,
        borderBottom,
      }}
    >
      {/** Render children elements dynamically */}
      {children}
    </div>
  );
};

export default ToolBox;