import React from "react";

export const ImageSection = ({
    imageClassName = "",
    imageUrl = "",
    ratio = "1/1", // Format: "width/height" as string (e.g. "1/1", "16/9", "4/3")
    width = "128px",
    height = "auto", // Auto by default, will use ratio if specified
    justification = "center", // "left", "center", "right"
    padding = "0px",
    alt = "Image",
    borderRadius, // Border radius for the image container
    style = {}, // Additional styles for the container
}) => {
    // Parse ratio if provided
    let ratioValues = { width: 1, height: 1 };
    if (ratio && ratio.includes("/")) {
        const [ratioWidth, ratioHeight] = ratio.split("/").map(num => parseFloat(num));
        ratioValues = { width: ratioWidth, height: ratioHeight };
    }

    // Calculate image dimensions
    let calculatedHeight = height;
    if (height === "auto" && width !== "auto") {
        // If width is specified and height is auto, use ratio to determine height
        const numericWidth = parseFloat(width);
        const unit = width.replace(/[0-9.]/g, ""); // Extract unit (px, em, rem, etc.)
        calculatedHeight = (numericWidth * ratioValues.height / ratioValues.width) + unit;
    }

    // Determine justification style
    const getJustificationStyle = () => {
        switch (justification) {
            case "left":
                return { marginRight: "auto", marginLeft: "0" };
            case "right":
                return { marginLeft: "auto", marginRight: "0" };
            case "center":
            default:
                return { marginLeft: "auto", marginRight: "auto" };
        }
    };

    return (
        <div
            style={{
                padding,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                ...style,
            }}
        >
            <div
                className={imageClassName}
                style={{
                    display: "block",
                    width: width,
                    height: calculatedHeight,
                    overflow: "hidden",
                    borderRadius,
                    ...getJustificationStyle(),
                }}
            >
                <img

                    src={imageUrl}
                    alt={alt}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Ensures the image covers the container while maintaining aspect ratio
                        display: "block", // Removes any bottom margin/spacing
                    }}
                />
            </div>
        </div>
    );
};

export default ImageSection;