

// Helper to generate unique qwantumId
const generateUniqueId = (metaqwanta, baseId) => {
    let id = baseId;
    let counter = 0;

    // Generate unique ID by appending a counter suffix (`baseId`, `baseId-1`, ...)
    while (metaqwanta.some((q) => q.defaultProps.qwantumId === id)) {
        counter++;
        id = `${baseId}-${counter}`;
    }

    return id;
};

// Add Qwantum object
export const addQwantum = (metaqwanta, type, additionalProps = {}) => {
    const factory = qwantumFactory[type];
    if (!factory) {
        throw new Error(`Unknown qwantum type: ${type}`);
    }

    const baseId = additionalProps.qwantumId || 'qwantum';
    const uniqueId = generateUniqueId(metaqwanta, baseId);

    const newQwantum = factory({ ...additionalProps, qwantumId: uniqueId });

    // Return updated metaqwanta with the new qwantum added
    return [...metaqwanta, newQwantum];
};

// Update Qwantum object
export const updateQwantum = (metaqwanta, qwantumId, updatedProps = {}) => {
    const qwantumIndex = metaqwanta.findIndex(
        (q) => q.defaultProps.qwantumId === qwantumId
    );

    if (qwantumIndex === -1) {
        throw new Error(`qwantumId "${qwantumId}" not found`);
    }

    const updatedQwantum = {
        ...metaqwanta[qwantumIndex],
        defaultProps: {
            ...metaqwanta[qwantumIndex].defaultProps,
            ...updatedProps,
        },
    };

    // Update the metaqwanta with the modified qwantum
    return metaqwanta.map((q, index) => (index === qwantumIndex ? updatedQwantum : q));
};

// Remove Qwantum object
export const removeQwantum = (metaqwanta, qwantumId) => {
    const updatedMetaqwanta = metaqwanta.filter(
        (q) => q.defaultProps.qwantumId !== qwantumId
    );

    if (updatedMetaqwanta.length === metaqwanta.length) {
        throw new Error(`qwantumId "${qwantumId}" not found`);
    }

    // Return the filtered metaqwanta with qwantum removed
    return updatedMetaqwanta;
};

// Factory for creating predefined types of qwantums
const qwantumFactory = {
    LabelledField: (overrideProps = {}) => ({
        type: 'LabelledField',
        defaultProps: {
            labelText: 'Field',
            defaultValue: '',
            qwantumId: 'field', // Example default qwantumId
            layout: 'column',
            placeholder: 'Enter text...',
            ...overrideProps, // Override default properties when provided
        },
    }),
    LabelledArea: (overrideProps = {}) => ({
        type: 'LabelledArea',
        defaultProps: {
            labelText: 'Field',
            defaultValue: '',
            qwantumId: 'field', // Example default qwantumId
            layout: 'column',
            placeholder: 'Enter text...',
            ...overrideProps, // Override default properties when provided
        },
    }),
    LabelledCom: (overrideProps = {}) => ({
        type: 'LabelledCom',
        defaultProps: {
            labelText: 'Field',
            defaultValue: '',
            qwantumId: 'com', // Example default qwantumId
            layout: 'column',
            placeholder: 'Enter text...',
            ...overrideProps, // Override default properties when provided
        },
    }),
    LabelledSwitch: (overrideProps = {}) => ({
        type: 'LabelledSwitch',
        defaultProps: {
            labelText: 'Switch',
            defaultValue: true,
            qwantumId: 'switch', // Example default qwantumId
            layout: 'row',
            ...overrideProps, // Override default properties when provided
        },
    }),
    Img: (overrideProps = {}) => ({
        type: 'Img',
        defaultProps: {
            labelText: 'Image',
            qwantumId: 'image',
            initialSrc: 'https://picsum.photos/400/300',
            placeholder: 'Src...',
            layout: 'row',
            ...overrideProps, // Override default properties when provided
        },
    }),
    JSONEditor: (overrideProps = {}) => ({
        type: 'JSONEditor',
        defaultProps: {
            labelText: 'Json',
            qwantumId: 'json',
            defaultValue: '',
            //placeholder: 'Src...',

            ...overrideProps, // Override default properties when provided
        },
    }),
    Invoice: (overrideProps = {}) => ({
        type: 'Invoice',
        defaultProps: {
            labelText: 'Invoice',
            qwantumId: 'invoice',
            defaultValue: '',
            //placeholder: 'Src...',

            ...overrideProps, // Override default properties when provided
        },
    }),
    // Add other object types as needed
};