import React, { useEffect, useState } from 'react';

export const Passepartout = ({
  src,
  alt,
  objectFit = 'contained',
  width = '100%',
  height = '100%',
  className = '',
  style = {},
  translateX = '0%',
  translateY = '0%',
  crop = 'center',
  backgroundColor = 'black',
  scale = 1,
  rotate = 0
}) => {

  const cropPositions = {
    center: {
      objectPosition: 'center'
    },
    top: {
      objectPosition: 'top'
    },
    bottom: {
      objectPosition: 'bottom'
    },
    left: {
      objectPosition: 'left'
    },
    right: {
      objectPosition: 'right'
    }
  };

  const imageStyle = {
    width,
    height,
    objectFit,
    objectPosition: cropPositions[crop].objectPosition,
    transform: `
      translate(${translateX}, ${translateY}) 
      scale(${scale}) 
      rotate(${rotate}deg)
    `,
    transformOrigin: cropPositions[crop].objectPosition,
    overflow: 'hidden',
    ...style
  };

  return (
    <div style={{  
        width,  
        height,  
        aspectRatio: '1 / 1',  // Ensure the container strictly maintains 1:1
        overflow: 'hidden',  
        backgroundColor  
    }}>  
        <img  
            src={src}  
            alt={alt}  
            style={{
                width: '100%',  
                height: '100%',  
                objectFit: 'contain',  // Force 'contain' behavior
                objectPosition: cropPositions[crop].objectPosition,  
                transform: `  
                    translate(${translateX}, ${translateY})   
                    scale(${scale})   
                    rotate(${rotate}deg)
                `,
                transformOrigin: cropPositions[crop].objectPosition  
            }}  
            className={className}  
        />  
    </div>  
);
};

export default Passepartout;