import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { uploadFiles } from 'qwanyx';

export const S3FileUploader = forwardRef(({ onUpload, folderPath = "root", accept, multiple = true }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        click: () => {
            fileInputRef.current?.click();
        }
    }));

    const handleFileUpload = async (event) => {
        const files = event.target.files;
        if (!files.length) return;

        setIsLoading(true);

        const fileNames = [];

        try {
            const filesToUpload = Array.from(files).map(file => {
                const fullFilePath = `${folderPath}/${file.name}`;
                return {
                    file,
                    fullFilePath
                };
            });

            for (const { file, fullFilePath } of filesToUpload) {
                await uploadFiles([{ file, key: fullFilePath }]);
                fileNames.push(file.name); // Now we're just storing the file name
            }

            onUpload(fileNames.join(',')); // Pass only the file names to onUpload

        } catch (error) {
            console.error('Error during file upload:', error);
        }

        setIsLoading(false);
    };

    return (
        <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileUpload}
            accept={accept}
            disabled={isLoading}
            style={{ display: 'none' }}
            {...(multiple ? { multiple: true } : {})}
        />
    );
});