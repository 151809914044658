import React, { useState } from "react";
import { QwantaProvider, useAuth, Ide, Guest } from "qwanyx";


const QwanyxApp = () => {
  const { currentUser, logged } = useAuth(); // Get the current user from AuthProvider
  
  // Initialize selectedCard with specific values
  const [selectedCard, setSelectedCard] = useState({
    _id: "67ebed89cdd8254087f5ec00",
    di: "645b5e00c7b61df466430901",
    ed: "67ebed7ccdd8254087f5ebff",
    pa: "67ebef80cdd8254087f5ec01"
  });

  // Styling for the app wrapper
  const style = {
    width: "100%",
    height: "100%",
    overflow: "hidden", // Prevent scrollbars
    backgroundColor: "white",
    zIndex: 1,
  };

  // Show loading state while authenticating
  if (!logged) {
    return (
      <div>
        {selectedCard && selectedCard._id && (
          <Guest initialCard ={selectedCard}  />
        )}
        <div>Loading...</div>
      </div>
    );
  }

  // Configuration for QwantaProvider if needed
  /*
  const qwantumDefaults = {
    ideDock: true,
  };
  */

  return (
    <div style={style}>
      <Ide currentUser={currentUser}>
        <QwantaProvider
          _id={currentUser?.id || "guest"} // Fallback to "guest" if no ID
          // embedEditor={false}
          // qwantum={qwantumDefaults}
        >
          {/* Main app UI */}
          <div>Welcome, {currentUser?.profile?.email || "Guest"}!</div>
        </QwantaProvider>
      </Ide>
    </div>
  );
};

export default QwanyxApp;