import React, { useCallback, useState, useContext, useMemo, useEffect } from "react";
import { persist, sync as fetchSync, QwantaEditor } from "qwanyx";
import { ObjectId } from "bson";

const QwantaContext = React.createContext(null);

export const QwantaProvider = ({
    _id = null,
    di = null,
    ed = null,
    pa = null,
    qwantum = {},
    type = null,
    qwantaType,
    children,
    backup = false,
    //sync = false,
    embedEditor = true,
}) => {
    const parentContext = useContext(QwantaContext);

    // Generate `_id` if not provided
    const generatedId = useMemo(() => _id || new ObjectId().toHexString(), [_id]);

    const inferredDi = useMemo(() => {
        if (qwantaType === "instance") {
            // When `qwantaType` is "instance," use parent's `di` or fallback
            return di || parentContext?.di || null;
        } else {
            // For other `qwantaType` values, use parent's `_id` as `di` or default
            return di || parentContext?._id || null;
        }
    }, [di, qwantaType, parentContext]);

    const inferredEd = useMemo(() => {
        if (qwantaType === "instance") {
            // When `qwantaType` is "instance," use parent's `_id` as `ed` or fallback
            return ed || parentContext?._id || null;
        } else {
            // For other `qwantaType` values, `ed` remains as passed (no special handling)
            return ed || null;
        }
    }, [ed, qwantaType, parentContext]);

    const [state, setState] = useState({
        _id: generatedId,
        di: inferredDi,
        ed: inferredEd,
        pa: pa || null,
        qwantum: { type: qwantum.type || type || null, ...qwantum },
        timestamp: Date.now(),
        qwantaType: qwantaType,
    });

    // Refresh state when `_id` changes
    useEffect(() => {
        const refreshState = async () => {
            try {
                const initialState = await fetchSync(generatedId);
                setState({
                    _id: generatedId,
                    di: inferredDi,
                    ed: inferredEd,
                    qwantum: { type: type || initialState?.type || null, ...initialState },
                    timestamp: Date.now(),
                    qwantaType: qwantaType,
                });
            } catch (error) {
                //console.error("Error refreshing Qwantum state:", error);
                setState((prevState) => ({
                    ...prevState,
                    timestamp: Date.now(), // Force timestamp update for debugging
                }));
            }
        };

        refreshState();
    }, [generatedId]); // Run every time `_id` changes


    const set = useCallback(async (key, value) => {
        // Ensure key is defined and valid
        if (!key) {
            //console.warn("Attempted to set a value without a valid key in Qwanta.");
            return; // Exit early; do nothing
        }

        const delta = {
            [key]: value,
            qwantaType: state.qwantum.qwantaType || qwantaType,
        };

        setState((prevState) => ({
            ...prevState,
            qwantum: { ...prevState.qwantum, ...delta },
            timestamp: Date.now(), // Update timestamp
        }));

        try {
            // Persist data if a valid key is provided
            await persist(delta, state._id, state.di, state.ed, backup);
        } catch (error) {
            console.error("Error persisting Qwantum:", error);
        }
    }, [state._id, state.di, backup, qwantaType, state.qwantum.qwantaType]);

    const get = useCallback((key) => state.qwantum[key], [state.qwantum]);

    const contextValue = useMemo(() => ({
        get,
        set,
        _id: state._id,
        di: state.di,
        ed: state.ed,
        qwantaType: state.qwantaType,
    }), [get, set, state._id, state.di, state.ed, state.qwantaType]);

    return (
        <QwantaContext.Provider value={contextValue}>
            {/*We embed a frame and make it not draggable which makes it as a background*/}
            {embedEditor && <QwantaEditor draggable={false} resizeable={false} />}
            {children}
        </QwantaContext.Provider>
    );
};
const test = null
export const useQwanta = (key) => {
    const context = useContext(QwantaContext);

    if (!context) {
        // Throw an error early if the hook is used outside its provider
        throw new Error("useQwanta must be used within a QwantaProvider");
    }

    // Always define the value using useMemo, even for invalid keys
    const value = useMemo(() => {
        if (!key) {
            //console.warn("useQwanta was called without a valid key.");
            return null; // Return null for invalid keys
        }

        return context.get(key) ?? null; // Retrieve the value from context for valid keys
    }, [context, key]); // Hook dependency must include context and key

    // Always define the setter function
    const setter = useMemo(() => {
        if (!key) {
            // Return a no-op setter for invalid keys
            return () => { };
        }

        // Return the actual setter from provider context
        return context.set;
    }, [context, key]);

    // Return value and setter, no matter what
    return [value, setter];
};

