import React from 'react';
import { SwitchIcon, FileUploader, useQwanta, newQwanta, setDocumentsBatch } from 'qwanyx';

export const ImportToolBox = ({
  iconSize = "24",
  height = "30px",
  fieldValues = "null",
  onBack
}) => {
  const [editorId] = useQwanta("_id");
  const [frameId] = useQwanta("di");

  const transformData = (data, fieldValues, editorId, frameId) => {
    return data
      .map((record) => {
        // Map the record using fieldValues
        const mappedRecord = mapRecordToFields(record, fieldValues);

        // If no properties fit (empty object), filter it out
        if (Object.keys(mappedRecord).length === 0) {
          return null; // Mark as invalid to filter later
        }

        // Add Qwanta-specific fields if the record is valid
        const qwantaRecord = newQwanta(editorId, frameId);

        return {
          ...qwantaRecord, // Qwanta-specific fields
          ...mappedRecord, // Mapped fields
        };
      })
      .filter((record) => record !== null); // Remove invalid records
  };

  const mapRecordToFields = (record, fieldValues) => {
    if (!fieldValues) return record; // Return the original record if no mapping is provided

    const mappedRecord = {};

    // Iterate over the field mappings
    for (const targetField in fieldValues) {
      const sourceField = fieldValues[targetField];

      // Only map fields that exist on the `record`
      if (record.hasOwnProperty(sourceField)) {
        mappedRecord[targetField] = record[sourceField];
      }
    }

    return mappedRecord; // Return the filtered and mapped record
  };

  const handleFileSelect = async (data) => {
    //console.log("Original Data:", data);
    //console.log("Field Values:", fieldValues);

    // Transform the data using the helper function
    const dataToPersist = transformData(data, fieldValues, editorId, frameId);

    // Log the transformed data
    //console.log("Transformed Data:", dateToPersist);

    try {
      const result = await setDocumentsBatch(dataToPersist);
      //console.log("Save successful:", result);
    } catch (error) {
      // Handle the error gracefully and show an alert
      alert(error.message); // Or use a toast notification library
    }
    // Proceed with persistence logic here if necessary (e.g., API call)
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end", // This aligns the content to the right.
        alignItems: "center", // Keeps items vertically centered.
        width: "100%", // Ensures the container takes up the full width.
        height: height,

        // backgroundColor: "rgba(0, 0, 0, 0.07)",
        // borderTop: "1px solid rgba(0, 0, 0, 0.2)",
        //marginBottom: '15px'
      }}
    >
      <FileUploader onFileSelect={handleFileSelect}>
        {(openFileDialog) => (
          <SwitchIcon
            iconNames={["import"]}
            size={32} // You can customize this size here
            onClick={openFileDialog} // Trigger file dialog on click
          />
        )}
      </FileUploader>
    </div>
  );
};

export default ImportToolBox;