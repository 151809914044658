import React, { createContext, useContext } from 'react';

const LayoutContext = createContext({});

export const FlexLayout = ({
    direction = 'row',
    width = '100%',
    height = '100%',
    aSize = 'auto',
    bSize = 'auto',
    showA = true,
    showB = true,
    a = null,
    m = null,
    b = null,
    style = {},
    aAlign = 'flex-start',
    aJustify = 'flex-start',
    mAlign = 'flex-start',
    mJustify = 'flex-start',
    bAlign = 'flex-start',
    bJustify = 'flex-start',
    padding
}) => {
    const contextValue = {
        direction,
        showA,
        showB,
        aSize,
        bSize,
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: direction,
        width,
        height,
        boxSizing: 'border-box',
        ...style
    };

    const createSectionStyle = (size, show, align, justify) => ({
        display: show ? 'flex' : 'none',
        flexDirection: 'column',
        [direction === 'row' ? 'width' : 'height']: size,
        [direction === 'row' ? 'height' : 'width']: '100%',
        flexShrink: 0,
        boxSizing: 'border-box',
        alignItems: align,
        justifyContent: justify,
        padding
    });

    const aStyle = createSectionStyle(aSize, showA, aAlign, aJustify);
    const mStyle = {
        ...createSectionStyle('auto', true, mAlign, mJustify),
        flex: '1 1 auto',
    };
    const bStyle = createSectionStyle(bSize, showB, bAlign, bJustify);

    return (
        <LayoutContext.Provider value={contextValue}>
            <div style={containerStyle}>
                {showA && <div style={aStyle}>{a}</div>}
                <div style={mStyle}>{m}</div>
                {showB && <div style={bStyle}>{b}</div>}
            </div>
        </LayoutContext.Provider>
    );
};

export const useLayout = () => {
    const context = useContext(LayoutContext);
    if (context === undefined) {
        throw new Error('useLayout must be used within a FlexLayout');
    }
    return context;
};

export default FlexLayout;