import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export const SvgInterpreter = ({
  iconName = "Coffee",
  size = 24,
  className = "",
}) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        // Construct the public folder path
        const svgPath = `/cutouts/${iconName}.svg`;

        // Fetch the SVG file
        const response = await fetch(svgPath);

        // Handle cases where the file is not found or has errors
        if (!response.ok) {
          throw new Error(`Failed to load SVG: ${svgPath}`);
        }

        const rawSvg = await response.text();
        setSvgContent(rawSvg);
      } catch (error) {
        console.error(`Error loading SVG "${iconName}":`, error);

        // Set a fallback SVG in case of an error
        setSvgContent(
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <text x="0" y="12" fill="red">NOIC</text>
          </svg>`
        );
      }
    };

    fetchSvg();
  }, [iconName]);

  if (!svgContent) {
    return null; // Or a loading indicator if you prefer
  }

  return (
    <div
      className={`svg-icon ${className}`}
      style={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: `${size}px`,
        height: `${size}px`,
      }}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

SvgInterpreter.propTypes = {
  iconName: PropTypes.string.isRequired, // The name of the SVG file, minus the '.svg' extension
  size: PropTypes.number, // The size of the rendered icon
  className: PropTypes.string, // Additional classes for styling
};

export default SvgInterpreter;