import React, { useState } from "react";
import { IconButton, ToolBox, useListContext } from "qwanyx";

// Define your icons array here with updated status ranges
const icons = [
  {
    id: 1,
    iconNames: ["task-backlog", "task-backlog-selected"],
    label: "backlog",
  },
  {
    id: 2,
    iconNames: ["task-pending", "task-pending-selected"],
    label: "pending",
  },
  {
    id: 3,
    iconNames: ["task-inprogress", "task-inprogress-selected"],
    label: "progress",
  },
  {
    id: 4,
    iconNames: ["task-done", "task-done-selected"],
    label: "done",
  },
  {
    id: 5,
    iconNames: ["task-urgent", "task-urgent-selected"],
    label: "urgent",
    statusRange: null
  }
];

export const ProjectsIcons = ({ onImportClick, size = 18 }) => {
  const HEIGHT = size * 3.5;
  const ICON_SIZE = size;

  const [selectedIconId, setSelectedIconId] = useState(null);
  const border = "1px solid rgba(0, 0, 0, 0.2)";

  const { updateQuery } = useListContext();

  const triggerSearch = (icon) => {
    if (icon) {
      const query = {};

      if (icon.label === "backlog") {
        query.status = {
          $in: ["", null],  // Empty or missing statuses
        };
      } else if (icon.label === "pending") {
        query.status = { $in: ["0", 0] }; // ✅ Handle both string/number
      } else if (icon.label === "progress") {
        query.status = {
          $nin: ["0", 0, "100", 100, "", null], // ✅ Ensure status is valid
          $exists: true,
        };
      } else if (icon.label === "urgent") {
        const today = new Date().toISOString().split("T")[0];

        query.duedate = { $lte: today };
        query.status = {
          $nin: ["100", 100], // ✅ Exclude both "100" and 100
          $exists: true,  // ✅ Ensure it actually exists
        };
      } else if (icon.label === "done") {
        query.status = { $in: ["100", 100] };  // ✅ Match string "100" and number 100
      }

      updateQuery(query);
    } else {
      updateQuery({});
    }
  };

  return (
    <ToolBox height={HEIGHT} borderTop={border} borderBottom={border}>
      {icons.map((icon) => (
        <IconButton
          key={icon.id}
          iconNames={
            selectedIconId === icon.id
              ? [icon.iconNames[1]]  // Show selected icon
              : [icon.iconNames[0]]  // Show default icon
          }
          size={ICON_SIZE}
          onClick={() => {
            const newSelection = selectedIconId === icon.id ? null : icon.id;  // Toggle icon selection
            setSelectedIconId(newSelection);  // Update state first

            triggerSearch(newSelection ? icon : null);  // Call search AFTER updating the state
          }}
        >
          {icon.label}
        </IconButton>
      ))}
    </ToolBox>
  );
};

export default ProjectsIcons;