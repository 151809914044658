import React from "react";
import { SwitchIcon, Typography, Button } from "qwanyx";

export const IconButton = ({
    iconNames,
    size = 24,
    children,
    direction = "row",
    onClick, // Parent's onClick passed in
    color = "",
    fontSize = "12px",
    fontWeight = "bold",
    gap = "8px", // Optional gap between content
    backgroundColor,
    padding,
    width
}) => {
    // Inline styles for dynamic direction
    const buttonStyle = {
        display: "flex",
        flexDirection: direction, // Dynamically determine layout direction
        alignItems: "center", // Ensure proper vertical/horizontal alignment
        fontSize,
        fontWeight,
        gap, // Add gap between icon and typography
        width,
        backgroundColor,
        padding
    };

    // Unified handler: for clicking on buttons/icons
    const handleOnClick = () => {
        console.log("IconButton clicked."); // Debugging click
        if (onClick) {
            onClick(); // Trigger any higher-level actions from parent
        }
    };

    return (
        <Button style={buttonStyle} onClick={handleOnClick}>
            {/* Render the SwitchIcon */}
            <SwitchIcon
                iconNames={iconNames}

                size={size}
                fill="black"
                stroke="none"
                onClick={handleOnClick} // Handle clicks directly on the icon
            />
            {/* Render the children within Typography */}
            <Typography
                className="buttonTypo"
                color={color}
                
                style={{
                    textAlign: direction === "column" ? "center" : "left", // Adjust alignment based on direction
                    alignSelf: "center", // Ensures vertical alignment within the parent flex container
                    backgroundColor: 'green'
                }}
            >
                {children}
            </Typography>
        </Button>
    );
};

export default IconButton;