import { createContext, useContext, useRef, useMemo } from 'react';

// Custom data provider class - optimized
class CustomDataProvider {
  constructor() {
    this.data = {
      root: {
        index: 'root',
        isFolder: true,
        children: [],
        data: 'Root',
      }
    };
    this.changeListeners = [];
  }

  async getTreeItem(itemId) {
    return this.data[itemId];
  }

  updateData(newData) {
    this.data = newData;
    this.changeListeners.forEach(listener => listener(Object.keys(newData)));
  }

  onDidChangeTreeData(listener) {
    this.changeListeners.push(listener);
    return {
      dispose: () => {
        this.changeListeners = this.changeListeners.filter(l => l !== listener);
      },
    };
  }
  
  // New method to handle item movements
  moveItems(itemIds, targetId, destinationIndex = null) {
    if (!itemIds.length || !targetId || !this.data[targetId]) return false;
    
    const newData = { ...this.data };
    
    // Find all parents that need updating
    const parentsToUpdate = {};
    Object.keys(newData).forEach(key => {
      if (newData[key].children) {
        const childrenToRemove = itemIds.filter(id => newData[key].children.includes(id));
        if (childrenToRemove.length > 0) {
          parentsToUpdate[key] = childrenToRemove;
        }
      }
    });
    
    // Remove items from old parents
    Object.keys(parentsToUpdate).forEach(parentId => {
      newData[parentId] = {
        ...newData[parentId],
        children: newData[parentId].children.filter(id => !parentsToUpdate[parentId].includes(id))
      };
    });
    
    // Make sure target has children and is marked as folder
    if (!newData[targetId].children) {
      newData[targetId].children = [];
    }
    newData[targetId].isFolder = true;
    
    // Add items to target at specified position
    if (typeof destinationIndex === 'number') {
      const newChildren = [...newData[targetId].children];
      for (let i = 0; i < itemIds.length; i++) {
        // Don't add if already in the array
        if (!newChildren.includes(itemIds[i])) {
          newChildren.splice(destinationIndex + i, 0, itemIds[i]);
        }
      }
      newData[targetId].children = newChildren;
    } else {
      // Add to end if no specific position
      newData[targetId].children = [
        ...newData[targetId].children,
        ...itemIds.filter(id => !newData[targetId].children.includes(id))
      ];
    }
    
    // Update moved items to reference new parent
    itemIds.forEach(id => {
      if (newData[id]) {
        newData[id] = {
          ...newData[id],
          ed: targetId !== 'root' ? targetId : null,
          isRoot: targetId === 'root'
        };
      }
    });
    
    this.updateData(newData);
    return true;
  }
}

// Create context for the tree data provider
const TreeDataContext = createContext(null);

// Provider component that wraps your app
export const TreeDataProvider = ({ children }) => {
  // Use a ref for the data provider to keep it stable across renders
  const dataProviderRef = useRef(null);
  
  // Create the data provider only once
  if (dataProviderRef.current === null) {
    dataProviderRef.current = new CustomDataProvider();
  }

  // Create a stable context value
  const contextValue = useMemo(() => ({
    dataProvider: dataProviderRef.current,
    processAndUpdateTreeData: (items) => {
      if (!items || Object.keys(items).length === 0) return;
      
      // Process items without creating unnecessary objects
      const processedItems = { 
        root: {
          index: 'root',
          isFolder: true,
          children: Object.keys(items).filter(id => items[id].isRoot),
          data: 'Root',
        }
      };
      
      // Add items with minimal processing
      for (const [key, item] of Object.entries(items)) {
        processedItems[key] = {
          ...item,
          children: item.children || [],
          isFolder: item.isFolder || (item.children && item.children.length > 0)
        };
      }
      
      dataProviderRef.current.updateData(processedItems);
    },
    // Add method to move items in the tree
    moveItems: (itemIds, targetId, destinationIndex) => {
      return dataProviderRef.current.moveItems(itemIds, targetId, destinationIndex);
    }
  }), []);

  return (
    <TreeDataContext.Provider value={contextValue}>
      {children}
    </TreeDataContext.Provider>
  );
};

// Custom hook to use the tree data context
export const useTreeData = () => {
  const context = useContext(TreeDataContext);
  if (!context) {
    throw new Error('useTreeData must be used within a TreeDataProvider');
  }
  return context;
};