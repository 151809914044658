import React, { createContext, useState, useContext } from "react";

const DragDropContext = createContext();

export const DragDropProvider = ({ children }) => {
  const [objects, setObjects] = useState([]);
  const [draggingObject, setDraggingObject] = useState(null);

  const createObject = (type) => {
    const newObject = {
      id: `${type}-${Date.now()}`,
      type,
      x: 200,
      y: 200,
      zIndex: 1000, // Ensure it starts above everything
    };
    setObjects((prev) => [...prev, newObject]);
  };

  const updateObjectPosition = (id, x, y) => {
    setObjects((prev) =>
      prev.map((obj) => (obj.id === id ? { ...obj, x, y } : obj))
    );
  };

  return (
    <DragDropContext.Provider
      value={{
        objects,
        draggingObject,
        setDraggingObject,
        createObject,
        updateObjectPosition,
      }}
    >
      {children}
    </DragDropContext.Provider>
  );
};

export const useDragDrop = () => useContext(DragDropContext);
