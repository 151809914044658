import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const HotDisplay = ({
  children,
  containerStyle = {},
  style = {},
  dimensions = {},
  hideOnClickOutside = false, // New prop
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // Control visibility
  const containerRef = useRef(null);

  const defaultStyle = {
    display: isVisible ? 'block' : 'none',
    transition: 'all 0.3s ease',
    zIndex: 1000,
    ...style,
  };

  const defaultDimensions = {
    width: dimensions.width || '20px',
    height: dimensions.height || '20px',
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (hideOnClickOutside) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [hideOnClickOutside]);

  return (
    <div
      ref={containerRef}
      style={{ cursor: 'pointer', ...containerStyle, ...defaultDimensions, zIndex: 1000 }}
      onMouseEnter={() => {
        setIsHovered(true);
        setIsVisible(true); // Show on hover
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        if (!hideOnClickOutside) setIsVisible(false); // Hide if not using click outside
      }}
    >
      <div style={defaultStyle}>{children}</div>
    </div>
  );
};

HotDisplay.propTypes = {
  children: PropTypes.node.isRequired,
  containerStyle: PropTypes.object,
  style: PropTypes.object,
  dimensions: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  hideOnClickOutside: PropTypes.bool, // New prop type
};

export default HotDisplay;
