import React from "react";

/**
 * A straightforward, reusable input field component.
 */
export const FieldBase = ({
    value, // Controlled value
    onChange, // Controlled change handler
    onKeyDown, //Add keydown listener
    onBlur, // Add blur listener
    placeholder = "Enter text...",
    fontSize = "14px",
    borderRadius = "0px",
    width = "100%",
    style = {}, // For custom inline styles
}) => {
    // Render a simple input field
    const calculatedHeight = `${parseFloat(fontSize) + 2}px`;
    return (
        <div
            style={{
                width,
                // Dynamic height
                position: "relative",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: calculatedHeight,
            }}
        >
            <input
                type="text"
                value={value} // Make it controlled
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                style={{
                    // Default styles
                    all: "unset",
                    width: '100%',
                    backgroundColor: "white",
                    fontSize,
                    borderRadius,
                    height: calculatedHeight, // Dynamic height
                    boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    padding: "3px 7px",
                    display: "block",
                    ...style,
                }}
            />
        </div>
    );
};

export default FieldBase;