import React from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const InvoiceTable = ({
  data = [],
  size = '4',
  color = 'dark',
  alignments = 'left'
}) => {
  // Validate data structure
  if (!Array.isArray(data) || data.length === 0) {
    console.error('InvoiceTable: data prop must be a non-empty array of arrays');
    return <div>Invalid data format</div>;
  }

  // Parse alignment string into an array
  const alignmentArray = alignments.split(',').map(a => a.trim());
  
  // Extract header row (first row) and body rows
  const headerRow = data[0];
  const bodyRows = data.slice(1);

  // Find the maximum number of columns
  const maxColumns = data.reduce((max, row) => {
    return Array.isArray(row) && row.length > max ? row.length : max;
  }, 0);

  return (
    <div className="invoice-table">
      <table className={`table is-fullwidth is-size-${size}`}>
        <thead>
          <tr>
            {[...Array(maxColumns)].map((_, colIndex) => {
              // Get header value (if exists)
              const headerValue = Array.isArray(headerRow) && colIndex < headerRow.length 
                ? headerRow[colIndex] 
                : '';
              
              // Determine alignment for this column
              const alignment = colIndex < alignmentArray.length 
                ? alignmentArray[colIndex] 
                : 'left';
                
              return (
                <th 
                  key={`header-${colIndex}`}
                  className={`has-text-${alignment} has-text-${color}`}
                >
                  {headerValue}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {bodyRows.map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`}>
              {[...Array(maxColumns)].map((_, colIndex) => {
                // Get cell value (if exists)
                const cellValue = Array.isArray(row) && colIndex < row.length 
                  ? row[colIndex] 
                  : '';
                  
                // Determine alignment for this column
                const alignment = colIndex < alignmentArray.length 
                  ? alignmentArray[colIndex] 
                  : 'left';
                  
                return (
                  <td 
                    key={`cell-${rowIndex}-${colIndex}`}
                    className={`has-text-${alignment} has-text-${color}`}
                  >
                    {cellValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Define prop types for the component
InvoiceTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.string,
  alignments: PropTypes.string // Comma-separated list of alignments (e.g., "left,right,center")
};

export default InvoiceTable;

// Usage examples:
// const invoiceData = [
//   ["Item", "Quantity", "Price", "Total"],
//   ["Product A", "2", "$10.99", "$21.98"],
//   ["Product B", "1", "$24.99", "$24.99"],
//   ["Product C", "3", "$5.99", "$17.97"]
// ];
// 
// <InvoiceTable data={invoiceData} />
// <InvoiceTable data={invoiceData} alignments="left,center,right,right" size="5" color="dark" />