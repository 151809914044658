import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const LAYOUT_STORAGE_KEY = "grid-layout"; // Key for local storage

export const Grid = () => {
    const defaultLayout = _.range(0, 8).map((i) => ({
        x: (i % 4) * 3,
        y: Math.floor(i / 4) * 2,
        w: 3,
        h: 2,
        i: i.toString(),
        static: Math.random() < 0.1,
    }));

    const loadSavedLayout = () => {
        const savedLayout = localStorage.getItem(LAYOUT_STORAGE_KEY);
        return savedLayout ? JSON.parse(savedLayout) : { lg: defaultLayout };
    };

    const [layouts, setLayouts] = useState(loadSavedLayout);
    const [compactType, setCompactType] = useState("vertical");
    const [mounted, setMounted] = useState(false);
    const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

    // Adjustable settings
    const [settings, setSettings] = useState({
        cols: 12,
        rowHeight: 50,
        gap: { horizontal: 10, vertical: 10 }, // Separate horizontal and vertical gap
        containerPadding: [10, 10],
    });

    useEffect(() => {
        setMounted(true);
    }, []);

    const onLayoutChange = (layout, updatedLayouts) => {
        setLayouts(updatedLayouts);
        localStorage.setItem(LAYOUT_STORAGE_KEY, JSON.stringify(updatedLayouts));
    };

    const onBreakpointChange = (breakpoint) => {
        setCurrentBreakpoint(breakpoint);
    };

    const resetLayout = () => {
        const newLayout = { lg: defaultLayout };
        setLayouts(newLayout);
        localStorage.setItem(LAYOUT_STORAGE_KEY, JSON.stringify(newLayout));
    };

    const updateSettings = (key, value) => {
        setSettings((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const generateDOM = () => {
        return layouts.lg.map((item) => (
            <div
                key={item.i}
                style={{
                    background: item.static ? "#e57373" : "#81c784",
                    border: "1px solid #388e3c",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {item.static ? `Static ${item.i}` : `Qwanta ${item.i}`}
            </div>
        ));
    };

    return (
        <div>
            <div style={{ marginBottom: "20px" }}>
                <h3>Grid Settings</h3>
                <label>
                    Columns:
                    <input
                        type="number"
                        value={settings.cols}
                        onChange={(e) => updateSettings("cols", parseInt(e.target.value))}
                        min="1"
                    />
                </label>
                <label>
                    Row Height:
                    <input
                        type="number"
                        value={settings.rowHeight}
                        onChange={(e) =>
                            updateSettings("rowHeight", parseInt(e.target.value))
                        }
                        min="10"
                    />
                </label>
                <label>
                    Horizontal Gap:
                    <input
                        type="number"
                        value={settings.gap.horizontal}
                        onChange={(e) =>
                            updateSettings("gap", {
                                ...settings.gap,
                                horizontal: parseInt(e.target.value),
                            })
                        }
                    />
                </label>
                <label>
                    Vertical Gap:
                    <input
                        type="number"
                        value={settings.gap.vertical}
                        onChange={(e) =>
                            updateSettings("gap", {
                                ...settings.gap,
                                vertical: parseInt(e.target.value),
                            })
                        }
                    />
                </label>
                <label>
                    Container Padding:
                    <input
                        type="number"
                        value={settings.containerPadding[0]}
                        onChange={(e) =>
                            updateSettings("containerPadding", [
                                parseInt(e.target.value),
                                settings.containerPadding[1],
                            ])
                        }
                    />
                </label>
                <button onClick={resetLayout}>Reset Layout</button>
            </div>

            <ResponsiveGridLayout
                className="layout"
                layouts={layouts}
                rowHeight={settings.rowHeight}
                cols={{ lg: settings.cols, md: 10, sm: 6, xs: 4, xxs: 2 }}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                containerPadding={settings.containerPadding}
                margin={[settings.gap.horizontal, settings.gap.vertical]} // Apply horizontal and vertical gap
                onLayoutChange={onLayoutChange}
                onBreakpointChange={onBreakpointChange}
                compactType={compactType}
                preventCollision={!compactType}
                //useCSSTransforms={mounted}
                useCSSTransforms={false}
                measureBeforeMount={false}
                //isDraggable={false} // Disable dragging
                //isResizable={false} // Disable resizing
            >
                {generateDOM()}
            </ResponsiveGridLayout>
        </div>
    );
};

export default Grid;
