import React from "react";
import { Label } from "qwanyx";

export const SimpleLabelledField = ({
  labelText = "Default Label",
  fieldValue = "",
  placeholder = "Type here...",
  onFieldChange,
  onBlur, // Add onBlur as a prop
  labelWidth = "150px",
  fontSize = "16px",
}) => {
  const calculatedHeight = `${parseFloat(fontSize) + 2}px`;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start", // Align to the left
        alignItems: "center", // Vertically center
        gap: "4px", // Add a gap between the label and the field
      }}
    >
      <Label
        text={labelText}
        fontSize={fontSize}
        style={{
          width: labelWidth,
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center", // Force vertical alignment
        }}
      />
      <span
        style={{ marginRight: "2px", fontSize }} // Styling for colon
      >
        :
      </span>
      <input
        type="text"
        value={fieldValue} // Bind to local state
        placeholder={placeholder}
        onChange={onFieldChange} // Handle changes
        onBlur={onBlur} // Trigger onBlur when focus is lost
        style={{
          all: "unset",
          backgroundColor: "white",
          fontSize,
          color: "black",
          textAlign: "left",
          width: "100%",
          height: calculatedHeight, // Dynamic height
          boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)",
          padding: "3px",
          border: "1px solid rgba(0, 0, 0, 0.2)", // Border with transparency
          borderRadius: "2px", // Rounded corners
        }}
        autoFocus
      />
    </div>
  );
};

export default SimpleLabelledField;