import React from "react";
import { SwitchIcon, Textarea, ToolBox } from "qwanyx";

export const QueryEditor = ({ toggle }) => {
    return (
        <ToolBox
            height='250px'
            alignItems="flex-start"
        >
            <SwitchIcon
                iconNames={["Burger"]}
                size="17"
                onClick={() => console.log("Navigate First")}
                id="navigate-first-icon"
            />
            <Textarea
                fontSize="16px"
                placeholder="Enter text here..."
                onSave={null}
                width="100%"
                height="100%"
                closeOnComplete = {true}
                rows={8} // Default number of rows
                //resize={true} // Control resize behavior
            />
            <SwitchIcon
                iconNames={["lens"]}
                size="17"
                onClick={() => console.log("Navigate Next")}
                id="navigate-next-icon"
            />
            <SwitchIcon
                iconNames={["caret-down"]}
                size="17"
                onClick={toggle} // Calls the passed toggle function to switch to SimpleSearch
                id="switch-to-query-editor"
            />
        </ToolBox>
    );
};

export default QueryEditor;