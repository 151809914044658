import React from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const BankAccountDisplay = ({
  bankName = 'BankName',
  iban = '',
  bic = '',
  size = '4',
  color = 'dark'
}) => {
  return (
    <div className="bank-account-display">
      {/* Fixed 3-column layout */}
      <div className="columns is-mobile is-gapless">
        {/* Column 1 - Bank Name */}
        <div className="column is-narrow">
          <div className={`has-text-right has-text-${color} is-size-${size} pr-2`}>{bankName}</div>
          <div className="is-invisible">Placeholder</div>
        </div>
        
        {/* Column 2 - Labels */}
        <div className="column is-narrow">
          <div className={`has-text-right has-text-${color} is-size-${size} pr-2`}>IBAN:</div>
          <div className={`has-text-right has-text-${color} is-size-${size} pr-2`}>BIC:</div>
        </div>
        
        {/* Column 3 - Values */}
        <div className="column">
          <div className={`has-text-${color} is-size-${size}`}>{iban}</div>
          <div className={`has-text-${color} is-size-${size}`}>{bic}</div>
        </div>
      </div>
    </div>
  );
};

// Define prop types for the component
BankAccountDisplay.propTypes = {
  bankName: PropTypes.string,
  iban: PropTypes.string,
  bic: PropTypes.string,
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.string
};

export default BankAccountDisplay;

// Usage examples:
// <BankAccountDisplay />                                     <!-- Shows default values -->
// <BankAccountDisplay bankName="Chase Bank" iban="US12345678901234567890" bic="CHASUS33" />
// <BankAccountDisplay bankName="Deutsche Bank" iban="DE89370400440532013000" bic="DEUTDEFF" size="3" color="primary" />