import { qwanyxCollection } from 'qwanyx'; // Assuming this gives us access to the collection.

/**
 * Persist multiple properties at once for a document
 * 
 * @param {string} _id - The document _id to update
 * @param {Object} properties - Object containing the properties to update
 * @param {boolean} backup - Whether to create a backup of the changes
 * @returns {Promise<void>}
 */
export async function persistQwanta(_id, properties, backup = false) {
  try {
    // Validate inputs
    if (!_id) {
      throw new Error("Document _id is required");
    }
    if (!properties || typeof properties !== 'object' || Array.isArray(properties)) {
      throw new Error("Properties must be a non-array object");
    }

    // Get the database collection
    const collection = qwanyxCollection();

    // Build the update object with all properties
    const updates = {
      $set: { ...properties }
    };

    // Handle backup logic if the flag is true
    if (backup) {
      const timestamp = new Date().toISOString();
      const deltaBackup = { [timestamp]: { ...properties } };

      // Add the delta backup to the `backup` array, limiting to 100 entries.
      updates.$push = {
        backup: {
          $each: [deltaBackup],
          $slice: -100, // Keep the most recent 100 items.
        },
      };
    }

    // Perform the upsert operation
    await collection.updateOne(
      { _id }, // Match the document by its _id
      {
        ...updates,
        $setOnInsert: { createdAt: new Date() }, // Add a createdAt field if this is a new document
      },
      { upsert: true } // Create the document if it doesn't exist
    );

    return true;
  } catch (error) {
    console.error("Error persisting Qwanta:", error);
    throw error; // Rethrow for higher-level handling
  }
}