import { qwanyxCollection } from "qwanyx";

export async function getInstancesBatch(
    query,
    skip = 0,
    limit = 300,
    projection,
    sortArray

) {
    try {
        const collection = qwanyxCollection();

        // Fetch the records based on pagination and projection
        const batch = await collection.find(query, {
            skip,
            limit,
            projection
        });

        // Custom sort logic
        const sortedBatch = batch.sort((a, b) => {
            // If sortArray is defined and contains at least one string
            if (Array.isArray(sortArray) && sortArray.length > 0) {
                for (let sortField of sortArray) {
                    const isDescending = sortField.startsWith('-'); // Check for descending order
                    const field = isDescending ? sortField.slice(1) : sortField; // Remove the '-' prefix if present
        
                    const valueA = a[field] ?? ''; // Use the specified field, fallback to an empty string
                    const valueB = b[field] ?? '';
        
                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        // Compare strings (case-insensitive)
                        const comparison = valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
                        if (comparison !== 0) {
                            return isDescending ? -comparison : comparison; // Apply sort order
                        }
                    } else if (valueA instanceof Date || valueB instanceof Date) {
                        // Compare dates if the properties are Date objects or can be converted to Date
                        const dateA = new Date(valueA);
                        const dateB = new Date(valueB);
                        if (!isNaN(dateA) && !isNaN(dateB)) {
                            if (dateA < dateB) return isDescending ? 1 : -1;
                            if (dateA > dateB) return isDescending ? -1 : 1;
                        }
                    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                        // Compare numbers
                        if (valueA < valueB) return isDescending ? 1 : -1;
                        if (valueA > valueB) return isDescending ? -1 : 1;
                    }
                }
            }
        
            // Default custom sort logic when sortArray is not present or did not resolve sorting
            // Prioritize records with duedate
            if (a.duedate && !b.duedate) return -1; // `a` has a duedate, move it up
            if (!a.duedate && b.duedate) return 1; // `b` has a duedate, move it up
        
            // If both have duedates, sort by the oldest duedate first (ascending order)
            if (a.duedate && b.duedate) {
                const dateA = new Date(a.duedate);
                const dateB = new Date(b.duedate);
                if (dateA < dateB) return -1; // `a` is older, move it up
                if (dateA > dateB) return 1; // `b` is older, move it up
            }
        
            // Fallback: If both don't have duedates, continue with alphabetical sorting
            const getSortingFieldValue = (doc) => {
                if (doc.firstname) return doc.firstname.toLowerCase();
                if (doc.name) return doc.name.toLowerCase();
                if (doc.title) return doc.title.toLowerCase();
                return ''; // Default sorting value for documents with none of these fields
            };
        
            const valueA = getSortingFieldValue(a);
            const valueB = getSortingFieldValue(b);
        
            // Compare the field values for sorting
            return valueA.localeCompare(valueB);
        });

        // Return the sorted result
        return sortedBatch;
    } catch (error) {
        console.error("Error fetching batch of instances:", error);
        throw error;
    }
}