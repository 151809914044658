import React from "react";
import Icon from '@mdi/react';
import { mdiContentCopy, mdiTrashCanOutline, mdiCogOutline, mdiPlus, mdiMenu } from '@mdi/js';
import { useQwanyx } from 'qwanyx';

export const TreeHeader = ({
    height = "32px",
    iconSize = "18px",
    gap = "8px",
    backgroundColor = "#f5f5f5",
}) => {

    const { newCard } = useQwanyx();
    const { deleteCard } = useQwanyx();

    
    const handlePlusClick = () => {
        newCard();
    };
    const handleDeleteClick = () => {
        deleteCard();
    };

    return (
        <div style={{
            height,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 8px",
            backgroundColor
        }}>
            <div style={{ height, display: "flex", alignItems: "center", justifyContent: "left", gap }}>
                <div style={{ display: "flex", gap }}>
                    <Icon path={mdiMenu} size={iconSize} />
                </div>
                {/*<div> Hello, World!</div>*/}
            </div>
            {/* <div> Middle </div> */}
            <div style={{ display: "flex", gap }}>
                {/*<div onClick={handlePlusClick} style={{ cursor: "pointer" }}>
                    <Icon path={mdiPlus} size={iconSize} />
                </div>
                <Icon path={mdiContentCopy} size={iconSize} />
                <Icon path={mdiCogOutline} size={iconSize} />
                <div onClick={handleDeleteClick} style={{ cursor: "pointer" }}>
                    <Icon path={mdiTrashCanOutline} size={iconSize} />
                </div>*/}
            </div>
        </div>
    );
};

export default TreeHeader;