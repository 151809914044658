import React, { useRef } from 'react';
import * as XLSX from 'xlsx';

export const FileUploader = ({ onFileSelect, accept = '.xlsx,.xls', children }) => {
  const fileInputRef = useRef();

  // Function to open the file dialog
  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  // Function to handle when a file is selected
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && onFileSelect) {
      const reader = new FileReader();

      // Read the Excel file as an ArrayBuffer
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);

        // Parse the Excel data using XLSX
        const workbook = XLSX.read(data, { type: 'array' });

        // Get the first sheet name (you can loop through all sheets if needed)
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Pass the parsed file data to `onFileSelect`
        onFileSelect(jsonData);
      };

      // Read the file as an ArrayBuffer for XLSX parsing
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      {/* Hidden file input */}
      <input
        type="file"
        accept={accept} // Accepts only the specified formats (default: Excel formats)
        ref={fileInputRef}
        style={{ display: 'none' }} // Hidden input
        onChange={handleFileChange}
      />
      {/* Render children and pass down the openFileDialog trigger */}
      {children(openFileDialog)}
    </>
  );
};

export default FileUploader;