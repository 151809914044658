import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQwanta } from "qwanyx";
import { SimpleLabelledField, ImportToolBox } from "qwanyx";

export const ImportTab = ({ editorConfig = [], onBack }) => {
  // Ensure `editorConfig` is always treated as an array
  const fields = Array.isArray(editorConfig) ? editorConfig : [];

  // Internal state for controlling field display and editing (typed state)
  const [fieldValues, setFieldValues] = useState(() =>
    fields.reduce((acc, { defaultProps }) => {
      const { qwantumId } = defaultProps || {};
      if (qwantumId) acc[qwantumId] = ""; // Initialize empty field state
      return acc;
    }, {})
  );

  // `savedFieldValues` persists and syncs with the qwanta store
 const [savedFieldValues, setQwanta] = useQwanta("importSettings");

  // Add a ref to hold the latest state without triggering renders
  const fieldValuesRef = useRef(fieldValues);

  // Synchronize fieldValuesRef with `fieldValues`
  useEffect(() => {
    fieldValuesRef.current = fieldValues;
  }, [fieldValues]);

  // Load values from Qwanta into the state (on mount/when data changes)
  useEffect(() => {
    if (savedFieldValues) {
      setFieldValues((prev) => ({
        ...prev,
        ...savedFieldValues, // Merge saved values with defaults
      }));
    }
  }, []);

  /**
   * Handles per-field edits (typing only affects internal state)
   * Does NOT sync directly to the persistent state yet.
   */
  const handleFieldChange = (qwantumId, value) => {
    setFieldValues((prev) => ({
      ...prev,
      [qwantumId]: value, // Update the current field
    }));
  };

  /**
   * Handles persisting changes during `onBlur` (debounced save to the database).
   */
  const handleBlur = useCallback(() => {
    const latestFieldValues = fieldValuesRef.current; // Use the latest field values
    console.log("Persisting changes on blur", latestFieldValues); // Debugging log
    setQwanta("importSettings", latestFieldValues); // Save to Qwanta
  }, [setQwanta]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5",
        color: "#555",
        padding: "10px",
      }}
    >
      {/* Header/Toolbox */}
      <ImportToolBox onBack={onBack} fieldValues={fieldValues} />

      {/* Render fields dynamically */}
      {fields.map(({ defaultProps }) => {
        const { qwantumId } = defaultProps || {};
        if (!qwantumId) return null; // Skip invalid configurations

        return (
          <SimpleLabelledField
            key={qwantumId} // Ensures unique React keys
            labelText={qwantumId} // Label for the input field
            placeholder="Imported Property"
            fieldValue={fieldValues[qwantumId]} // Controlled input bound to internal state
            onFieldChange={(e) => handleFieldChange(qwantumId, e.target.value)} // Localized typing updates
            onBlur={handleBlur} // Persist data on blur
          />
        );
      })}
    </div>
  );
};

export default ImportTab;