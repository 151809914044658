import React from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const InvoiceNum = ({ 
  invoiceNum = '000000', 
  size = '4', 
  color = 'dark',
  justify = 'left',
  prefix = 'Facture n°'
}) => {
  return (
    <div className="invoice-number">
      <h1 className={`title is-${size} has-text-${color} has-text-${justify}`}>
        {prefix}{invoiceNum}
      </h1>
    </div>
  );
};

InvoiceNum.propTypes = {
  invoiceNum: PropTypes.string,
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.string,
  justify: PropTypes.oneOf(['left', 'centered', 'right', 'justified']),
  prefix: PropTypes.string
};

export default InvoiceNum;