/**
 * Tax number validation rules for different countries
 */
const taxNumberRules = {
    AF: { regex: /^\d{8}$/, description: "Afghanistan TIN (8 digits)." },
    AL: { regex: /^\d{10}$/, description: "Albania NUIS/NIPT (10 digits)." },
    DZ: { regex: /^\d{15}$/, description: "Algeria TIN (15 digits)." },
    AD: { regex: /^U\d{6}$/, description: "Andorra NRT (U followed by 6 digits)." },
    AO: { regex: /^\d{9}$/, description: "Angola NIF (9 digits)." },
    AR: { regex: /^\d{11}$/, description: "Argentina CUIT/CUIL/CDI (11 digits)." },
    AM: { regex: /^\d{9}$/, description: "Armenia TIN (9 digits)." },
    AU: { regex: /^\d{11}$/, description: "Australia ABN (11 digits)." },
    AT: { regex: /^U\d{8}$/, description: "Austria VAT (U followed by 8 digits)." },
    AZ: { regex: /^\d{10}$/, description: "Azerbaijan TIN (10 digits)." },
    BH: { regex: /^\d{8}$/, description: "Bahrain TIN (8 digits)." },
    BD: { regex: /^\d{12}$/, description: "Bangladesh TIN (12 digits)." },
    BY: { regex: /^\d{9}$/, description: "Belarus UNP (9 digits)." },
    BE: { regex: /^\d{10}$/, description: "Belgium VAT (10 digits)." },
    BO: { regex: /^\d{8,9}$/, description: "Bolivia NIT (8-9 digits)." },
    BR: { regex: /^\d{14}$/, description: "Brazil CNPJ (14 digits)." },
    BG: { regex: /^\d{9,10}$/, description: "Bulgaria VAT (9-10 digits)." },
    CA: { regex: /^\d{9}$/, description: "Canada BN (9 digits)." },
    CL: { regex: /^\d{8,9}-\d$/, description: "Chile RUT (8-9 numbers + checksum digit)." },  
    CN: { regex: /^\d{15,18}$/, description: "China TIN (15-18 digits)." },
    CO: { regex: /^\d{9,10}$/, description: "Colombia NIT (9-10 digits)." },
    HR: { regex: /^\d{11}$/, description: "Croatia OIB/VAT (11 digits)." },
    CY: { regex: /^[0-9A-Z]{9}$/, description: "Cyprus VAT (9 alphanumeric characters)." },
    CZ: { regex: /^\d{8,10}$/, description: "Czech Republic VAT (8-10 digits)." },
    DK: { regex: /^\d{8}$/, description: "Denmark CVR (8 digits)." },
    EG: { regex: /^\d{9,13}$/, description: "Egypt TIN (9-13 digits)." },
    EE: { regex: /^\d{9}$/, description: "Estonia VAT (9 digits)." },
    FI: { regex: /^\d{8}$/, description: "Finland VAT (8 digits)." },
    FR: { regex: /^[0-9A-Z]{11}$/, description: "France SIRET/VAT (11 alphanumeric characters)." },
    DE: { regex: /^\d{9}$/, description: "Germany VAT (9 digits)." },
    GR: { regex: /^\d{9}$/, description: "Greece AFM (9 digits)." },
    HU: { regex: /^\d{8}$/, description: "Hungary VAT (8 digits)." },
    IS: { regex: /^\d{10}$/, description: "Iceland TIN (10 digits)." },
    IN: { regex: /^\d{15}$/, description: "India GSTIN (15 digits)." },
    ID: { regex: /^\d{15}$/, description: "Indonesia NPWP (15 digits)." },
    IR: { regex: /^\d{11}$/, description: "Iran VAT (11 digits)." },
    IE: { regex: /^[0-9A-Z]{8,9}$/, description: "Ireland VAT (8-9 characters with letter at end)." },
    IL: { regex: /^\d{9}$/, description: "Israel VAT (9 digits)." },
    IT: { regex: /^\d{11}$/, description: "Italy VAT (11 digits)." },
    JP: { regex: /^\d{13}$/, description: "Japan Corporate Number (13 digits)." },
    KZ: { regex: /^\d{12}$/, description: "Kazakhstan BIN (12 digits)." },
    KE: { regex: /^\d{11}$/, description: "Kenya PIN (11 digits)." },
    KW: { regex: /^\d{10}$/, description: "Kuwait VAT (10 digits)." },
    MY: { regex: /^\d{12}$/, description: "Malaysia TIN (12 digits)." },
    MX: { regex: /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/, description: "Mexico RFC (XXXXYYYYMMDDXXX)." },
    NL: { regex: /^[A-Z0-9]{14}$/, description: "Netherlands VAT (14 alphanumeric characters)." },
    NZ: { regex: /^\d{9}$/, description: "New Zealand IRD (9 digits)." },
    NG: { regex: /^\d{12}$/, description: "Nigeria TIN (12 digits)." },
    NO: { regex: /^\d{9}$/, description: "Norway VAT (9 digits)." },
    PK: { regex: /^\d{7,12}$/, description: "Pakistan NTN (7-12 digits)." },
    PE: { regex: /^\d{11}$/, description: "Peru RUC (11 digits)." },
    PH: { regex: /^\d{9}$/, description: "Philippines TIN (9 digits)." },
    PL: { regex: /^\d{10}$/, description: "Poland NIP (10 digits)." },
    PT: { regex: /^\d{9}$/, description: "Portugal NIF (9 digits)." },
    RU: { regex: /^\d{10,12}$/, description: "Russia INN (10 or 12 digits)." },
    SA: { regex: /^\d{15}$/, description: "Saudi Arabia VAT (15 digits)." },
    ZA: { regex: /^\d{10}$/, description: "South Africa VAT (10 digits)." },
    ES: { regex: /^[A-Z0-9]{9}$/, description: "Spain NIF (9 alphanumeric characters)." },
    SE: { regex: /^\d{12}$/, description: "Sweden VAT (12 digits)." },
    CH: { regex: /^\d{9}$/, description: "Switzerland UID (9 digits)." },
    TR: { regex: /^\d{10}$/, description: "Turkey VAT (10 digits)." },
    UA: { regex: /^\d{10}$/, description: "Ukraine VAT (10 digits)." },
    GB: { regex: /^\d{9}$/, description: "United Kingdom VAT (9 digits)." },
    US: { regex: /^\d{2}-\d{7}$/, description: "United States EIN (XX-XXXXXXX)." },
    VN: { regex: /^\d{10}$/, description: "Vietnam TIN (10 digits)." },
  };
  
  /**
   * Validates a tax identification number, extracting the country code from the first two characters
   * @param {string} taxNumber - The tax number to validate
   * @returns {Object} Result object with validation info
   */
  export const validateTaxNumber = (taxNumber) => {
    // If tax number is empty, return "NA"
    if (!taxNumber || taxNumber.trim() === '') {
      return {
        isValid: true,
        result: "NA",
        description: "No tax number provided"
      };
    }
    
    // Extract the country code from the first two characters
    const country = taxNumber.substring(0, 2).toUpperCase();
    
    // Check if the country code exists in our rules
    if (!taxNumberRules[country]) {
      return {
        isValid: false,
        error: `Country code '${country}' is not supported.`,
        description: null
      };
    }
    
    // Get the validation rule for this country
    const rule = taxNumberRules[country];
    
    // Test the tax number against the regex
    const isValid = rule.regex.test(taxNumber.substring(2)); // Validate the rest of the string after country code
    
    return {
      isValid,
      error: isValid ? null : `Invalid tax number format for ${country}.`,
      description: rule.description
    };
  };
  
  /**
   * Returns a list of all supported country codes
   * @returns {string[]} Array of supported country codes
   */
  export const getSupportedCountries = () => {
    return Object.keys(taxNumberRules);
  };
  
  /**
   * Get the format description for a specific country
   * @param {string} countryCode - The ISO country code
   * @returns {string|null} Format description or null if country not supported
   */
  export const getFormatDescription = (countryCode) => {
    const country = countryCode.toUpperCase();
    return taxNumberRules[country]?.description || null;
  };
  
  export default {
    validateTaxNumber,
    getSupportedCountries,
    getFormatDescription,
    taxNumberRules
  };