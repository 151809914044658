import React, { useEffect, useRef, useState } from 'react';
import 'bulma/css/bulma.min.css';
import { InvoiceNum } from './InvoiceNum';

// CSS for the container structure
const styles = {
  fullHeightContainer: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    width: '100%'
    // No fixed height - will size to content
  },
  contentSection: {
    borderColor: 'black',
    flex: '1 1 auto', // This makes it fill available space
    width: '100%',
    borderRadius: 0,
    padding: '0px',
    border: '1px solid rgb(0, 0, 0)',
    overflow: 'auto' // Allow scrolling if content exceeds height
  },
  footer: {
    padding: '0px',
    width: '100%',
    //border: '1px solid rgb(0, 0, 0)',
    // No fixed height - will size to content
  }
};

export const InvoiceLayout = ({
  headerLeftContent,
  headerRightContent,
  contentSectionContent,
  footerContent,
  headerBackgroundColor = 'white',
  contentBackgroundColor = 'white',
  footerBackgroundColor = 'white'
}) => {
  const headerRef = useRef(null);
  const [useFlexColumn, setUseFlexColumn] = useState(false);

  // Function to check width and update layout
  const checkWidth = () => {
    if (headerRef.current) {
      const headerWidth = headerRef.current.offsetWidth;
      // You can adjust this threshold based on your needs
      setUseFlexColumn(headerWidth < 768);
    }
  };

  // Set up resize observer to watch for container size changes
  useEffect(() => {
    if (!headerRef.current) return;

    // Check on initial render
    checkWidth();

    // Set up ResizeObserver to watch for parent container size changes
    const resizeObserver = new ResizeObserver(() => {
      checkWidth();
    });

    resizeObserver.observe(headerRef.current);

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
    };
  }, []);

  return (
    <div style={styles.fullHeightContainer}>
      {/* Header - 20% height */}
      <div
        ref={headerRef}
        className={`has-background-${headerBackgroundColor}`}
        style={styles.header}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: useFlexColumn ? 'column' : 'row',
            minHeight: '100%'
          }}
        >
          {/* Left side of header */}
          <div style={{ flex: 1 }}>
            <div className={`notification is-${headerBackgroundColor}`} style={{ height: '100%', borderRadius: 0 }}>
              {headerLeftContent || "Header Left"}
            </div>
          </div>

          {/* Right side of header */}
          <div style={{ flex: 1 }}>
            <div className={`notification is-${headerBackgroundColor}`} style={{ height: '100%', borderRadius: 0 }}>
              {headerRightContent || "Header Right"}
            </div>
          </div>
        </div>
      </div>

      {/* Content section - 70% height */}
      
        <div className={`notification is-${contentBackgroundColor}`}  style={styles.contentSection}>
          {contentSectionContent || "Content section area - 70% height"}
        </div>
 
      {/* Footer - 10% height */}
      <div
        className={`has-background-${footerBackgroundColor}`}
        style={styles.footer}
      >
        
          {footerContent || (
            <div className="content has-text-centered">
              <p>Invoice footer - 10% height</p>
            </div>
          )}
        
      </div>
    </div>
  );
};

export default InvoiceLayout;