import React from "react";
import Icon from '@mdi/react';
import { mdiContentCopy, mdiTrashCanOutline, mdiHome } from '@mdi/js';

export const IdeHeader = ({
    height = "32px",
    iconSize = "18px",
    gap = "8px",
    backgroundColor = "#252525",
    color = "white",
}) => {
    return (
        <div style={{
            height,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 8px",

            backgroundColor
        }}>
            <div style={{ height, display: "flex", alignItems: "center", justifyContent: "left", gap }}>
                <div style={{ display: "flex", gap }}>
                    <Icon path={mdiHome} size={iconSize} color={color} />
                </div>
                <div style={{ color }}> Hello, World!</div>
            </div>
            <div style={{ color }}> Hello, World!</div>
            <div style={{ display: "flex", gap }}>
                <Icon path={mdiContentCopy} size={iconSize} color={color} />
                <Icon path={mdiContentCopy} size={iconSize} color={color} />
                <Icon path={mdiTrashCanOutline} size={iconSize} color={color} />

            </div>
        </div>
    );
};

export default IdeHeader;