import React, { useState, useEffect } from 'react';
import { SearchableList } from 'qwanyx';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

// Composant Twin qui utilise deux SearchableList côte à côte
export const TwinSearchableLists = ({
  brandsModelsData, // Object contenant les marques (clés) et les modèles (valeurs dans des arrays)
  brandLabel = "Marque",
  modelLabel = "Modèle",
  onBrandSelect,
  onModelSelect
}) => {
  const [selectedBrand, setSelectedBrand] = useState("");
  const [modelItems, setModelItems] = useState([]);

  // Mise à jour des modèles quand une marque est sélectionnée
  useEffect(() => {
    if (selectedBrand && brandsModelsData[selectedBrand]) {
      setModelItems(brandsModelsData[selectedBrand]);
    } else {
      setModelItems([]);
    }
  }, [selectedBrand, brandsModelsData]);

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand);
    if (onBrandSelect) {
      onBrandSelect(brand);
    }
  };

  const handleModelSelect = (model) => {
    if (onModelSelect) {
      onModelSelect(model, selectedBrand);
    }
  };

  // Extraire la liste des marques de l'objet brandsModelsData
  const brandItems = Object.keys(brandsModelsData);

  return (
    <div className="columns is-mobile">
      <div className="column is-half">
        <SearchableList
          items={brandItems}
          label={brandLabel}
          placeholder={`Rechercher une ${brandLabel.toLowerCase()}`}
          onSelect={handleBrandSelect}
          qwantumId="brand"
        />
      </div>
      <div className="column is-half">
        <SearchableList
          items={modelItems}
          label={modelLabel}
          placeholder={selectedBrand ? `Rechercher un ${modelLabel.toLowerCase()}` : `Sélectionnez d'abord une ${brandLabel.toLowerCase()}`}
          onSelect={handleModelSelect}
          disabled={!selectedBrand}
          qwantumId="model"
        />
      </div>
    </div>
  );
};

TwinSearchableLists.propTypes = {
  brandsModelsData: PropTypes.object.isRequired,
  brandLabel: PropTypes.string,
  modelLabel: PropTypes.string,
  onBrandSelect: PropTypes.func,
  onModelSelect: PropTypes.func
};

export default TwinSearchableLists;
