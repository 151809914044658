import { newId } from "qwanyx";

export function newQwanta(editorId, frameId, editorParent, qwantaType= "instance" ) {

    const newInstance = {
        _id: newId(),          // Generate a unique ID
        di: frameId,
        pa: editorParent,           // Frame ID from the provider
        ed: editorId,       // Editor ID from the provider
        qwantaType
    };

    return newInstance; // Return the new record
}