import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth, newQwanta, deleteQwanta } from 'qwanyx';

const QwanyxContext = createContext();

export const QwanyxProvider = ({ children }) => {
  const { currentUser } = useAuth();
  
  // State to store domain ID (di)
  const [domainId, setDomainId] = useState(null);
  
  // State to store _id, ed, and di
  const [selectedCard, setSelectedCard] = useState({
    _id: null,
    ed: null,
    di: null,
    pa: null,
  });

  // Debug: Log state changes
  useEffect(() => {
    console.log("🔵 QwanyxProvider - selectedCard updated:", selectedCard);
  }, [selectedCard]);

  // Initialize domainId with currentUser.id when component mounts
  useEffect(() => {
    if (currentUser && currentUser.id) {
      setDomainId(currentUser.id);
    }
  }, [currentUser]);

  // Enhanced update function with memoization to prevent unnecessary renders
  const updateSelectedCard = useCallback((newCardData) => {
    console.log("🔵 updateSelectedCard called with:", newCardData);
    
    // Use functional update to ensure we're working with the latest state
    setSelectedCard(prevState => {
      // Check if this is actually a change to avoid unnecessary renders
      const isIdentical = 
        prevState._id === newCardData._id &&
        prevState.ed === newCardData.ed &&
        prevState.di === newCardData.di &&
        prevState.pa === newCardData.pa;
      
      if (isIdentical) {
        console.log("🔄 Forcing re-render despite identical values");
        // Even though values are identical, force a new reference
        return { ...newCardData, _forceUpdate: Date.now() };
      }
      
      console.log("✨ New card data is different, updating state");
      return { ...newCardData };
    });
    
    // Force a DOM update in the next event loop
    setTimeout(() => {
      console.log("⏱️ State update complete, selectedCard should now be:", newCardData);
    }, 0);
  }, []);

  const updateDomainId = useCallback((newDomainId) => {
    setDomainId(newDomainId);
  }, []);

  const newCard = useCallback(() => {
    const newCardData = newQwanta(selectedCard.ed, domainId, selectedCard.pa, "instance");
    setSelectedCard(newCardData);
  }, [selectedCard.ed, domainId, selectedCard.pa]);

  const deleteCard = useCallback(() => {
    // Use the built-in confirm dialog
    const confirmDelete = window.confirm("Are you sure you want to delete this card?");

    // Only proceed with deletion if the user confirmed
    if (confirmDelete) {
      deleteQwanta(selectedCard._id);
      setSelectedCard({
        _id: null,
        ed: null,
        di: null,
        pa: null,
      });
    }
  }, [selectedCard._id]);

  const contextValue = {
    selectedCard,
    domainId,
    updateSelectedCard,
    updateDomainId,
    newCard,
    deleteCard
  };

  return (
    <QwanyxContext.Provider value={contextValue}>
      {children}
    </QwanyxContext.Provider>
  );
};

export const useQwanyx = () => {
  const context = useContext(QwanyxContext);
  if (!context) {
    throw new Error('useQwanyx must be used within a QwanyxProvider');
  }
  return context;
};

export default QwanyxProvider;