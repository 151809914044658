import React from 'react';

export const Button = ({ children, style = {}, className = '' }) => {
  return (
    <button
      className={className} // Dynamically apply the className
      style={{
        background: 'none',
        border: 'none',
        ...style, // Merge any additional styles passed via props
      }}
    >
      {children}
    </button>
  );
};

export default Button;